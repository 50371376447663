import { useQuery, useMutation } from 'react-query'

import api from 'src/api'

import { Order, OrderHistoryEvent, OrdersStatusesRes } from 'src/interfaces'

interface UseOrdersParamsType {}

const getOrders = async (params?: UseOrdersParamsType) => {
  const result = await api.get(`v1/orders`, { params })
  if (!result.data?.success) throw new Error(result.data.message)
  return result.data
}

interface OrdersResult {
  orders: Order[]
  recordsTotal: number
}

export const useOrders = (params?: UseOrdersParamsType) => {
  return useQuery<OrdersResult>([`orders`, params], () => getOrders(params), {
    enabled: !!params,
    keepPreviousData: true,
    initialData: { orders: [], recordsTotal: 0 },
  }) as { data: OrdersResult }
}

const getOrdersStatuses = async () => {
  const result = await api.get(`v1/orders/statuses`)
  if (!result.data?.success) throw new Error(result.data.message)
  return { ...result.data.statuses, ORDER_ERROR: result.data.problemOrders }
}

export const useOrdersStatuses = () => {
  return useQuery<OrdersStatusesRes>([`ordersStatuses`], getOrdersStatuses, {
    initialData: {},
  })
}

interface UseOrderHistoryParams {
  id: string
}

const getOrderHistory = async (params?: UseOrderHistoryParams) => {
  const result = await api.get(`v1/orders/history`, { params })
  if (!result.data?.success) throw new Error(result.data.message)
  return result.data
}

interface OrderHistoryResult {
  events: OrderHistoryEvent[]
}

export const useOrderHistory = (params?: UseOrderHistoryParams) => {
  return useQuery<OrderHistoryResult>(
    [`orderHistory`, params],
    () => getOrderHistory(params),
    {
      enabled: !!params,
      keepPreviousData: true,
      initialData: { events: [] },
    },
  ) as { data: OrderHistoryResult }
}

const calcOrderInsuranceSum = async (data: Order) => {
  const result = await api.post(`v1/orders/calc_insurance_sum`, data)
  if (!result.data?.success) throw new Error(result.data.message)
  return result.data
}

export const useCalcOrderInsuranceSum = () => {
  //TODO type
  return useMutation(async (order: Order) => {
    return await calcOrderInsuranceSum(order)
  })
}
