import React from 'react'

import styles from './block.module.scss'

type BlockProps = React.PropsWithChildren<React.ReactNode> & {
  title: string
  subTitle?: string | React.ReactNode
  onOpenEdit?: () => void
}

const Block = (props: BlockProps) => {
  const { title, subTitle, children, onOpenEdit } = props

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div>
          <h3>{title}</h3>
          <div className={styles.subTitle}>{subTitle}</div>
        </div>

        {onOpenEdit ? (
          <div className={styles.editButton} onClick={onOpenEdit}>
            Изменить
          </div>
        ) : null}
      </div>

      <div className={styles.divider} />

      <div className={styles.main}>{children}</div>
    </div>
  )
}

export default Block
