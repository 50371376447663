import React from 'react'
import cx from 'classnames'

import { Tooltip } from 'src/components'

import styles from './toggle.module.scss'

interface ToggleType {
  value?: boolean
  label?: string
  disabled?: boolean
  tooltip?: string
  tooltipSide?: 'top' | 'right'
  onChange: (newValue: boolean) => void
}

const Toggle: React.FC<ToggleType> = (props) => {
  const {
    value = false,
    label,
    disabled = false,
    tooltip,
    tooltipSide,
    onChange,
  } = props

  const handleChange = () => {
    if (!disabled) {
      onChange(!value)
    }
  }

  return (
    <div className={cx(styles.wrapper, disabled && styles.disabled)}>
      <div
        className={cx(styles.back, value ? styles.active : null)}
        onClick={handleChange}
      >
        <div className={styles.round} />
      </div>

      {label ? <label>{label}</label> : null}

      {tooltip ? (
        <Tooltip
          className={styles.tooltip}
          content={tooltip}
          side={tooltipSide}
        />
      ) : null}
    </div>
  )
}

export default Toggle
