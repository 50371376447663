import React, { useState } from 'react'

import styles from './order-products.module.scss'

import OrderProductsTable, { PRODUCTS_MOCK } from './OrderProductsTable'
import OrderProductsModal from './OrderProductsModal'

import { Price, Icons } from 'src/components'

import { Order } from 'src/interfaces'

interface OrderProductsProps {
  currentOrder: Order
  sum: number
}

const OrderProducts = (props: OrderProductsProps) => {
  const { currentOrder, sum } = props

  const [showModal, setShowModal] = useState(false)

  const handleProductsChange = () => {}

  const products = currentOrder.items.filter((p) => p.variantId !== 'delivery')

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.topBlock}>
          <h2>Состав заказа</h2>
          <div className={styles.sellerId}>ID Селлера: 2347</div>{' '}
          {/* TODO подставлять настоящий ID */}
        </div>

        <OrderProductsTable currentOrder={currentOrder} />

        <div className={styles.bottomBlock}>
          <div className={styles.addButton} onClick={() => setShowModal(true)}>
            <Icons.Plus /> Добавить новую позицию
          </div>

          <div className={styles.rightBlock}>
            <div className={styles.upTotal}>
              {`Всего товаров: ${products.length} шт. | Сумма:`}
              <Price className={styles.price} value={sum} />
            </div>

            <div className={styles.total}>
              <div>
                Итого: <Price className={styles.price} value={sum} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {showModal ? (
        <OrderProductsModal
          currentProducts={PRODUCTS_MOCK}
          onChange={handleProductsChange}
          onClose={() => setShowModal(false)}
        />
      ) : null}
    </>
  )
}

export default OrderProducts
