import React from 'react'

import { Badge } from 'src/components'

import { ORDER_STATUSES } from 'src/config'

import { Order } from 'src/interfaces'

const getPaymentBadgeStyle = (data: Order) => {
  const { status, paymentMethodType, paymentStatus, codPayments } = data

  if (
    paymentStatus === 'PAID' &&
    (paymentMethodType === 'ONLINE' ||
      paymentMethodType === 'NO_COD' ||
      paymentMethodType === 'NO_COD_NO_CONTROL')
  ) {
    return {
      title: 'Оплачен',
      style: {
        color: '#5e827a',
        background:
          'linear-gradient(90deg, rgba(216,244,238,1) 0%, rgba(168,241,225,1) 100%)',
        borderColor: '#b7e2d9',
      },
    }
  }

  if (
    paymentStatus === 'NOT_PAID' &&
    (paymentMethodType === 'ONLINE' || paymentMethodType === 'NO_COD')
  ) {
    return {
      title: 'Ожидание оплаты',
      style: {
        background:
          'linear-gradient(90deg, rgba(255,247,230,1) 0%, rgba(255,236,201,1) 100%)',
        borderColor: '#fdebcd',
        color: '#806027',
      },
    }
  }

  if (
    paymentStatus === 'NOT_PAID' &&
    paymentMethodType === 'NO_COD_NO_CONTROL'
  ) {
    return {
      title: 'Без контроля оплаты',
      style: {
        background:
          'linear-gradient(90deg, rgba(236,236,236,1) 0%, rgba(227,227,229,1) 100%)',
        borderColor: '#d8d8da',
        color: '#3b3c40',
      },
    }
  }

  if (paymentMethodType === 'COD_CARD' || paymentMethodType === 'COD_CASH') {
    if (codPayments) {
      return {
        title: 'Оплата при доставке',
        style: {
          background:
            'linear-gradient(90deg, rgba(216,244,238,1) 0%, rgba(168,241,225,1) 100%)',
          borderColor: '#b7e2d9',
          color: '#5e827a',
        },
        icon: 'fe-check-circle',
      }
    }

    const statuses = Object.keys(ORDER_STATUSES)
    const orderInfoCourierIndex = statuses.findIndex(
      (key) => key === 'ORDER_INFO_COURIER',
    )
    const showClockLabel =
      orderInfoCourierIndex !== -1 &&
      statuses.findIndex((key) => key === status) >= orderInfoCourierIndex
    if (showClockLabel) {
      return {
        title: 'Оплата при доставке',
        style: {
          background:
            'linear-gradient(90deg, rgba(220,244,249,1) 0%, rgba(194,237,246,1) 100%)',
          borderColor: '#c1ecf4',
          color: '#296775',
        },
        icon: 'fe-clock',
      }
    }

    return {
      title: 'Оплата при доставке',
      style: {
        background:
          'linear-gradient(90deg, rgba(220,244,249,1) 0%, rgba(194,237,246,1) 100%)',
        borderColor: '#c1ecf4',
        color: '#296775',
      },
    }
  }

  return { title: '', style: {} }
}

interface PaymentBadgeProps {
  className?: string
  data: Order
}

const PaymentBadge = (props: PaymentBadgeProps) => {
  const { className, data } = props
  const { title, style } = getPaymentBadgeStyle(data)

  return <Badge className={className} title={title} style={style} />
}

export default PaymentBadge
