import React from 'react'
import cx from 'classnames'

import { Spinner } from 'src/components'

import styles from './button.module.scss'

const getButtonTemplateStyle = (templateName: string) => {
  switch (templateName) {
    case 'secondary':
      return styles.buttonSecondary
    case 'ghost':
      return styles.buttonGhost
    case 'simple':
      return styles.buttonSimple
    default:
      return ''
  }
}

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean
  size?: 'normal' | 'large'
  template?: 'primary' | 'secondary' | 'ghost' | 'simple'
  icon?: React.ReactNode
}

const Button = (props: Props) => {
  const {
    className,
    type = 'button',
    loading,
    disabled = loading,
    children,
    size,
    template = 'primary',
    icon,
    ...otherProps
  } = props

  return (
    <button
      {...otherProps}
      className={cx(
        className,
        size === `large` ? styles.buttonLarge : styles.button,
        getButtonTemplateStyle(template),
      )}
      disabled={disabled}
      type={type}
    >
      {!loading ? (
        <>
          {icon ? <span className={styles.icon}>{icon}</span> : null}
          {children}
        </>
      ) : (
        <Spinner />
      )}
    </button>
  )
}

export default Button
