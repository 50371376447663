import React, { useState, useEffect } from 'react'
import cx from 'classnames'

import styles from './notifications.module.scss'

import Notification from './Notification'

import { Icons } from 'src/components'

import { useNotifications } from '../NotificationProvider'

export interface NotificationOptions {
  id: string
  buttons?: {
    label: string
    type?: 'main'
    onClick?: () => void
  }[]
}

export interface INotification {
  id: string
  type: string
  content: string
  options?: NotificationOptions
}

const Notifications = () => {
  const context = useNotifications()

  const handleButtonClick = (id: string, callback?: () => void) => {
    if (callback) {
      callback()
    }

    context?.clear(id)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        {Object.keys(context?.state || {}).map((key: string) => (
          <React.Fragment key={key}>
            {context?.state[key] ? (
              <Notification
                {...context?.state[key]}
                onButtonClick={handleButtonClick}
              />
            ) : null}
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}

export default Notifications
