import { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { privateRoutes, publicRoutes, Routes } from './'

const renderRoutes = (routes: Routes) => {
  const defaultPath = routes.find((route) => route.default)?.path

  return (
    <Switch>
      <Redirect from="/:url*(/+)" to={window.location.pathname.slice(0, -1)} />
      {routes.map((route) => (
        <Route exact key={route.path} path={route.path}>
          <Suspense fallback={null}>
            <route.component />
          </Suspense>
        </Route>
      ))}
      {defaultPath && <Redirect to={defaultPath} />}
    </Switch>
  )
}

export const renderPrivateRoutes = () => renderRoutes(privateRoutes)

export const renderPublicRoutes = () => renderRoutes(publicRoutes)
