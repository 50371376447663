import { TreeOptionType } from './'

interface Image {
  id: string
  pos: string
  success: boolean
  url: string
}

interface Price {
  priceCode: string
  priceName: string
  value: number
}

interface Stock {
  warehouseCode: string
  stockTotal: number
  stockAvailable: number
  stockReserved: number
}

interface Dimension {
  height: number
  width: number
  depth: number
}

export interface Barcode {
  value: string
  isDefault: boolean
  type: string
}

interface AccountAttribute {
  code: string
  title: string
  stringValue: string
}

interface SubscriptionItem {
  productId: string
  variantId: string
  count: number
  subscriptionShipmentNum: number
  shipmentDelayIntervalDays: number
  subscriptionStartDate: string
  itemPrice: number
}

export interface Variant {
  id: string
  extId?: string
  extId2?: string
  extId3?: string
  extId4?: string
  extId5?: string
  article?: string
  price: number
  oldPrice: number
  isFfSynchronised: boolean
  ffSyncError?: boolean
  isPhysicalSet: boolean
  isFfPhysicalSetSynchronized: boolean
  ffPhysicalSetSyncError?: unknown
  prices: Price[]
  stock: Stock[]
  ordering: string
  defaultImage: string
  weight: number
  dimensions: Dimension[]
  barcodes?: Barcode[]
  optionsUsed?: unknown
}

export enum ProductSaleStatus {
  NEW = 'NEW', //Черновик
  READY_FOR_SALE = 'READY_FOR_SALE', //Готов к продаже
  ON_SALE = 'ON_SALE', //В продаже
  WITHDRAWN = 'WITHDRAWN', //Снят с продажи
  DECLINED = 'DECLINED', //Отклонен
  ARCHIVE = 'ARCHIVE', //В архиве
}

export interface Product {
  id: string
  extId?: string
  extId2?: string
  extId3?: string
  extId4?: string
  extId5?: string
  shortName: string
  description: string
  tnvedCode: string
  internationalDescription: string
  additionalDescriptions: { title: string; description: string }[]
  available: boolean
  deleted: boolean
  brandName?: string
  countryOfOrigin?: string
  isBestseller?: boolean
  isNovelty?: boolean
  type: string
  vat: string
  reviewScore: number
  options?: unknown
  images?: Image[]
  variants: Variant[]
  collections: string[]
  attributes?: unknown
  accountingAttributes: AccountAttribute[]
  subscriptionInfo: {
    productId: string
    variantId: string
    onlinePaymentType: string
    type: string
    useShipmentDelayInterval: boolean
    items: SubscriptionItem[]
  }
  saleStatus: ProductSaleStatus
}

export interface ProductsResponse {
  products: Product[]
  recordsTotal: 2
  success: true
}

export interface Attribute {
  id: string
  code: string
  title: string
  type: 'string' | 'decimal'
}

export interface AttributeValue {
  code: string
  stringValue?: string
  decimalValue?: string
}

export interface Feature {
  id: string
  code: string
  title: string
}

export interface FeatureValue {
  code: string
  stringValue: string
}

export interface ProductCreatingVariantValues {
  id?: string
  article: string
  barcodes: Barcode[]
  price: number | undefined
  oldPrice: number | undefined
  weight: number | undefined
  dimensions: {
    width: number | undefined
    height: number | undefined
    depth: number | undefined
  }
  extID: string
  optionsUsed?: FeatureValue[]
  imageId?: string
  imageUrl?: string
  defaultImage?: string
}

export interface ProductCreatingImage {
  id: string
  file?: File
  url?: string
}

export interface ProductCreatingValues {
  shortName: string
  categoryId?: string
  description?: string
  images?: ProductCreatingImage[]
  attributes: AttributeValue[] | null
  variantsToUpdate: ProductCreatingVariantValues[]
  variantsForRemoving: ProductCreatingVariantValues[]
}

export interface NomenclatureProduct {
  variantId: string
  variantExtId?: string
  productId?: string
  img: string
  name: string
  article: string
  barcode?: Barcode
  price: number
}
