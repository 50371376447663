export const PRODUCT_CREATING = {
  SHORT_NAME: 'Название',
  CATEGORY: 'Категория',
  DESCRIPTION: 'Описание',
  ARTICLE: 'Артикул',
  BARCODE: 'Штрихкод',
  PRICE: 'Цена',
  OLD_PRICE: 'Цена до скидки',
  WEIGHT: 'Вес с упаковкой, г',
  WIDTH: 'Ширина, cм',
  HEIGHT: 'Высота, см',
  DEPTH: 'Глубина, см',
  EXT_ID: 'Внешний ID',
}
