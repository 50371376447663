import React from 'react'
import dayjs from 'dayjs'

import styles from './page-wrapper.module.scss'

import { Page, StatusBadge, PaymentBadge, Button, Icons } from 'src/components'

import { PATHS } from 'src/config'

interface PageWrapperProps {
  currentOrder: any //TODO order type
  children: React.ReactNode
}

const PageWrapper = (props: PageWrapperProps) => {
  const { currentOrder, children } = props

  return (
    <Page
      title={`#${currentOrder.id}`}
      backUrl={PATHS.ORDERS}
      leftBlock={
        <>
          <div className={styles.date}>
            {dayjs(currentOrder.date).format('DD MMM YYYY HH:mm')}
          </div>
          {currentOrder.status !== 'ORDER_INFO_REQUIRE_PAYMENT' ? (
            <StatusBadge className={styles.statusBadge} data={currentOrder} />
          ) : null}
          <PaymentBadge className={styles.paymentBadge} data={currentOrder} />
        </>
      }
      rightBlock={
        <>
          <Button icon={<Icons.Check />}>Подтвердить</Button>
          <Button
            className={styles.actionsButton}
            icon={<Icons.Arrow />}
            template="secondary"
          >
            Действия
          </Button>
        </>
      }
    >
      {children}
    </Page>
  )
}

export default PageWrapper
