import React from 'react'
import cx from 'classnames'
import dayjs from 'dayjs'

import styles from './date-picker.module.scss'

interface DayProps {
  label: number
  isDayOfCurrentMonth?: boolean
  isActive?: boolean
  isDisabled?: boolean
  onClick?: () => void
}

const Day = (props: DayProps) => {
  const { label, isDayOfCurrentMonth, isActive, isDisabled, onClick } = props
  return (
    <div
      className={cx(
        isDayOfCurrentMonth && styles.dayOfCurrentMonth,
        isActive && styles.active,
        isDisabled && styles.disabled,
      )}
      onClick={() => (!isDisabled && onClick ? onClick() : () => false)}
    >
      {label}
    </div>
  )
}

export default Day
