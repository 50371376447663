import React, { Fragment, useState, useLayoutEffect } from 'react'
import cx from 'classnames'

import styles from './page-nav.module.scss'

interface PageNavProps {
  parts: {
    icon: React.FC
    name: string
    title: string
  }[]
  currentPartName: string
  disabled?: boolean
  scrolledElement?: HTMLElement | null
  onSelect?: (partName: string) => void
}

const PageNav = (props: PageNavProps) => {
  const {
    parts,
    currentPartName,
    disabled,
    scrolledElement,
    onSelect = () => false,
  } = props

  const [clickedElement, setClickedElement] = useState<string | null>(null)

  useLayoutEffect(() => {
    const listener = (event: any) => {
      let actualPartName = null
      parts.forEach((part) => {
        const specifiedElement = document.getElementById(`part-${part.name}`)

        if (
          scrolledElement &&
          specifiedElement &&
          specifiedElement.offsetTop <
            scrolledElement.scrollTop + scrolledElement.clientHeight
        ) {
          actualPartName = part.name
        }
      })

      if (actualPartName) {
        if (!clickedElement) {
          onSelect(actualPartName)
        } else {
          if (actualPartName === clickedElement) {
            setClickedElement(null)
            onSelect(actualPartName)
          }
        }
      }
    }

    if (!disabled && scrolledElement) {
      scrolledElement.addEventListener('scroll', listener)
    }

    return () => {
      if (!disabled && scrolledElement) {
        scrolledElement.removeEventListener('scroll', listener)
      }
    }
  }, [scrolledElement, clickedElement])

  useLayoutEffect(() => {
    if (clickedElement) {
      const specifiedElement = document.getElementById(`part-${clickedElement}`)
      if (specifiedElement) {
        specifiedElement.scrollIntoView({ block: 'start', behavior: 'smooth' })
      }
    }
  }, [clickedElement])

  const handlePartClick = (partName: string) => {
    if (!disabled) {
      setClickedElement(partName)
      onSelect(partName)
    }
  }

  return (
    <div className={styles.wrapper}>
      {parts.map((p, i) => (
        <Fragment key={i}>
          {i ? <span className={styles.separator} /> : null}
          <div
            key={p.name}
            className={cx(
              styles.part,
              p.name === currentPartName ? styles.active : null,
              disabled && styles.disabled,
            )}
            onClick={() => handlePartClick(p.name)}
          >
            <p.icon />
            {p.title}
          </div>
        </Fragment>
      ))}
    </div>
  )
}

export default PageNav
