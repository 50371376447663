import React from 'react'
import styles from './status-bar.module.scss'

import { ORDER_STATUSES, INFO_STATUS_BAR_COLORS } from 'src/config'

interface colorsSetKeysType {
  [key: string]: {
    stroke: string
    fill: string
  }
}

const COLORS_SET: colorsSetKeysType = {
  '0': { stroke: '#ebebeb', fill: '#FFFFFF' }, // phase not started
  '1': { stroke: '#7e57c2', fill: '#FFFFFF' }, // phase started
  '2': { stroke: '#7e57c2', fill: '#7e57c2' }, // phase finished
  '3': { stroke: '#c6eee6', fill: '#c6eee6' }, // success
  e: { stroke: '#f1556c', fill: '#f1556c' }, // phase error
  c: { stroke: '#ebebeb', fill: '#ebebeb' }, // phase cancelled
}

interface StatusBarProps {
  status: keyof typeof ORDER_STATUSES
}

const StatusBar = (props: StatusBarProps) => {
  const { status } = props

  var status_colors

  if (INFO_STATUS_BAR_COLORS.hasOwnProperty(status)) {
    status_colors = INFO_STATUS_BAR_COLORS[status]
  } else {
    status_colors = 'eeeee'
  }

  var status_title

  if (ORDER_STATUSES.hasOwnProperty(status)) {
    status_title = ORDER_STATUSES[status]
  } else {
    status_title = 'Unknown status'
  }

  return (
    <div>
      <div>
        <svg
          viewBox="0 0 186 12"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          width="180px"
          height="12px"
        >
          {status === 'ORDER_INFO_CANCELED' ? (
            <rect x="0" y="5" width="180" height="3" fill="#f1556c"></rect>
          ) : (
            <>
              <circle
                cx="5"
                cy="6"
                r="3"
                stroke={COLORS_SET[status_colors[0]].stroke}
                strokeWidth="3"
                fill={COLORS_SET[status_colors[0]].fill}
              />
              <rect
                x="9"
                y="5"
                width="35"
                height="3"
                fill={COLORS_SET[status_colors[1]].stroke}
              />
              <circle
                cx="48"
                cy="6"
                r="3"
                stroke={COLORS_SET[status_colors[1]].stroke}
                strokeWidth="3"
                fill={COLORS_SET[status_colors[1]].fill}
              />
              <rect
                x="52"
                y="5"
                width="35"
                height="3"
                fill={COLORS_SET[status_colors[2]].stroke}
              />
              <circle
                cx="91"
                cy="6"
                r="3"
                stroke={COLORS_SET[status_colors[2]].stroke}
                strokeWidth="3"
                fill={COLORS_SET[status_colors[2]].fill}
              />
              <rect
                x="95"
                y="5"
                width="35"
                height="3"
                fill={COLORS_SET[status_colors[3]].stroke}
              />
              <circle
                cx="134"
                cy="6"
                r="3"
                stroke={COLORS_SET[status_colors[3]].stroke}
                strokeWidth="3"
                fill={COLORS_SET[status_colors[3]].fill}
              />
              <rect
                x="138"
                y="5"
                width="35"
                height="3"
                fill={COLORS_SET[status_colors[4]].stroke}
              />
              <circle
                cx="177"
                cy="6"
                r="3"
                stroke={COLORS_SET[status_colors[4]].stroke}
                strokeWidth="3"
                fill={COLORS_SET[status_colors[4]].fill}
              />
            </>
          )}
        </svg>
      </div>
      <div>
        <small className={styles.statusTitle}>{status_title}</small>
      </div>
    </div>
  )
}

export default StatusBar
