import { useMutation } from 'react-query'

import api from 'src/api'

import { LoginType } from 'src/interfaces/auth'
import appLocalStorage from 'src/libs/localStorage'
import { useContext } from 'react'
import { AppContext } from 'src/App'

export const useLogin = () => {
  const { setAuthorized } = useContext(AppContext)
  return useMutation(async (values: LoginType) => api.post('v1/auth', values), {
    onSuccess: (result) => {
      if (result && result.data) {
        appLocalStorage.token = result.data.refresh_token
        api.defaults.headers.Authorization = `Bearer ${result.data.access_token}`
        setAuthorized(true)
      }
    },
  })
}
