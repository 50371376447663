import React from 'react'
import cx from 'classnames'

import styles from './image-from-store.module.scss'

const getYandexStoreImageStyle = (url: string, size: number) => {
  if (url.indexOf('https://storage.yandexcloud.net/') === 0) {
    const splitted = url.split('.')
    const extension = splitted.pop()
    url = splitted.join('.') + '-' + size + '.' + extension
  }
  return url ? { backgroundImage: `url(${url})` } : {}
}

interface ImageFromStoreProps {
  url?: string
  size?: 'small' | 'normal'
}

const ImageFromStore = (props: ImageFromStoreProps) => {
  const { url, size = 'normal' } = props

  if (!url) return null

  let storeSize = 200

  if (size === 'small') {
    storeSize = 100
  }

  return (
    <div
      className={cx(styles.wrapper, styles[size])}
      style={getYandexStoreImageStyle(url, storeSize)}
    />
  )
}

export default ImageFromStore
