import {
  TreeOptionType,
  AttributeValue,
  ProductCreatingValues,
  ProductCreatingVariantValues,
  Variant,
  Barcode,
} from 'src/interfaces'

export const reformatProductDataBeforeSaving = (
  values: ProductCreatingValues,
  productId?: string | string[],
  variantId?: string | string[],
) => {
  const data = {
    ...values,
  }

  delete data.images

  return {
    data,
    images: values.images?.filter((i) => i.file) ?? [],
    productId,
    variantId,
  }
}

export const isMultiVariant = (values: ProductCreatingValues) => {
  return Boolean(
    values.variantsToUpdate?.length &&
      values.variantsToUpdate?.find(
        (v: ProductCreatingVariantValues) => v.optionsUsed,
      ),
  )
}

interface FindCurrentCategoryArgs {
  items: TreeOptionType[]
  guid?: string
}

export const findCurrentCategory = (
  args: FindCurrentCategoryArgs,
): TreeOptionType | null => {
  const { items, guid } = args

  let currentCategory = null
  if (!guid) return null

  for (let i = 0; i < items.length; i++) {
    if (items[i].guid === guid) {
      currentCategory = items[i]
      break
    } else {
      if (items[i].items?.length) {
        currentCategory = findCurrentCategory({ items: items[i].items, guid })
        if (currentCategory) {
          break
        }
      }
    }
  }

  return currentCategory
}

type FindCurrentCategoryWithParentsArgs = FindCurrentCategoryArgs & {
  items: TreeOptionType[]
  guid?: string
  parents?: TreeOptionType[]
  level?: number
}

export const findCurrentCategoryWithParents = (
  args: FindCurrentCategoryWithParentsArgs,
): TreeOptionType[] | null => {
  const { items, guid, level = 0 } = args

  let { parents = [] } = args

  let currentCategory = null
  let haveSearchedChild = false
  if (!guid) return null

  for (let i = 0; i < items.length; i++) {
    if (items[i].guid === guid) {
      parents[level] = items[i]
      break
    } else {
      if (items[i]?.items.length) {
        haveSearchedChild = Boolean(
          findCurrentCategoryWithParents({
            items: items[i].items,
            guid,
            parents,
            level: level + 1,
          }),
        )
        if (haveSearchedChild) {
          parents[level] = items[i]
          break
        }
      }
    }
  }

  return parents[level] ? parents : null
}

export const getCategoryNameString = (
  args: FindCurrentCategoryWithParentsArgs,
): string => {
  const treeArr = findCurrentCategoryWithParents(args)
  return treeArr
    ? Object.values(treeArr)
        .map((category, i) => (i ? ' / ' : '') + category?.name)
        .join('')
    : ''
}

export const getAttributeValue = (
  type: string | undefined,
  attributeValue: AttributeValue | null,
) => {
  if (attributeValue) {
    switch (type) {
      case 'string':
        return attributeValue.stringValue || ''
      case 'decimal':
        return attributeValue.decimalValue || ''
    }
  }
  return ''
}

export const reformatVariantForValues = (variant: Variant) => ({
  id: variant.id,
  article: variant.article,
  barcodes: variant.barcodes,
  price: variant.price,
  oldPrice: variant.oldPrice,
  weight: variant.weight,
  dimensions: variant.dimensions,
  extId: variant.extId,
  optionsUsed: variant.optionsUsed,
  defaultImage: variant.defaultImage,
})

export const getProductFormikInitialValues = (data?: any) => {
  //TODO type
  return {
    shortName: data ? data.shortName : '',
    description: data ? data.description : '',
    categoryId: data ? data.categoryId : undefined,
    attributes: data ? data.attributes : null,
    images: data ? data.images || [] : [],
    variantsToUpdate: data
      ? data.variantsToUpdate ||
        (data.variants?.map(reformatVariantForValues) ?? [])
      : [
          {
            article: '',
            barcodes: [
              {
                value: '',
                isDefault: true,
                type: 'COMMON',
              },
            ],
            price: undefined,
            oldPrice: undefined,
            weight: undefined,
            dimensions: {
              width: undefined,
              height: undefined,
              depth: undefined,
            },
            extID: '',
          },
        ],
    variantsForRemoving: [],
  }
}

export const getProductCommonBarcode = (variant: Variant) =>
  (variant.barcodes || []).find((b: Barcode) => b.type === 'COMMON')
