import React from 'react'
import cx from 'classnames'

import styles from './product-status.module.scss'

import { PRODUCT_SALE_STATUSES } from 'src/config'

import { ProductSaleStatus } from 'src/interfaces'

interface ProductStatusProps {
  value: ProductSaleStatus
}

const ProductStatus = (props: ProductStatusProps) => {
  const { value } = props

  return (
    <div className={styles.wrapper}>
      <span className={cx(styles.circle, styles[value])} />
      {PRODUCT_SALE_STATUSES[value]}
    </div>
  )
}

export default ProductStatus
