import React, { useState, useEffect } from 'react'

import styles from './city-block.module.scss'

import { Select } from 'src/components'

import { useLocationPrompter } from 'src/ADMIN/hooks'

import { DadataCityType, AddressFiases } from 'src/ADMIN/interfaces'
import { OrderDelivery } from 'src/interfaces'

interface CityBlockProps {
  defaultData?: OrderDelivery
  onCityChange: (fiases: AddressFiases) => void
}

const CityBlock = (props: CityBlockProps) => {
  const { defaultData, onCityChange } = props

  const [query, setQuery] = useState(defaultData?.address.city || '')
  const [isReady, setIsReady] = useState(false)
  const [city, setCity] = useState('')

  const { data, isFetching, isIdle, isLoading, isFetched } =
    useLocationPrompter({
      query,
      country: 'RU',
    })

  const handleSelectCity = (value: any) => {
    //todo type
    setCity(value)

    const currentSuggestion = data?.suggestions.find(
      (s: DadataCityType, i: number) => value === i,
    )

    if (currentSuggestion) {
      onCityChange({
        regionFias: currentSuggestion.data?.region_fias_id ?? null,
        areaFias: currentSuggestion.data?.area_fias_id ?? null,
        cityFias: currentSuggestion.data?.city_fias_id ?? null,
        settlementFias: currentSuggestion.data?.settlement_fias_id ?? null,
      })
    }
  }

  useEffect(() => {
    if (!isReady && isFetched && data?.suggestions.length) {
      handleSelectCity(0)
      setIsReady(true)
    }
  }, [isFetched, isReady])

  const handleSetQuery = (value: string) => {
    if (value) {
      setQuery(value)
    }
  }

  const cityOptions =
    query.length > 2
      ? data?.suggestions.map((s: DadataCityType, i: number) => ({
          label: s.value,
          value: i,
          data: s.data,
        })) ?? []
      : []

  if (!isReady) return null

  return (
    <div>
      <Select
        label="Город"
        options={cityOptions}
        value={city}
        showSearch
        onChange={handleSetQuery}
        onSelect={handleSelectCity}
      />
    </div>
  )
}

export default CityBlock
