import React from 'react'

import styles from './public-layout.module.scss'

const Layout: React.FC = (props) => {
    const { children } = props
    return (
        <div className={styles.wrapper}>
            {children}
        </div>
    )
}

export default Layout