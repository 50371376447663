import React from 'react'
import cx from 'classnames'

import styles from './info.module.scss'

interface InfoType {
  children?: React.ReactNode
  type: 'error' | 'warning' | 'success'
  className?: string
}

const Info: React.FC<InfoType> = (props) => {
  const { type, children, className } = props

  return (
    <div className={cx(styles.info, styles[type], className)}>
      <img alt="" className={styles.icon} src={`/icons/${type}.svg`} />
      <div>{children}</div>
    </div>
  )
}

export default Info
