import React, { useRef } from 'react'
import ReactQuill from 'react-quill'
import cx from 'classnames'

import { Tooltip } from 'src/components'

import styles from './wysiwyg.module.scss'

interface WYSIWYGType {
  required?: boolean
  id: string
  value: string
  label?: string
  className?: string
  error?: string
  placeholder?: string
  tooltip?: string
  tooltipSide?: 'top' | 'right'
  onChange: (e: string) => void
}

const modules = {
  toolbar: [
    // [{ header: [1, 2, false] }],
    // ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      // { indent: '-1' },
      // { indent: '+1' },
    ],
    // ['link', 'image', 'video'],
  ],
}

const formats = [
  // 'header',
  // 'bold',
  // 'italic',
  // 'underline',
  // 'strike',
  // 'blockquote',
  'list',
  'bullet',
  // 'indent',
  // 'link',
  // 'image',
  // 'video',
]

const WYSIWYG: React.FC<WYSIWYGType> = (props) => {
  const {
    required,
    className,
    id,
    value = '',
    error,
    placeholder,
    label,
    tooltip,
    tooltipSide = 'right',
    onChange,
  } = props

  const quillEl = useRef() as React.MutableRefObject<ReactQuill>

  const handleContainerClick = (event: React.MouseEvent) => {
    if (document) {
      const specifiedElement = document
        .getElementById(`wysiwyg-${id}`)!
        .querySelector('.ql-tooltip.ql-editing')!
      if (specifiedElement && event.target instanceof Node) {
        const isClickInside = specifiedElement.contains(event.target)
        if (!isClickInside) {
          quillEl.current.focus()!
        }
      }
    }
  }

  return (
    <div className={cx(className, styles.wrapper)}>
      {label ? (
        <label htmlFor={id}>
          {label}
          {required ? <span className="required">*</span> : null}
        </label>
      ) : null}
      <div
        id={`wysiwyg-${id}`}
        className={cx(
          styles.inputWrapper,
          tooltip && tooltipSide === 'right' ? styles.withTooltip : null,
        )}
        onClick={handleContainerClick}
      >
        <ReactQuill
          value={value}
          modules={modules}
          formats={formats}
          ref={quillEl}
          placeholder={placeholder}
          onChange={(newValue, delta, source) => {
            if (source === 'user') {
              onChange(newValue)
            }
          }}
        />
        {tooltip ? (
          <Tooltip
            className={styles.tooltip}
            content={tooltip}
            side={tooltipSide}
          />
        ) : null}
      </div>
      <div className={styles.errorText}>{error}</div>
    </div>
  )
}

export default WYSIWYG
