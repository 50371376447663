import React from 'react'
import cx from 'classnames'

import styles from './link.module.scss'

interface LinkProps {
  active?: boolean
  onClick?: () => void
}

const Link = (props: React.PropsWithChildren<LinkProps>) => {
  const { active, children } = props

  return (
    <div {...props} className={cx(styles.wrapper, active && styles.active)}>
      {children}
    </div>
  )
}

export default Link
