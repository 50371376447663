import React, { ReactNode } from 'react'
import styles from './tab.module.scss'

interface Props {
  id?: string
  active?: boolean
  children?: ReactNode
  icon?: ReactNode
  elementsCount?: number
  onClick?: (id: string) => void
}

const Tab = (props: Props) => {
  const { id, active, children, icon, elementsCount, onClick } = props

  const handleTabClick = () => {
    if (onClick && id) {
      onClick(id)
    }
  }

  return (
    <button
      className={active ? styles.activeButton : styles.button}
      onClick={handleTabClick}
    >
      {icon && <span className={styles.icon}>{icon}</span>}
      <span className={styles.content}>{children}</span>
      {typeof elementsCount !== 'undefined' ? (
        <span className={styles.count}>{elementsCount}</span>
      ) : null}
    </button>
  )
}

export default Tab
