import React, { useState } from 'react'
import Tabs from 'src/components/Tabs'

import { TableTab } from 'src/interfaces'

interface TableTabsProps {
  currentTab?: string | null
  tabsData: TableTab[]
  onTabChange?: (id: string) => void
}

const TableTabs = (props: TableTabsProps) => {
  const { currentTab, tabsData, onTabChange } = props

  return (
    <Tabs activeKey={currentTab}>
      {tabsData.map((tab) => (
        <Tabs.TabPane
          key={tab.key}
          elementsCount={tab.count}
          icon={tab.icon}
          onClick={onTabChange}
        >
          {tab.title}
        </Tabs.TabPane>
      ))}
    </Tabs>
  )
}

export default TableTabs
