import React, { useState } from 'react'
import cx from 'classnames'

import styles from './category-select.module.scss'

import { Modal, Select, Button, Icons } from 'src/components'

import { useCategories } from 'src/hooks/products'

import { TreeOptionType } from 'src/interfaces'
import { Category } from 'src/interfaces/category'

const TreeOption: React.FC<
  TreeOptionType & { className?: string | null; onClick: () => void }
> = (props) => {
  const { name, className, leaf_node, onClick } = props

  return (
    <div className={cx(styles.selectTreeOption, className)} onClick={onClick}>
      {name}
      {!leaf_node ? <Icons.Arrow /> : null}
    </div>
  )
}

interface CategorySelectModalType {
  id: string
  categories: TreeOptionType[]
  onSubmit: (option: TreeOptionType) => void
  onClose: () => void
}

const CategorySelectModal: React.FC<CategorySelectModalType> = (props) => {
  const { id, onSubmit, onClose } = props

  const { data: { categories } = { categories: [] } as Category } =
    useCategories()

  const [selectedCategories, setSelectedCategories] = useState<
    TreeOptionType[]
  >([])

  const handleTreeOptionClick = (option: TreeOptionType, depth: number) => {
    const newArr = []

    for (let i = depth; i > -1; i--) {
      if (i === depth) {
        newArr[depth] = option
      } else {
        if (selectedCategories[i]) {
          newArr[i] = selectedCategories[i]
        }
      }
    }

    setSelectedCategories(newArr)
  }

  const handleSelectCategory = (value: string | string[]) => {
    const option = (
      depth ? selectedCategories[depth - 1].items : categories
    ).find((category) => category.id === value)
    if (!option) return
    handleTreeOptionClick(option, depth)
  }

  const isSubmitButtonDisabled = selectedCategories.length
    ? !selectedCategories[selectedCategories.length - 1].leaf_node
    : true

  const handleSubmit = () => {
    onSubmit(selectedCategories[selectedCategories.length - 1])
    onClose()
  }

  const depth = Math.min(selectedCategories.length, 2)

  return (
    <Modal
      id={`${id}-modal`}
      title="Выберите категорию товара"
      HeaderButton={() => (
        <Button
          size="large"
          disabled={isSubmitButtonDisabled}
          onClick={handleSubmit}
        >
          Подтвердить
        </Button>
      )}
      onClose={onClose}
    >
      <div className={styles.modal}>
        <Select
          showSearch
          placeholder="Поиск по категориям"
          options={(depth
            ? selectedCategories[depth - 1].items
            : categories
          ).map(({ id, name }) => ({
            value: id,
            label: name,
          }))}
          onSelect={handleSelectCategory}
          filterOption={(input, option) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        />

        <div className={styles.selectTree}>
          <div className={styles.selectTreeItem}>
            {categories.map((c: TreeOptionType) => (
              <TreeOption
                key={c.id}
                className={
                  c.id === selectedCategories[0]?.id ? styles.selected : null
                }
                {...c}
                onClick={() => handleTreeOptionClick(c, 0)}
              />
            ))}
          </div>

          <div className={styles.selectTreeItem}>
            {selectedCategories[0] && !selectedCategories[0].leaf_node
              ? selectedCategories[0].items.map((c: TreeOptionType) => (
                  <TreeOption
                    key={c.id}
                    className={
                      c.id === selectedCategories[1]?.id
                        ? styles.selected
                        : null
                    }
                    {...c}
                    onClick={() => handleTreeOptionClick(c, 1)}
                  />
                ))
              : null}
          </div>

          <div className={styles.selectTreeItem}>
            {selectedCategories[1] && !selectedCategories[1]?.leaf_node
              ? selectedCategories[1].items.map((c: TreeOptionType) => (
                  <TreeOption
                    key={c.id}
                    className={
                      c.id === selectedCategories[2]?.id
                        ? styles.selected
                        : null
                    }
                    {...c}
                    onClick={() => handleTreeOptionClick(c, 2)}
                  />
                ))
              : null}
          </div>
        </div>

        <div className={styles.bottom}>
          Не нашли подходящую категорию?{' '}
          <a href="mailto:test@test.test">Напишите нам и мы ее добавим.</a>
        </div>
      </div>
    </Modal>
  )
}

export default CategorySelectModal
