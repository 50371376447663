import React, { useEffect } from 'react'
import cx from 'classnames'

import { ReactComponent as CloseIcon } from './assets/close.svg'

import styles from './modal.module.scss'

//TODO Modal ref ???

interface ModalType {
  id: string
  isOpen?: boolean
  title?: string
  size?: 'basic' | 'big'
  HeaderButton?: React.FC
  onClose?: () => void
  children: React.ReactNode
}

const Modal: React.FC<ModalType> = (props) => {
  const {
    id,
    isOpen = true,
    title,
    size = 'basic',
    HeaderButton,
    onClose = () => false,
    children,
  } = props

  useEffect(() => {
    const clickEventHandler = (event: MouseEvent | TouchEvent) => {
      const specifiedElement = document.getElementById(id)
      if (specifiedElement && event.target instanceof Node) {
        const isClickInside = specifiedElement.contains(event.target)
        if (!isClickInside) {
          onClose()
        }
      }
    }

    const keyDownEventHandler = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose()
      }
    }

    window.addEventListener('mousedown', clickEventHandler, false)
    window.addEventListener('touchstart', clickEventHandler, false)
    window.addEventListener('keydown', keyDownEventHandler, false)

    return () => {
      window.removeEventListener('mousedown', clickEventHandler, false)
      window.removeEventListener('touchstart', clickEventHandler, false)
      window.removeEventListener('keydown', keyDownEventHandler, false)
    }
  }, [id, onClose])

  return (
    <div
      id={id}
      className={cx(styles.wrapper, isOpen ? styles.open : null)}
      role="dialog"
      aria-modal="true"
    >
      <div className={cx(styles.dialog, styles[size])}>
        <div className={styles.content}>
          <div className={styles.header}>
            <div className={styles.close} onClick={onClose}>
              <CloseIcon />
            </div>
            {title ? <div className={styles.title}>{title}</div> : null}
            <div className={styles.controls}>
              {HeaderButton ? <HeaderButton /> : null}
            </div>
          </div>

          <div className={styles.body}>{children}</div>
        </div>
      </div>
    </div>
  )
}

export default Modal
