import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import appLocalStorage from 'src/libs/localStorage'

import styles from './new-product.module.scss'

import StepsForm, { StepType } from './StepsForm'
import { STEPS } from '../Steps'

import { Page, PageNav, Icons } from 'src/components'

import { useNotifications } from 'src/notification'
import {
  useCreateProduct,
  useUpdateProduct,
  useSendProductToModeration,
} from 'src/hooks/products'

import {
  reformatProductDataBeforeSaving,
  getProductFormikInitialValues,
} from 'src/helpers'

import { PATHS } from 'src/config'

import { Dispatch, SetStateAction } from 'react'
import { History } from 'history'
import { UseMutationResult } from 'react-query'
import {
  ProductCreatingValues,
  ProductCreatingImage,
  ProductCreatingVariantValues,
} from 'src/interfaces'
import { NotificationOptions } from 'src/notification/Notifications'

interface onSuccessProps {
  history: History
  res: {
    id: string
    product: {
      variants: ProductCreatingVariantValues[]
    }
    images: ProductCreatingImage[]
    variantsResult: {
      success: boolean
      productId: string
      variantId: string
    }[]
  }
  values: ProductCreatingValues
  currentStepIndex: number
  isFirstStep: boolean
  isLastStep: boolean
  setCurrentId: Dispatch<SetStateAction<string | string[] | undefined>>
  setCurrentVariantId: Dispatch<SetStateAction<string | string[] | undefined>>
  sendProductToModerationMutation: UseMutationResult
  showNotification?: (
    type: string,
    content: string,
    options?: NotificationOptions,
  ) => void
  clearNewProduct: () => void
}

const onSuccess = ({
  history,
  res,
  values,
  currentStepIndex,
  isFirstStep,
  isLastStep,
  sendProductToModerationMutation,
  setCurrentId,
  setCurrentVariantId,
  showNotification,
  clearNewProduct,
}: onSuccessProps) => {
  if (isFirstStep) {
    setCurrentId(res.id)
    setCurrentVariantId(res.product.variants[0].id)
  }

  if (!isLastStep) {
    showNotification &&
      showNotification('success', 'Черновик товара успешно сохранен', {
        id: 'productDraftSaved',
      })
  }

  let resObj: ProductCreatingValues = { ...values }

  if (res.variantsResult) {
    resObj.variantsToUpdate = values.variantsToUpdate.map((v, i: number) => {
      return {
        ...v,
        id: res.variantsResult[i].variantId,
      }
    })
  }

  if (currentStepIndex == 2) {
    //если загрузили картинки
    return { ...resObj, images: res.images }
  }

  if (isLastStep) {
    if (PATHS.PRODUCTS) {
      //TODO remove admin
      clearNewProduct()
      sendProductToModerationMutation.mutate({ productId: res.id })
      showNotification &&
        showNotification('success', 'Товар успешно отправлен на модерацию')
      history.push(PATHS.PRODUCTS)
    }
  } else {
    return { ...resObj }
  }
}

interface PageNavType {
  steps: StepType[]
  currentStepName: string
}

const NewProductPage = () => {
  const history = useHistory()
  const notifications = useNotifications()
  const newProductMutation = useCreateProduct()
  const updateProductMutation = useUpdateProduct()
  const sendProductToModerationMutation = useSendProductToModeration()

  const unfinishedProduct = appLocalStorage.getData('unfinishedProduct')

  const [needSyncWithStorage, setNeedSyncWithStorage] = useState(
    !unfinishedProduct,
  ) //Если есть кеш заказа, то не синкаем пока не подтвердит
  const [updatesFormValues, setUpdatesFormValues] = useState()

  const clearNewProduct = () => {
    setNeedSyncWithStorage(false)
    appLocalStorage.clearData('unfinishedProduct')
  }

  useEffect(() => {
    // TODO Make better
    if (!needSyncWithStorage) {
      if (!notifications?.state['unfinishedProduct']) {
        notifications?.show(
          'info',
          'У вас есть незаконченное создание товара',
          {
            id: 'unfinishedProduct',
            buttons: [
              {
                label: 'Продолжить',
                type: 'main',
                onClick: () => {
                  setNeedSyncWithStorage(true)
                  setCurrentId(unfinishedProduct?.currentId)
                  setCurrentVariantId(unfinishedProduct?.currentVariantId)
                  setUpdatesFormValues(unfinishedProduct?.values)
                },
              },
              {
                label: 'Отмена',
                onClick: () => {
                  setNeedSyncWithStorage(true)
                },
              },
            ],
          },
        )
      }
    }

    return () => notifications?.clear('unfinishedProduct')
  }, [])

  const [currentStep, setCurrentStep] = useState(STEPS[0])
  const [currentId, setCurrentId] = useState<string | string[] | undefined>()
  const [currentVariantId, setCurrentVariantId] =
    useState<string | string[] | undefined>()

  const currentStepIndex = STEPS.findIndex((s) => s.name === currentStep.name)
  const isFirstStep = currentStepIndex === 0
  const isLastStep = currentStepIndex === STEPS.length - 1

  return (
    <Page title="Создание товара" backUrl={PATHS.PRODUCTS}>
      <div className={styles.wrapper}>
        <PageNav
          parts={STEPS.map((s) => ({
            name: s.name,
            title: s.title,
            icon: s.icon,
          }))}
          currentPartName={currentStep.name}
          disabled={true}
        />

        <div className={styles.rightBlock}>
          <h2>{currentStep.title}</h2>
          <div className={styles.formWrapper}>
            <StepsForm<ProductCreatingValues>
              initialValues={getProductFormikInitialValues(updatesFormValues)}
              meta={{ currentId, currentVariantId }}
              unfinishedStorage={
                needSyncWithStorage ? 'unfinishedProduct' : undefined
              }
              mutation={
                typeof currentId === 'undefined'
                  ? newProductMutation
                  : updateProductMutation
              }
              reformatData={(values) =>
                reformatProductDataBeforeSaving(
                  values,
                  currentId,
                  currentVariantId,
                )
              }
              currentStep={currentStep}
              isFirstStep={isFirstStep}
              isLastStep={isLastStep}
              stepSaving
              lastStepButtonText="Создать товар"
              setPrevStep={() => setCurrentStep(STEPS[currentStepIndex - 1])}
              setNextStep={() => setCurrentStep(STEPS[currentStepIndex + 1])}
              onSuccess={(res, values) =>
                onSuccess({
                  history,
                  res,
                  values,
                  currentStepIndex,
                  isFirstStep,
                  isLastStep,
                  sendProductToModerationMutation,
                  setCurrentId,
                  setCurrentVariantId,
                  showNotification: notifications?.show,
                  clearNewProduct,
                })
              }
            />
          </div>
        </div>
      </div>
    </Page>
  )
}

export default NewProductPage
