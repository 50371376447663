import React from 'react'
import cx from 'classnames'

import styles from './pagination.module.scss'

import PaginationButton from './PaginationButton'
import PaginationStateString from './PaginationStateString'
import PaginationPanel from './PaginationPanel'

import { Select } from 'src/components'
import { ReactComponent as ArrowLeft } from './assets/arrow-left.svg'

interface Props {
  className?: string
  current: number
  pageSize?: number
  total?: number
  onChange?: (page: number, pageSize: number) => void
  pageSizeOptions?: number[]
  showSizeChanger?: boolean
}

const Pagination = (props: Props) => {
  const {
    className,
    current,
    pageSize = 10,
    total = 0,
    onChange,
    showSizeChanger,
    pageSizeOptions = [10, 20, 50, 100],
  } = props

  const pages = []
  const pagesCount = Math.ceil(total / pageSize)
  const currentPage = Math.max(0, Math.min(current, pagesCount))

  const handlePageChange = (page: number) => {
    if (onChange) onChange(page, pageSize)
  }

  const handlePageSizeChange = (pageSize: number) => {
    if (onChange) onChange(currentPage, pageSize)
  }

  for (let page = 1; page <= pagesCount; page++) {
    const button = (
      <PaginationButton
        active={page === currentPage}
        key={page}
        page={page}
        onChange={handlePageChange}
      />
    )
    if (page === 1 || page === pagesCount) {
      pages.push(button)
    } else if (currentPage - 3 > page || currentPage + 3 < page) {
    } else if (currentPage - 3 === page || currentPage + 3 === page) {
      pages.push(<PaginationButton key={page} ellipsis />)
    } else {
      pages.push(button)
    }
  }

  return (
    <div className={cx(styles.wrapper, className)}>
      <PaginationButton
        key="prev"
        type="switch"
        direction="descending"
        page={currentPage}
        onChange={() =>
          handlePageChange(currentPage - 1 > 0 ? currentPage - 1 : currentPage)
        }
      />
      {pages}
      <PaginationButton
        key="next"
        type="switch"
        direction="ascending"
        page={currentPage}
        onChange={() =>
          handlePageChange(
            currentPage + 1 <= pagesCount ? currentPage + 1 : currentPage,
          )
        }
      >
        <ArrowLeft />
      </PaginationButton>
      {showSizeChanger ? (
        <Select
          className={styles.sizeChanger}
          size="small"
          options={pageSizeOptions.map((o) => ({
            label: String(o),
            value: String(o),
          }))}
          value={String(pageSize)}
          onSelect={(value) => handlePageSizeChange(Number(value))}
        />
      ) : // <select
      //   className={styles.sizeChanger}
      //   onChange={(evt) => handlePageSizeChange(parseInt(evt.target.value))}
      //   value={pageSize}
      // >
      //   {pageSizeOptions.map((option) => (
      //     <option value={option} key={option}>
      //       {option}
      //     </option>
      //   ))}
      // </select>
      null}
    </div>
  )
}

Pagination.StateString = PaginationStateString
Pagination.Panel = PaginationPanel

export default Pagination
