interface GetWordEnding {
  (number: number, words: [string, string, string]): string
}

const getWordEnding: GetWordEnding = (number, words) =>
  words[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? number % 10 : 5]
  ]

export default getWordEnding
