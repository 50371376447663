import React, { useState, useEffect } from 'react'
import cx from 'classnames'

import ValueItem from './ValueItem'
import { Tooltip, Icons } from 'src/components'

import styles from './select.module.scss'

import { IOption } from 'src/interfaces'

interface SelectType {
  id?: string
  className?: string
  inputClassName?: string
  required?: boolean
  label?: string
  error?: string
  tooltip?: string
  tooltipSide?: 'top' | 'right'
  size?: 'small' | 'normal'
  placeholder?: string
  options: IOption[]
  value?: string | string[]
  onSelect: (value: string | string[]) => void
  onChange?: (value: string) => void
  showSearch?: boolean
  withoutError?: boolean
  filterOption?: (input: string, option: IOption) => unknown
  isMultiple?: boolean
}

const Select: React.FC<SelectType> = (props) => {
  const {
    required,
    id,
    label,
    className,
    inputClassName,
    placeholder,
    tooltip,
    tooltipSide,
    size,
    options: outerOptions,
    value,
    error,
    onSelect,
    onChange,
    showSearch,
    filterOption,
    withoutError,
    isMultiple,
  } = props

  const [isOpen, setIsOpen] = useState(false)
  const [search, setSearch] = useState<string>(``)

  useEffect(() => {
    if (onChange && search.length && outerOptions.length) {
      setIsOpen(true)
    }
  }, [search, outerOptions, onChange])

  const handleInputClick = () => {
    if (!onChange) {
      setIsOpen(!isOpen)
    }
  }

  const handleSetSearch = (query: string) => {
    setSearch(query)
    if (onChange) {
      onChange(query)
    }
  }

  const handleOptionSelect = (event: React.MouseEvent, option: IOption) => {
    event.stopPropagation()

    if (!isMultiple) {
      onSelect(option.value)
    } else {
      const newValue = Array.isArray(value) ? value : []
      onSelect([...newValue, option.value])
    }

    handleSetSearch(``)
    setIsOpen(false)
  }

  const handleRemoveOption = (
    event: React.MouseEvent,
    valueForRemoving: string,
  ) => {
    event.stopPropagation()

    if (Array.isArray(value)) {
      const findIndex = value?.findIndex((v) => v === valueForRemoving) ?? -1
      if (findIndex > -1) {
        const newValue = value.slice()
        newValue.splice(findIndex, 1)
        onSelect(newValue)
      }
    }
  }

  const active = !isMultiple
    ? outerOptions.find((option) => option.value === value)
    : outerOptions.filter((option) => value?.includes(option.value))
  const options = filterOption
    ? outerOptions.filter((option) => filterOption(search, option))
    : outerOptions

  return (
    <div className={cx(className, styles.wrapper, size && styles[size])}>
      {label ? (
        <label htmlFor={id}>
          {label}
          {required ? <span className="required">*</span> : null}
        </label>
      ) : null}

      <div className={styles.inputWrapper}>
        {!isMultiple ? (
          <input
            id={id}
            value={search || (!Array.isArray(active) && active?.label) || ``}
            placeholder={placeholder}
            className={cx(
              styles.input,
              error && styles.inputError,
              inputClassName,
            )}
            onClick={handleInputClick}
            readOnly={!showSearch}
            onChange={(event) => handleSetSearch(event.target.value)}
          />
        ) : (
          <div
            className={cx(
              styles.input,
              error && styles.inputError,
              inputClassName,
              isMultiple && styles.isMultiple,
            )}
            onClick={handleInputClick}
          >
            {Array.isArray(active) ? (
              <div className={styles.valuesWrapper}>
                {active.map((a) => (
                  <ValueItem
                    key={a.value}
                    size={size}
                    name={a.label}
                    onRemove={(e) => handleRemoveOption(e, a.value)}
                  />
                ))}
              </div>
            ) : null}
          </div>
        )}
        <div className={styles.downButton}>
          <Icons.Arrow />
        </div>
        {isOpen ? (
          <div className={styles.options}>
            {options.map((o) => (
              <div key={o.value} onClick={(e) => handleOptionSelect(e, o)}>
                {o.label}
              </div>
            ))}
          </div>
        ) : null}
        {tooltip ? (
          <Tooltip
            className={styles.tooltip}
            content={tooltip}
            side={tooltipSide}
          />
        ) : null}
      </div>
      {!withoutError ? <div className={styles.errorText}>{error}</div> : null}
    </div>
  )
}

export default Select
