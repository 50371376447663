import React, { useState } from 'react'

import styles from './order-products-modal.module.scss'

import SelectCount from './SelectCount'

import {
  Modal,
  Table,
  Pagination,
  Nomenclature,
  Price,
  Input,
  Button,
} from 'src/components'

import { useProducts } from 'src/hooks'

import { getProductCommonBarcode } from 'src/helpers'

import { OrderProduct, NomenclatureProduct } from 'src/interfaces'

interface Counts {
  [key: string]: number
}

interface OrderProductsModalProps {
  currentProducts: OrderProduct[]
  onClose: () => void
  onChange: () => void
}

const OrderProductsModal = (props: OrderProductsModalProps) => {
  const { currentProducts, onClose, onChange } = props

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)

  const [counts, setCounts] = useState<Counts>(
    currentProducts.reduce((a, c) => {
      a[c.variantId] = c.price * c.quantity
      return a
    }, {} as Counts),
  )

  const [query, setQuery] = useState<string | undefined>(undefined)

  const [touched, setTouched] = useState(false)

  const {
    data: { products, recordsTotal },
  } = useProducts({
    page: page,
    size: pageSize,
    query,
  })

  const handleChangePagination = (page: number, size: number) => {
    setPage(page - 1)
    setPageSize(size)
  }

  const handleChangeQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0)
    setQuery(event.currentTarget.value)
  }

  const handleChangeCount = (variantId: string, value: number) => {
    const newCounts = {
      ...counts,
      [variantId]: value,
    }
    setCounts(newCounts)
    setTouched(true)
  }

  const handleSave = () => {
    onChange() //TODO
    onClose()
  }

  const reformatProducts = products.map((product) => {
    return {
      variantId: product.variants[0].id,
      variantExtId: product.variants[0].extId,
      productId: product.id,
      img: product.variants[0].defaultImage,
      name: product.shortName,
      article: product.variants[0].article,
      barcode: getProductCommonBarcode(product.variants[0]),
      price: product.variants[0].price,
    }
  }) as NomenclatureProduct[]

  return (
    <Modal
      id="orderProductsModal"
      title="Редактирование товаров заказа"
      size="big"
      HeaderButton={() =>
        touched ? (
          <Button size="large" onClick={handleSave}>
            Обновить товары в заказе
          </Button>
        ) : null
      }
      onClose={onClose}
    >
      <div className={styles.wrapper}>
        <Input
          className={styles.search}
          placeholder="Поиск"
          value={query}
          onChange={handleChangeQuery}
        />

        <Table>
          {reformatProducts.map((product: NomenclatureProduct) => (
            <Table.Row key={product.variantId}>
              <Table.Column
                title="Номенклатура"
                className={styles.nomenclature}
              >
                <Nomenclature product={product} />
              </Table.Column>
              <Table.Column title="Штрихкод">
                {product.barcode?.value}
              </Table.Column>
              <Table.Column title="Цена">
                <Price value={product.price} />
              </Table.Column>
              <Table.Column title="Доступно на складе">
                0{/* TODO */}
              </Table.Column>
              <Table.Column className={styles.count}>
                <SelectCount
                  value={counts[product.variantId]}
                  onChange={(value) =>
                    handleChangeCount(product.variantId, value)
                  }
                />
              </Table.Column>
            </Table.Row>
          ))}
        </Table>

        <Pagination
          className={styles.pagination}
          current={page + 1 || 0}
          pageSize={pageSize || 0}
          total={recordsTotal || 0}
          showSizeChanger
          onChange={handleChangePagination}
        />
      </div>
    </Modal>
  )
}

export default OrderProductsModal
