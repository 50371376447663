import React from 'react'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { useLogin } from 'src/hooks/auth'

import { Input, Password, Button, Info, Icons } from 'src/components'

import { PATHS } from 'src/config'

import styles from './login.module.scss'

const validationSchema = Yup.object().shape({
  login: Yup.string().required('Необходимое поле').email('Невалидный email'),
  password: Yup.string().required('Необходимое поле'),
})

const LoginPage = () => {
  const loginMutation = useLogin()

  const { values, errors, submitCount, handleChange, handleSubmit } = useFormik(
    {
      initialValues: {
        login: '',
        password: '',
      },
      onSubmit: (values) => loginMutation.mutate(values),
      validationSchema,
    },
  )

  return (
    <form className={styles.wrapper} onSubmit={handleSubmit}>
      {process.env.REACT_APP_ADMIN_PANEL !== 'true' ? (
        <img alt="" className={styles.logo} src="/logo.svg" />
      ) : (
        <h1>Админка</h1>
      )}{' '}
      {/* TODO remove admin */}
      <div className={styles.substrate}>
        <h2 hidden className={styles.title}>
          Вход
        </h2>
        <Input
          id="login"
          className={styles.login}
          value={values.login}
          error={submitCount ? errors.login : ''}
          IconBefore={Icons.User}
          placeholder="Введите email"
          onChange={handleChange}
        />

        <Password
          id="password"
          className={styles.password}
          value={values.password}
          error={submitCount ? errors.password : ''}
          placeholder="Введите пароль"
          onChange={handleChange}
        />

        <div className={styles.info}>
          {loginMutation.isError && (
            <Info type="error">Аккаунт с указанными данными не найден</Info>
          )}
        </div>

        <Button
          type="submit"
          size="large"
          className={styles.button}
          loading={loginMutation.isLoading}
        >
          Войти
        </Button>
        <Link to={PATHS.RECOVERY}>Не помню пароль</Link>
      </div>
    </form>
  )
}

export default LoginPage
