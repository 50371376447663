import React from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'

import styles from './filters.module.scss'

import { Input, Button, Icons } from 'src/components'

import { PATHS } from 'src/config'

interface FiltersProps {
  values: {
    query?: string | null
  }
  setFilterFieldValue: (key: string, value?: string | null) => void
}

const Filters = (props: FiltersProps) => {
  const { values, setFilterFieldValue } = props

  return (
    <div className={styles.topPanel}>
      <Input
        value={values.query || ''}
        inputClassName={styles.searchInput}
        placeholder="Поиск по названию или артикулу"
        template="small"
        withoutError
        IconBefore={Icons.Search}
        onChange={(e) => setFilterFieldValue('query', e.currentTarget.value)}
      />

      <Button icon={<Icons.Categories />} className={styles.categoriesButton}>
        Категории
      </Button>

      <Button
        template="ghost"
        icon={<Icons.Import />}
        className={styles.importButton}
      >
        Импорт
      </Button>

      <Button
        template="ghost"
        icon={<Icons.Export />}
        className={styles.exportButton}
      >
        Экспорт
      </Button>

      {PATHS.NEW_PRODUCT ? ( //TODO remove admin
        <Link to={PATHS.NEW_PRODUCT}>
          <Button icon={<Icons.Plus />}>Новый товар</Button>
        </Link>
      ) : null}
    </div>
  )
}

export default Filters
