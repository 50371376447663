import React, { useState } from 'react'

import styles from './order-payment-block.module.scss'

import OrderPaymentModal from './OrderPaymentModal'

import Block from '../Block'

import { PaymentBadge, Price } from 'src/components'

import { Order } from 'src/interfaces'

interface OrderPaymentBlockProps {
  currentOrder: Order
  sum: number
}

const OrderPaymentBlock = (props: OrderPaymentBlockProps) => {
  const { currentOrder, sum } = props

  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <Block
        title="Оплата"
        subTitle={<PaymentBadge data={currentOrder} />}
        onOpenEdit={() => setShowModal(true)}
      >
        <div className={styles.wrapper}>
          <div className={styles.block}>
            <div className={styles.blockTitle}>Способ оплаты</div>
            <div className={styles.flex}>
              {currentOrder.paymentMethodName || 'MOCK_PAYMENT_METHOD'}
              <div className={styles.price}>
                <Price value={sum} />
              </div>
            </div>
          </div>

          <div className={styles.block}>
            <div className={styles.blockTitle}>Страховка при доставке</div>
            <div className={styles.flex}>
              Объявленная ценность
              <div className={styles.price}>
                <Price value={currentOrder.insuranceSum} />
              </div>
            </div>
          </div>
        </div>
      </Block>

      {showModal ? (
        <OrderPaymentModal
          currentOrder={currentOrder}
          onClose={() => setShowModal(false)}
        />
      ) : null}
    </>
  )
}

export default OrderPaymentBlock
