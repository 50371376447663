import React from 'react'
import { Link } from 'react-router-dom'

import styles from './page.module.scss'

import { ReactComponent as BackIcon } from './assets/back.svg'

interface PageType {
  title: string
  backUrl?: string
  leftBlock?: React.ReactNode
  rightBlock?: React.ReactNode
  children: React.ReactNode
}

const Page: React.FC<PageType> = (props) => {
  const { title, backUrl, leftBlock, rightBlock, children } = props
  return (
    <div className={styles.wrapper}>
      <div className={styles.titleBlock}>
        {backUrl ? (
          <Link to={backUrl} className={styles.backIcon}>
            <BackIcon />
          </Link>
        ) : null}
        <h1>{title}</h1>

        {leftBlock ? <div className={styles.leftBlock}>{leftBlock}</div> : null}

        {rightBlock ? (
          <div className={styles.rightBlock}>{rightBlock}</div>
        ) : null}
      </div>
      {children}
    </div>
  )
}

export default Page
