import React from 'react'

import styles from './product-preview.module.scss'

import Gallery from './Gallery'

import { VariantsBlock } from 'src/components'

import { PRODUCT_CREATING as DICTIONARY } from 'src/dictionaries'

import { useCategories, useAttributes } from 'src/hooks/products'

import {
  isMultiVariant,
  getCategoryNameString,
  getAttributeValue,
} from 'src/helpers'

import {
  Attribute,
  ProductCreatingValues,
  TreeOptionType,
} from 'src/interfaces'

interface InfoItemType {
  label: string
  value: any
  className?: string
  isHtml?: boolean
}

const getInfoData = (
  values: ProductCreatingValues,
  categories: TreeOptionType[],
  productId?: string,
) => {
  const categoryNameStr = getCategoryNameString({
    items: categories,
    guid: values.categoryId,
  })
  if (isMultiVariant(values)) {
    return [
      {
        label: DICTIONARY.SHORT_NAME,
        value: values.shortName,
        className: 'productName',
      },
      { label: DICTIONARY.CATEGORY, value: categoryNameStr },
      {
        label: DICTIONARY.DESCRIPTION,
        value: values.description,
        isHtml: true,
      },
      { label: 'ID', value: productId },
    ]
  } else {
    return [
      {
        label: DICTIONARY.SHORT_NAME,
        value: values.shortName,
        className: 'productName',
      },
      { label: DICTIONARY.CATEGORY, value: categoryNameStr },
      {
        label: DICTIONARY.DESCRIPTION,
        value: values.description,
        isHtml: true,
      },
      {
        label: DICTIONARY.ARTICLE,
        value: values.variantsToUpdate?.[0].article,
      },
      {
        label: DICTIONARY.BARCODE,
        value: values.variantsToUpdate?.[0].barcodes.find((b) => b.isDefault)
          ?.value,
      },
      { label: DICTIONARY.PRICE, value: values.variantsToUpdate?.[0].price },
      {
        label: DICTIONARY.OLD_PRICE,
        value: values.variantsToUpdate?.[0].oldPrice,
      },
      { label: DICTIONARY.WEIGHT, value: values.variantsToUpdate?.[0].weight },
      {
        label: `${DICTIONARY.WIDTH} x ${DICTIONARY.HEIGHT} x ${DICTIONARY.DEPTH}, см`,
        value: `${values.variantsToUpdate?.[0].dimensions.width} x ${values.variantsToUpdate?.[0].dimensions.height} x ${values.variantsToUpdate?.[0].dimensions.depth}`,
      },
      { label: 'ID', value: productId },
      { label: DICTIONARY.EXT_ID, value: values.variantsToUpdate?.[0].extID },
    ]
  }

  return []
}

interface PreviewProps {
  values: ProductCreatingValues
  meta?: {
    currentId: string
  }
}

const Preview = (props: PreviewProps) => {
  const { values, meta } = props

  const { data: { attributes } = { attributes: [] } } = useAttributes({
    categoryId: values.categoryId || '',
  })

  const { data: { categories } = { categories: [] } } = useCategories()

  const infoData = getInfoData(values, categories, meta?.currentId)

  return (
    <div className={styles.wrapper}>
      {values.images?.length ? <Gallery images={values.images} /> : null}

      <h2>Информация о товаре</h2>
      <div className={styles.dataBlock}>
        {(infoData as InfoItemType[]).map(
          (infoItem: InfoItemType, i: number) => (
            <div key={i}>
              <div className={styles.label}>{infoItem.label}</div>
              {infoItem.isHtml ? (
                <div dangerouslySetInnerHTML={{ __html: infoItem.value }} />
              ) : (
                <div
                  className={
                    infoItem.className ? styles[infoItem.className] : ''
                  }
                >
                  {infoItem.value}
                </div>
              )}
            </div>
          ),
        )}
      </div>

      {attributes ? (
        <>
          <h2>Характеристики</h2>
          <div className={styles.dataBlock}>
            {attributes.map((a: Attribute) => (
              <div key={a.id}>
                <div className={styles.label}>{a.title}</div>
                <div>
                  {getAttributeValue(
                    a.type,
                    values.attributes?.find((v) => v.code === a.code) ?? null,
                  )}
                </div>
              </div>
            ))}
          </div>
        </>
      ) : null}

      {isMultiVariant(values) ? (
        <>
          <h2>Варианты</h2>
          <VariantsBlock
            readOnly
            variants={values.variantsToUpdate}
            images={values.images}
          />
        </>
      ) : null}
    </div>
  )
}

export default Preview
