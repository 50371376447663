import React, { useState } from 'react'
import cx from 'classnames'

import styles from './order-delivery-block.module.scss'

import Block from '../Block'
import OrderDeliveryModal from './OrderDeliveryModal'

import { Price, Icons } from 'src/components'

import { DELIVERY_TYPES } from 'src/config'

import { OrderDelivery } from 'src/interfaces'

interface OrderDeliveryBlockProps {
  delivery: OrderDelivery
  price?: number
}

const OrderDeliveryBlock = (props: OrderDeliveryBlockProps) => {
  const { delivery, price } = props

  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <Block
        title="Доставка"
        subTitle={DELIVERY_TYPES[delivery.type]}
        onOpenEdit={() => setShowModal(true)}
      >
        <div className={styles.wrapper}>
          <div className={styles.block}>
            <div className={styles.blockTitle}>Способ доставки</div>
            <div className={styles.type}>
              MOCK_DELIVERY_TYPE
              {typeof price !== 'undefined' ? (
                <div className={styles.price}>
                  <Price value={price} />
                </div>
              ) : null}
            </div>
          </div>

          <div className={styles.block}>
            <div className={styles.blockTitle}>Адрес доставки</div>
            <div className={styles.fullAddress}>
              {`${delivery.address.zip ? delivery.address.zip + ', ' : ''} ${
                delivery.address.fullAddress
              }`}
              <Icons.Location
                className={cx(
                  !delivery.address.cityFias &&
                    !delivery.address.settlementFias &&
                    styles.error,
                )}
              />
            </div>
          </div>
        </div>
      </Block>

      {showModal ? (
        <OrderDeliveryModal
          defaultData={delivery}
          onClose={() => setShowModal(false)}
        />
      ) : null}
    </>
  )
}

export default OrderDeliveryBlock
