import React, { useEffect, useMemo } from 'react'
import cx from 'classnames'
import { useNotifications } from '../../'

import styles from './notification.module.scss'

import { Icons } from 'src/components'

import { INotification } from '../Notifications'

import { NOTIFICATIONS_TIMEOUT } from 'src/config'

const Icon = (props: { type: string }) => {
  const { type } = props
  switch (type) {
    case 'success':
      return <Icons.CircleCheck className={styles[type]} />
    case 'info':
      return <Icons.CircleInfo className={styles[type]} />
    case 'error':
      return <Icons.CircleClose className={styles[type]} />
    default:
      return null
  }
}

type NotificationProps = INotification & {
  id: string
  onButtonClick: (id: INotification['id'], callback?: () => void) => void
}

const Notification = (props: NotificationProps) => {
  const { id, type, content, options, onButtonClick } = props

  const context = useNotifications()

  useEffect(() => {
    if (!options?.buttons) {
      setTimeout(() => {
        context?.clear(id)
      }, 3000)
    }
  }, [])

  return (
    <div className={styles.notification}>
      <Icon type={type} />
      {content}
      {options?.buttons ? (
        <div className={styles.buttonsWrapper}>
          {options.buttons.map((b, i) => (
            <div
              key={i}
              className={cx(styles.button, b.type && styles[b.type])}
              //onClick={() => handleButtonClick(id, b.onClick)}
              onClick={() => onButtonClick(id, b.onClick)}
            >
              {b.label}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  )
}

export default Notification
