import React from 'react'

import Sidebar from './Sidebar'
import Header from './Header'
import { Icons } from 'src/components'

import { PRIVATE_PATHS } from 'src/config'

import styles from './private-layout.module.scss'

const Layout: React.FC = (props) => {
  const { children } = props

  const MENU_ITEMS =
    process.env.REACT_APP_ADMIN_PANEL !== 'true'
      ? [
          [
            {
              Icon: Icons.House,
              path: PRIVATE_PATHS.PRODUCTS,
              title: 'Продукты',
            },
          ],
          [
            {
              Icon: Icons.Basket,
              path: 'orders',
              title: 'Заказы',
            },
            {
              Icon: Icons.Box,
              path: 'supplies',
              title: 'Поставки',
            },
          ],
        ]
      : [[]]

  return (
    <div className={styles.wrapper}>
      <Sidebar menuItems={MENU_ITEMS} />
      <div className={styles.inner}>
        <Header menuItems={MENU_ITEMS} />
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  )
}

export default Layout
