import { useQuery } from 'react-query'

import api from 'src/api'

const getPaymentMethods = async () => {
  const result = await api.get(`v1/settings/payment-methods`)
  if (!result.data?.success) throw new Error(result.data.message)
  return result.data
}

interface PaymentMethodsResult {
  paymentMethods: {
    code: string
    name: string
    paymentMethod: string
  }[]
}

export const usePaymentMethods = () => {
  return useQuery<PaymentMethodsResult>(
    [`paymenthMethods`],
    () => getPaymentMethods(),
    {
      keepPreviousData: true,
      initialData: { paymentMethods: [] },
    },
  ) as { data: PaymentMethodsResult }
}
