import React, { useState, useEffect, Suspense } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { UseMutationResult } from 'react-query'
import cx from 'classnames'

import appLocalStorage from 'src/libs/localStorage'

import styles from './steps-form.module.scss'

import { Button, Spinner } from 'src/components'

export interface StepType {
  name: string
  title: string
  icon: React.FC
  component: React.LazyExoticComponent<React.FC<React.PropsWithChildren<any>>>
  validationSchema?: Yup.AnyObjectSchema
}

interface StepsFormType<ValuesType> {
  initialValues?: ValuesType
  mutation: UseMutationResult
  className?: string
  currentStep: StepType
  isFirstStep: boolean
  isLastStep?: boolean
  stepSaving?: boolean
  lastStepButtonText?: string
  meta?: any
  unfinishedStorage?: string
  setPrevStep?: () => void
  setNextStep?: () => void
  reformatData?: (values: ValuesType) => unknown //TODO type ?
  onSuccess?: (res: any, values: ValuesType) => any
}

const StepsForm = <ValuesType,>(props: StepsFormType<ValuesType>) => {
  const {
    initialValues = {},
    mutation,
    className,
    currentStep,
    isFirstStep,
    isLastStep,
    stepSaving,
    lastStepButtonText = 'Сохранить',
    meta,
    unfinishedStorage,
    setPrevStep,
    setNextStep,
    reformatData = (data) => data,
    onSuccess = () => false,
  } = props

  const [showErrors, setShowErrors] = useState(false)

  const gotoNextStep = () => {
    if (setNextStep) {
      setShowErrors(false)
      setNextStep()
    }
  }

  const {
    values,
    errors,
    submitCount,
    handleChange,
    setFieldValue,
    validateForm,
    setValues,
    submitForm,
  } = useFormik({
    initialValues: initialValues as ValuesType,
    onSubmit: async (values) => {
      mutation.mutate(reformatData(values), {
        onSuccess: async (res) => {
          const valuesUpdating = await onSuccess(res, values)

          if (valuesUpdating) {
            setValues({
              ...values,
              ...valuesUpdating,
            })
          }

          if (stepSaving && !isLastStep) {
            gotoNextStep()
          }
        },
      })
    },
    validationSchema: currentStep.validationSchema || Yup.object().shape({}),
    enableReinitialize: true,
  })

  useEffect(() => {
    if (unfinishedStorage) {
      appLocalStorage.setData(unfinishedStorage, {
        values,
        ...meta,
      })
    }
  }, [values, meta])

  const handleNextButtonClick = () => {
    if (isLastStep) {
      submitForm()
    } else {
      if (!stepSaving) {
        setShowErrors(true)
        validateForm().then((result) => {
          if (!Object.values(result).length) {
            gotoNextStep()
          }
        })
      } else {
        submitForm()
      }
    }
  }
  const stepProps = {
    meta,
    values,
    errors: submitCount || showErrors ? errors : {},
    handleChange,
    setFieldValue,
  }

  return (
    <form className={cx(styles.wrapper, className)}>
      <Suspense fallback={<Spinner />}>
        <currentStep.component {...stepProps} />
      </Suspense>
      <div className={styles.buttons}>
        {!isFirstStep ? (
          <Button
            key={`${currentStep.name}-back`}
            template="ghost"
            size="large"
            type="button"
            className={styles.backButton}
            onClick={setPrevStep}
          >
            Назад
          </Button>
        ) : null}
        <Button
          key={currentStep.name}
          size="large"
          loading={mutation.isLoading}
          type="button"
          className={styles.stepButton}
          onClick={handleNextButtonClick}
        >
          {isLastStep ? lastStepButtonText : 'Продолжить'}
        </Button>
      </div>
    </form>
  )
}

export default StepsForm
