import React, { useEffect, useState } from 'react'

import styles from './pvz-manual.module.scss'

import { Input, Button } from 'src/components'

import { useDeliveryPoint } from 'src/ADMIN/hooks'

import { DeliveryPoint, Tariff } from 'src/ADMIN/interfaces'

interface PvzManualProps {
  tariff?: Tariff
  pointFieldValue: string | null
  setCurrentPoint: (point?: DeliveryPoint) => void
}

const PvzManual = (props: PvzManualProps) => {
  const { tariff, pointFieldValue, setCurrentPoint } = props

  const [pointCode, setPointCode] = useState(pointFieldValue || '')

  const { data: point, refetch: queryCheckPoint } = useDeliveryPoint(
    {
      courierCode: tariff?.courier || '',
      pvzCode: pointCode,
    },
    {
      onError: () => setCurrentPoint(),
    },
  )

  useEffect(() => {
    if (point) {
      setCurrentPoint(point)
    }
  }, [point])

  const handleCheckPoint = () => {
    queryCheckPoint()
  }

  return (
    <div className={styles.wrapper}>
      <Input
        label="Код пункта выдачи"
        value={pointCode}
        onChange={(e) => setPointCode(e.currentTarget.value)}
      />

      <Button
        className={styles.checkButton}
        size="large"
        disabled={pointCode === ''}
        onClick={handleCheckPoint}
      >
        Проверить
      </Button>
    </div>
  )
}

export default PvzManual
