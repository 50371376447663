import React, { useState } from 'react'

import { Input, Icons } from 'src/components'

import styles from './password.module.scss'

type InputType = React.InputHTMLAttributes<HTMLInputElement> & {
  error?: string
}

const Password: React.FC<InputType> = (props) => {
  const [visibility, setVisibility] = useState(false)

  const Icon = Icons[!visibility ? 'EyeClosed' : 'Eye']

  return (
    <Input
      {...props}
      type={!visibility ? 'password' : 'text'}
      IconBefore={Icons.Lock}
    >
      {/* TODO изменить iconBefore из string на Icon */}
      <Icon
        className={styles.iconAfter}
        onClick={() => setVisibility(!visibility)}
      />
    </Input>
  )
}

export default Password
