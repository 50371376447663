import React, { useState, useEffect } from 'react'

import styles from './table.module.scss'

import { ActionsPanel, Checkbox, Icons } from 'src/components'

const Row = <RowData,>(
  props: React.PropsWithChildren<{ id?: string; data?: RowData }>,
) => {
  const { children } = props

  return <>{children}</>
}

const Column = (
  props: React.PropsWithChildren<{ title?: string; className?: string }>,
) => {
  const { children } = props

  return <>{children}</>
}

interface TableProps {
  FilterPanel?: React.FC
  SelectPanel?: any //TODO type
  cacheSelected?: boolean
  actions?: any[] //TODO type
  children:
    | React.ReactElement<React.ComponentProps<typeof Row>>
    | React.ReactElement<React.ComponentProps<typeof Row>>[]
}

const Table = <RowData,>(props: React.PropsWithChildren<TableProps>) => {
  const { FilterPanel, SelectPanel, actions, children } = props

  const [selected, setSelected] = useState<{
    [key: string]: boolean | RowData | null
  }>({})
  const [selectAll, setSelectAll] = useState<boolean>(false)

  const handleClickSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectAll(event.currentTarget.checked)

    const newSelected = { ...selected }
    React.Children.map(children, (c: any) => {
      //TODO type
      newSelected[c.props.id] = c.props.data
        ? event.currentTarget.checked
          ? { ...c.props.data }
          : null
        : event.currentTarget.checked
    })
    console.log('NEW SELECTED')
    console.log(newSelected)
    setSelected(newSelected)
  }

  const handleClickCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
    //c: React.ReactElement<React.ComponentProps<typeof Row>>,
    c: {
      props: {
        id?: string
        data?: RowData
      }
    },
  ) => {
    if (typeof c.props.id !== 'undefined') {
      setSelected({
        ...selected,
        [c.props.id]: c.props.data
          ? event.currentTarget.checked
            ? { ...c.props.data }
            : null
          : event.currentTarget.checked,
      })
      setSelectAll(false)
    }
  }

  const defaultRow = (
    children
      ? Array.isArray(children)
        ? children[0]
        : [children]
      : [{ props: { children: [] } }]
  ) as { props: any } //TODO type
  const columns = defaultRow
    ? defaultRow.props.children.map((c: { props: { title: string } }) => ({
        title: c.props.title,
      }))
    : []

  const selectedItems = Object.keys(selected).filter(
    (key: string) => selected[key],
  )

  return (
    <div className={styles.wrapper}>
      {FilterPanel ? (
        <div className={styles.filterPanel}>
          <FilterPanel />
        </div>
      ) : null}
      {columns.length ? (
        <table>
          <thead>
            <tr>
              {SelectPanel ? (
                <th className={styles.checkboxCell}>
                  <Checkbox
                    indeterminate={!selectAll && selectedItems.length > 0}
                    checked={selectAll}
                    onChange={(e) => handleClickSelectAll(e)}
                  />
                </th>
              ) : null}

              {columns.map((c: { title: string }, i: number) => (
                <th key={i}>{c.title}</th>
              ))}

              {actions ? <th>Действия</th> : null}
            </tr>
          </thead>
          <tbody>
            {React.Children.map(
              children,
              (
                c: any, //TODO type
                i: number,
              ) => (
                <tr key={i}>
                  {SelectPanel ? (
                    <td className={styles.checkboxCell}>
                      <Checkbox
                        checked={Boolean(selected[c.props.id])}
                        onChange={(e) => handleClickCheckbox(e, c)}
                      />
                    </td>
                  ) : null}

                  {c.props.children.map((cc: any, j: number) => (
                    <td key={`${i}-${j}`} className={cc.props.className}>
                      {cc.props.children}
                    </td>
                  ))}

                  {actions ? (
                    <td className={styles.actions}>
                      <Icons.Dots />
                    </td>
                  ) : null}
                </tr>
              ),
            )}
          </tbody>
        </table>
      ) : null}

      {SelectPanel && selectedItems.length ? (
        <ActionsPanel>
          <SelectPanel<RowData>
            selectedItems={selectedItems}
            itemsData={selected}
            onUnselect={() => setSelected({})}
          />
        </ActionsPanel>
      ) : null}
    </div>
  )
}

Table.Row = Row
Table.Column = Column

export default Table
