import React, { useState } from 'react'

import styles from './order-receiver-block.module.scss'

import OrderReceiverModal from './OrderReceiverModal'

import Block from '../Block'

import { getPhoneWithMask } from 'src/helpers'

import { OrderReceiver } from 'src/interfaces'

interface OrderReceiverBlockProps {
  receiver: OrderReceiver
}

const OrderReceiverBlock = (props: OrderReceiverBlockProps) => {
  const { receiver } = props

  const [showModal, setShowModal] = useState<boolean>(false)

  return (
    <>
      <Block
        title="Покупатель"
        subTitle={receiver.fullName}
        onOpenEdit={() => setShowModal(true)}
      >
        <div className={styles.wrapper}>
          <div className={styles.block}>
            <div className={styles.blockTitle}>ФИО</div>
            <div>{receiver.fullName}</div>
          </div>

          {receiver.phone ? (
            <div className={styles.block}>
              <div className={styles.blockTitle}>Телефон</div>
              <div>{getPhoneWithMask(receiver.phone)}</div>
            </div>
          ) : null}
        </div>
      </Block>

      {showModal ? (
        <OrderReceiverModal onClose={() => setShowModal(false)} />
      ) : null}
    </>
  )
}

export default OrderReceiverBlock
