import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import styles from './order-receiver-modal.module.scss'

import { Input, PhoneInput, Button, Modal } from 'src/components'

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Невалидный email'),
})

interface OrderReceiverModalProps {
  // onSubmit: (image: ProductCreatingImage) => void
  onClose: () => void
}

const OrderReceiverModal = (props: OrderReceiverModalProps) => {
  const { onClose } = props

  const { values, errors, submitCount, handleChange, handleSubmit } = useFormik(
    {
      initialValues: {
        name: '',
        surname: '',
        patronymic: '',
        rawPhone: '',
        alternativePhone: '',
        email: '',
      },
      validationSchema,
      onSubmit: () => {
        onClose()
      },
    },
  )

  return (
    <Modal id="receiverModal" title="Данные покупателя" onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <div className={styles.row}>
          <Input
            label="Имя"
            id="name"
            value={values.name}
            onChange={handleChange}
          />

          <Input
            label="Фамилия"
            id="surname"
            value={values.surname}
            onChange={handleChange}
          />
        </div>

        <div className={styles.row}>
          <Input
            label="Отчество"
            id="patronymic"
            value={values.patronymic}
            onChange={handleChange}
          />

          <div />
        </div>

        <div className={styles.row}>
          <PhoneInput
            label="Номер телефона"
            id="rawPhone"
            value={values.rawPhone}
            onChange={handleChange}
          />

          <PhoneInput
            label="Доп. номер телефона"
            id="alternativePhone"
            value={values.alternativePhone}
            onChange={handleChange}
          />
        </div>

        <div className={styles.row}>
          <Input
            label="Email"
            id="email"
            value={values.email}
            error={submitCount ? errors.email : ''}
            onChange={handleChange}
          />

          <div />
        </div>

        <Button type="submit" className={styles.button} size="large">
          Сохранить
        </Button>
      </form>
    </Modal>
  )
}

export default OrderReceiverModal
