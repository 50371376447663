import React from 'react'

import styles from './ordered.module.scss'

import { OrderProduct } from 'src/interfaces'

interface OrderedProps {
  product: OrderProduct
}

const Ordered = (props: OrderedProps) => {
  const { product } = props

  return (
    <div className={styles.wrapper}>
      {product.variantId !== 'delivery' ? (
        <>
          <div>{`${product.quantity} шт.`}</div>
          <div
            className={styles.secondary}
          >{`Остаток: ${product.quantityAvailable}`}</div>
        </>
      ) : (
        <div className={styles.delivery}>Услуга</div>
      )}
    </div>
  )
}

export default Ordered
