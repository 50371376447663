import React from 'react'

import { TableTabs, Icons } from 'src/components'

import { useModerationStatuses } from 'src/ADMIN/hooks'

import { TableTab } from 'src/interfaces'

interface ProductsTab {
  key: string
  title: string
  icon: React.SVGProps<SVGElement>
  count: number
}

export const PRODUCTS_PAGE_TABS: ProductsTab[] = [
  {
    key: 'all',
    title: 'Все',
    count: 942,
    icon: <Icons.Box2d />,
  },
  {
    key: 'sale',
    title: 'В продаже',
    count: 942,
    icon: <Icons.Bag />,
  },
  {
    key: 'ready',
    title: 'Готовы к продаже',
    count: 942,
    icon: <Icons.ArchiveOut />,
  },
  {
    key: 'errors',
    title: 'С ошибками',
    count: 942,
    icon: <Icons.Danger />,
  },
  {
    key: 'withdrawn',
    title: 'Сняты с продажи',
    count: 942,
    icon: <Icons.ArchiveIn />,
  },
  {
    key: 'archive',
    title: 'Архив',
    count: 942,
    icon: <Icons.Archive />,
  },
]

interface ProductsTabsProps {
  currentTab?: string | null
  onTabChange: (id: string) => void
}

const ProductsTabs = (props: ProductsTabsProps) => {
  const { currentTab, onTabChange } = props

  //const { data: statusesLength } = useModerationStatuses()

  return (
    <TableTabs
      currentTab={currentTab}
      tabsData={PRODUCTS_PAGE_TABS}
      onTabChange={onTabChange}
    />
  )
}

export default ProductsTabs
