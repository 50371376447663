import React from 'react'
import cx from 'classnames'

import styles from './value-item.module.scss'

import { Icons } from 'src/components'

import { TreeOptionType } from 'src/interfaces'

const ValueItem: React.FC<{
  name: string
  size?: 'small' | 'normal'
  disabled?: boolean
  onRemove: (event: React.MouseEvent) => void
}> = (props) => {
  const { name, size, disabled, onRemove } = props

  return (
    <div className={cx(styles.valueItem, size && styles[size])}>
      {name}
      {!disabled ? <Icons.Close onClick={onRemove} /> : null}
    </div>
  )
}

export default ValueItem
