import React, { createContext, useEffect, useMemo, useState } from 'react'

import { renderPrivateRoutes, renderPublicRoutes } from 'src/routing'

import { NotificationProvider } from './notification'

import { PrivateLayout, PublicLayout } from 'src/components'
import { PrivateLayout as PrivateAdminLayout } from 'src/ADMIN/components/' //TODO remove admin

import api from 'src/api'
import appLocalStorage from 'src/libs/localStorage'

const PrivateRoute =
  process.env.REACT_APP_ADMIN_PANEL !== 'true'
    ? PrivateLayout
    : PrivateAdminLayout

export const AppContext = createContext({
  authorized: false,
  setAuthorized: (authorized: boolean) => void authorized as void,
  currencySymbol: '₽',
})

const App = () => {
  const [authorized, setAuthorized] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const token = appLocalStorage.token
    if (token) {
      api
        .post(`v1/auth/refresh`, null, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(({ data }) => {
          appLocalStorage.token = data.refresh_token
          api.defaults.headers.Authorization = `Bearer ${data.access_token}`
          setAuthorized(true)
          setLoading(false)
        })
        .catch(() => setLoading(false))
    } else setLoading(false)
  }, [])

  return useMemo(
    () =>
      loading ? null : (
        <AppContext.Provider
          value={{
            authorized,
            setAuthorized,
            currencySymbol: '₽',
          }}
        >
          <NotificationProvider>
            {authorized ? (
              <PrivateRoute>{renderPrivateRoutes()}</PrivateRoute>
            ) : (
              <PublicLayout>{renderPublicRoutes()}</PublicLayout>
            )}
          </NotificationProvider>
        </AppContext.Provider>
      ),
    [authorized, loading],
  )
}

export default App
