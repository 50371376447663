import React, { useState } from 'react'

import styles from './variants-block.module.scss'

import ImagesModal from './ImagesModal'
import EditableTd from './EditableTd'

import { Icons, FileItem } from 'src/components'

import {
  Barcode,
  FeatureValue,
  ProductCreatingVariantValues,
  ProductCreatingImage,
} from 'src/interfaces'

const getUpdatedVariants = (
  variants: ProductCreatingVariantValues[],
  variantIndex: number,
  prop: string,
  value: string | number,
) => {
  const newVariants = variants.slice()

  switch (prop) {
    case 'barcode':
      const commonBarcode = (newVariants[variantIndex].barcodes || []).find(
        (b: Barcode) => b.type === 'COMMON',
      )
      if (typeof value === 'string') {
        if (commonBarcode) {
          commonBarcode.value = value
        } else {
          if (!newVariants[variantIndex].barcodes) {
            newVariants[variantIndex].barcodes = []
          }
          newVariants[variantIndex].barcodes.push({
            isDefault: true,
            type: 'COMMON',
            value,
          })
        }
      }
      break
    default:
      newVariants[variantIndex] = {
        ...newVariants[variantIndex],
        [prop]: value,
      }
  }

  return newVariants
}

interface ImageTdType {
  readOnly?: boolean
  variantIndex: number
  variantImageUrl?: string
  variantImageId?: string
  images?: ProductCreatingImage[]
  setShowModal: (variantIndex: number) => void
  handleUnsetImage: (variantIndex: number) => void
}

const ImageTd: React.FC<ImageTdType> = (props) => {
  const {
    readOnly,
    variantIndex,
    variantImageUrl,
    variantImageId,
    images,
    setShowModal,
    handleUnsetImage,
  } = props

  if (images && images.length) {
    if (!readOnly && !variantImageId && !variantImageUrl) {
      return (
        <td
          className={styles.addImage}
          onClick={() => setShowModal(variantIndex)}
        >
          <div>+</div>
        </td>
      )
    } else {
      const currentImage = images.find(
        (i) => i.id === variantImageId || i.url === variantImageUrl,
      )
      return (
        <td>
          <FileItem
            className={styles.image}
            url={variantImageUrl}
            file={currentImage?.file}
            handleRemove={
              readOnly ? undefined : () => handleUnsetImage(variantIndex)
            }
          />
        </td>
      )
    }
  }
  return <td />
}

interface VariantsBlockType {
  readOnly?: boolean
  editMode?: boolean
  productId?: string
  variants: ProductCreatingVariantValues[]
  variantsForRemoving?: ProductCreatingVariantValues[]
  images?: ProductCreatingImage[]
  setVariants?: (variants: ProductCreatingVariantValues[]) => void
  setVariantsForRemoving?: (variants: ProductCreatingVariantValues[]) => void
}

const VariantsBlock: React.FC<VariantsBlockType> = (props) => {
  const {
    readOnly,
    editMode = false,
    productId,
    variants,
    variantsForRemoving,
    images,
    setVariants,
    setVariantsForRemoving,
  } = props

  const [modalForVariant, setShowModal] = useState<number | null>(null)

  const handleRemoveVariant = async (index: number) => {
    const currentVariant = variants[index]

    if (
      editMode &&
      currentVariant.id &&
      setVariantsForRemoving &&
      variantsForRemoving
    ) {
      setVariantsForRemoving([...variantsForRemoving, currentVariant])
    }

    const newVariants = variants.slice()
    newVariants.splice(index, 1)
    if (setVariants) {
      setVariants(newVariants)
    }
  }

  const handleSetImage = (image: ProductCreatingImage) => {
    const newVariants = variants.slice()
    newVariants[modalForVariant!] = {
      ...newVariants[modalForVariant!],
      imageId: !image.url ? image.id : undefined,
      imageUrl: image.url,
      defaultImage: undefined,
    }
    if (setVariants) {
      setVariants(newVariants)
    }
  }

  const handleUnsetImage = (variantIndex: number) => {
    const newVariants = variants.slice()
    newVariants[variantIndex] = {
      ...newVariants[variantIndex],
      imageId: undefined,
      imageUrl: undefined,
      defaultImage: undefined,
    }
    if (setVariants) {
      setVariants(newVariants)
    }
  }

  const handleUpdateVariant = (
    variantIndex: number,
    prop: string,
    value: string | number,
  ) =>
    setVariants
      ? setVariants(getUpdatedVariants(variants, variantIndex, prop, value))
      : false

  return (
    <>
      <table className={styles.variantsTable}>
        <thead>
          <tr>
            <th>Фото</th>
            <th>Значение</th>
            <th>Цена</th>
            <th>До скидки</th>
            <th>Артикул</th>
            <th>Штрихкод</th>
            {!readOnly ? <th>Действия</th> : null}
          </tr>
        </thead>
        <tbody>
          {variants?.map((v: ProductCreatingVariantValues, i: number) => (
            <tr key={i}>
              <ImageTd
                readOnly={readOnly}
                variantIndex={i}
                variantImageUrl={v.imageUrl || v.defaultImage}
                variantImageId={v.imageId}
                images={images}
                setShowModal={setShowModal}
                handleUnsetImage={handleUnsetImage}
              />

              <td className={styles.optionValues}>
                {v.optionsUsed &&
                  v.optionsUsed.map((option: FeatureValue) => (
                    <span key={option.code}>{option.stringValue}</span>
                  ))}
              </td>

              <EditableTd
                disabled={readOnly}
                type="number"
                className={styles.price}
                defaultValue={v.price}
                postfix="₽"
                onChange={(value) => handleUpdateVariant(i, 'price', value)}
              />

              <EditableTd
                disabled={readOnly}
                type="number"
                className={styles.price}
                defaultValue={v.oldPrice}
                postfix="₽"
                onChange={(value) => handleUpdateVariant(i, 'oldPrice', value)}
              />

              <EditableTd
                disabled={readOnly}
                defaultValue={v.article}
                onChange={(value) => handleUpdateVariant(i, 'article', value)}
              />

              <EditableTd
                disabled={readOnly}
                defaultValue={
                  v.barcodes
                    ? (
                        v.barcodes.find((b: Barcode) => b.type === 'COMMON') ||
                        {}
                      ).value
                    : undefined
                }
                onChange={(value) => handleUpdateVariant(i, 'barcode', value)}
              />
              {!readOnly ? (
                <td
                  className={styles.remove}
                  onClick={() => handleRemoveVariant(i)}
                >
                  <Icons.Trash />
                </td>
              ) : null}
            </tr>
          ))}
        </tbody>
      </table>

      {images && modalForVariant !== null ? (
        <ImagesModal
          images={images}
          onSubmit={handleSetImage}
          onClose={() => setShowModal(null)}
        />
      ) : null}
    </>
  )
}

export default VariantsBlock
