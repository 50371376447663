import React from 'react'

interface PaginationStateStringProps {
  page: number
  size?: number
  total?: number
}

const PaginationStateString = (props: PaginationStateStringProps) => {
  const { page, size = 10, total = 0 } = props

  if (!total) return null

  const currentPage = page + 1

  return (
    <>
      {`Показано ${(currentPage - 1) * size + 1} - ${
        currentPage * size > total ? total : currentPage * size
      } из ${total}`}
    </>
  )
}

export default PaginationStateString
