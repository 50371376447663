import React from 'react'
import * as Yup from 'yup'

import { Icons } from 'src/components'

import { Variant } from 'src/interfaces'

export const getProductValidationSchema = (isNew: boolean) =>
  Yup.object().shape({
    shortName: Yup.string().required('Введите название'),
    categoryId: Yup.string().required('Выберите категорию'),
    variantsToUpdate: isNew
      ? Yup.array(
          Yup.object().shape({
            article: Yup.string().required('Введите артикул'),
            price: Yup.number().required('Введите цену'),
            oldPrice: Yup.number().moreThan(
              Yup.ref('price'),
              'Должно быть больше цены',
            ),
            weight: Yup.string().required('Введите вес'),
            dimensions: Yup.object().shape({
              width: Yup.string().required('Введите ширину'),
              height: Yup.string().required('Введите высоту'),
              depth: Yup.string().required('Введите глубину'),
            }),
          }),
        )
      : Yup.array(),
  })

export const STEPS = [
  {
    name: 'info',
    title: 'Информация о товаре',
    icon: Icons.Info,
    component: React.lazy(() => import('../Steps/Info')),
    validationSchema: getProductValidationSchema(true),
  },
  {
    name: 'characteristics',
    title: 'Характеристики',
    icon: Icons.Ruler,
    component: React.lazy(() => import('../Steps/Characteristics')),
  },
  {
    name: 'media',
    title: 'Медиа',
    icon: Icons.Media,
    component: React.lazy(() => import('../Steps/Media')),
  },
  {
    name: 'variants',
    title: 'Варианты',
    icon: Icons.Variants,
    component: React.lazy(() => import('../Steps/Variants')),
  },
  {
    name: 'preview',
    title: 'Предпросмотр',
    icon: Icons.Desktop,
    component: React.lazy(() => import('../Steps/Preview')),
  },
]
