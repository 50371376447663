interface getFullAddressProps {
  street?: string
  house?: string
  block?: string
  building?: string
  flat?: string
}

export const getFullAddress = ({
  street,
  house,
  block,
  building,
  flat,
}: getFullAddressProps) => {
  let result = ''
  if (street) result += `${street}, `
  if (house) result += `д.${house} `
  if (block) result += `к.${block} `
  if (building) result += `стр.${building} `
  if (flat) result += `кв.${flat}`
  return result
}
