import React from 'react'
import { useFormik } from 'formik'

import styles from './order-payment-modal.module.scss'

import { Input, Select, Checkbox, Button, Modal } from 'src/components'

import { usePaymentMethods, useCalcOrderInsuranceSum } from 'src/hooks'

import { Order } from 'src/interfaces'

interface OrderPaymentModalProps {
  // onSubmit: (image: ProductCreatingImage) => void
  currentOrder: Order
  onClose: () => void
}

const OrderPaymentModal = (props: OrderPaymentModalProps) => {
  const { currentOrder, onClose } = props

  const {
    data: { paymentMethods },
  } = usePaymentMethods()
  const calcOrderInsuranceSum = useCalcOrderInsuranceSum()

  const {
    values,
    errors,
    submitCount,
    setFieldValue,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      paymentMethodCode: undefined,
      insuranceSum: undefined,
      insuranceSumAutoCalculation: false,
    },
    onSubmit: () => {
      onClose()
    },
  })

  const handleUpdateInsuranceSumAutoCalculation = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const isChecked = event.currentTarget.checked
    if (isChecked) {
      const result = await calcOrderInsuranceSum.mutate({ ...currentOrder })
      //TODO
    }
  }

  const paymentMethodsOptions = paymentMethods.map((m) => ({
    label: m.name,
    value: m.code,
  }))

  return (
    <Modal id="paymentModal" title="Оплата" onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <Select
          id="paymentMethodCode"
          label="Способ оплаты"
          options={paymentMethodsOptions}
          value={values.paymentMethodCode}
          onSelect={(value) => setFieldValue('paymentMethodCode', value)}
        />

        <Input
          id="insuranceSum"
          label="Сумма объявленной ценности"
          value={values.insuranceSum}
          onChange={handleChange}
        />

        <Checkbox
          id="insuranceSumAutoCalculation"
          label="Авторасчет"
          checked={values.insuranceSumAutoCalculation}
          onChange={handleUpdateInsuranceSumAutoCalculation}
        />

        <Button
          type="submit"
          className={styles.button}
          size="large"
          loading={calcOrderInsuranceSum.isLoading}
        >
          Сохранить
        </Button>
      </form>
    </Modal>
  )
}

export default OrderPaymentModal
