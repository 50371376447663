import { ReactNode } from 'react'

interface Props {
  icon?: ReactNode
  children?: ReactNode
  elementsCount?: number
  onClick?: (id: string) => void
}

const TabPane = (props: Props) => props && null

export default TabPane
