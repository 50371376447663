import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import styles from './single-moderation.module.scss'

import { Page, ProductPreview, Textarea, Button, Icons } from 'src/components'

import { useModerationTasks, useAcceptProductModeration } from 'src/ADMIN/hooks'
import { useNotifications } from 'src/notification'

import { getProductFormikInitialValues } from 'src/helpers'

import { PATHS } from 'src/config'

import { ModerationTask } from 'src/ADMIN/interfaces'

const getCurrentData = (currentTask: ModerationTask) => {
  if (currentTask.status === 'NEW') {
    if (currentTask.resultProductDto) {
      return getProductFormikInitialValues(
        JSON.parse(currentTask.resultProductDto),
      )
    }
  } else {
    if (currentTask.sourceProductDto) {
      return getProductFormikInitialValues(
        JSON.parse(currentTask.sourceProductDto),
      )
    }
  }

  return null
}

const SingleModeration = () => {
  const { moderationId } = useParams<{ moderationId: string }>()
  const history = useHistory()

  const [comment, setComment] = useState<string | null>(null)

  const {
    data: {
      tasks: { 0: currentTask },
    },
  } = useModerationTasks({ id: moderationId })

  const currentData = currentTask ? getCurrentData(currentTask) : null

  const acceptProductModeration = useAcceptProductModeration()

  // const { showNotification } = useNotifications()
  const context = useNotifications()

  const handleAcceptTask = async (status: 'ACCEPTED' | 'DECLINED') => {
    acceptProductModeration.mutate(
      {
        productId: currentTask?.productId,
        productUuid: currentTask?.productUuid,
        status,
        comment,
      },
      {
        onSuccess: () => {
          // showNotification(
          //   'success',
          //   `Товар ${status === 'DECLINED' ? 'не ' : ''}прошел модерацию`,
          // )
          history.push('/moderation')
        },
      },
    )
  }

  if (!currentData) return null

  return (
    <Page
      title={`Модерация заявки #${moderationId}`}
      backUrl={PATHS.MODERATION}
    >
      <div className={styles.wrapper}>
        <div>
          <ProductPreview
            values={currentData}
            meta={{ currentId: currentTask?.productId }}
          />

          <Textarea
            className={styles.comment}
            label="Комментарий"
            value={comment || ''}
            onChange={(e) => setComment(e.target.value)}
          />
          {!acceptProductModeration.isLoading ? (
            <div className={styles.bottomButtons}>
              <Button
                size="large"
                template="secondary"
                icon={<Icons.CircleCloseLight />}
                onClick={() => handleAcceptTask('DECLINED')}
              >
                Отклонить
              </Button>
              <Button
                size="large"
                icon={<Icons.Check />}
                onClick={() => handleAcceptTask('ACCEPTED')}
              >
                Подтвердить
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    </Page>
  )
}

export default SingleModeration
