import React, { useState } from 'react'

import styles from './order-comments-block.module.scss'

import OrderCommentsModal from './OrderCommentsModal'

import Block from '../Block'

import { Order } from 'src/interfaces'

interface OrderCommentsBlockProps {
  currentOrder: Order
}

const OrderCommentsBlock = (props: OrderCommentsBlockProps) => {
  const { currentOrder } = props

  const [showModal, setShowModal] = useState<boolean>(false)

  return (
    <>
      <Block title="Комментарии" onOpenEdit={() => setShowModal(true)}>
        <div className={styles.wrapper}>
          {currentOrder.comment ? (
            <div className={styles.block}>
              <div className={styles.blockTitle}>Комментарий</div>
              <div>{currentOrder.comment}</div>
            </div>
          ) : null}

          {currentOrder.delivery.deliveryComment ? (
            <div className={styles.block}>
              <div className={styles.blockTitle}>Комментарий по доставке</div>
              <div>{currentOrder.delivery.deliveryComment}</div>
            </div>
          ) : null}
        </div>
      </Block>

      {showModal ? (
        <OrderCommentsModal onClose={() => setShowModal(false)} />
      ) : null}
    </>
  )
}

export default OrderCommentsBlock
