import React from 'react'
import { Link, generatePath } from 'react-router-dom'
import {
  useQueryParams,
  withDefault,
  NumberParam,
  StringParam,
} from 'use-query-params'

import styles from './products.module.scss'

import Filters from './Filters'
import SelectPanel from './SelectPanel'
import ProductStatus from './ProductStatus'
import ProductsTabs, { PRODUCTS_PAGE_TABS } from './ProductsTabs'

import { Page, Table, SelectOnPage, Pagination, Price } from 'src/components'

import { useProducts } from 'src/hooks'

import {
  updateQuery,
  setQueryFieldValue,
  handleChangePagination,
  getWordEnding,
  getProductCommonBarcode,
} from 'src/helpers'

import { PATHS } from 'src/config'

import { Product } from 'src/interfaces'

const ACTIONS = [] as any[] //TODO type

const ProductsParams = {
  page: withDefault(NumberParam, 0),
  size: withDefault(NumberParam, 10),
  tab: StringParam,
  query: StringParam,
}

const ProductsPage = () => {
  const [query, setQuery] = useQueryParams(ProductsParams)

  const {
    data: { products, recordsTotal },
  } = useProducts(query)

  const handleTabChange = (tab: string) => {
    updateQuery(query, setQuery, { tab, page: 0 })
  }

  const onPaginationChange = (page: number, size: number) =>
    handleChangePagination(query, setQuery, page, size)

  const setFilterFieldValue = (key: string, value?: string | null) => {
    updateQuery(query, setQuery, {
      page: 0,
      [key]: value,
    })
  }

  return (
    <Page title="Продукты">
      <Filters
        values={{ query: query.query }}
        setFilterFieldValue={setFilterFieldValue}
      />

      <ProductsTabs
        currentTab={query.tab || 'all'}
        onTabChange={handleTabChange}
      />

      <Table<Product>
        FilterPanel={() => (
          <Pagination.Panel
            page={query.page}
            total={recordsTotal}
            size={query.size || 0}
            onChange={(size) =>
              handleChangePagination(query, setQuery, query.page + 1, size)
            }
          />
        )}
        actions={ACTIONS}
        SelectPanel={SelectPanel}
      >
        {products.map((row) => (
          <Table.Row<Product> key={row.id} id={row.id} data={row}>
            <Table.Column title="Артикул">
              {row.variants.length === 1 && row.variants[0]?.article}
            </Table.Column>

            <Table.Column title="Фото" className={styles.imageWrapper}>
              <img alt="" className={styles.image} src={row.images?.[0]?.url} />
            </Table.Column>

            <Table.Column
              title="Наименование товара"
              className={styles.productName}
            >
              {PATHS.SINGLE_PRODUCT ? (
                //TODO remove admin
                <Link
                  to={generatePath(PATHS.SINGLE_PRODUCT, {
                    productId: row.id,
                  })}
                >
                  {row.shortName}
                </Link>
              ) : null}
            </Table.Column>

            <Table.Column title="Статус">
              {row.variants.length === 1 ? (
                <ProductStatus value={row.saleStatus} />
              ) : (
                `${row.variants.length} ${getWordEnding(row.variants.length, [
                  `вариант`,
                  `варианта`,
                  `вариантов`,
                ])}`
              )}
            </Table.Column>

            <Table.Column title="Штрихкод">
              {row.variants.length === 1 &&
                getProductCommonBarcode(row.variants[0])?.value}
            </Table.Column>

            <Table.Column title="Цена">
              <div className={styles.productPrice}>
                {row.variants.length > 1 && `от `}
                <Price value={getMinimalPrice(row)} />
              </div>
            </Table.Column>

            <Table.Column title="Остаток">
              {row.variants.length === 1 && row.variants[0].stock ? (
                <div className={styles.productStock}>
                  <div>{row.variants[0].stock?.[0]?.stockAvailable}</div>
                  <div
                    className={styles.reserved}
                  >{`Резерв: ${row.variants[0].stock?.[0]?.stockReserved}`}</div>
                </div>
              ) : null}
            </Table.Column>
          </Table.Row>
        ))}
      </Table>

      <Pagination
        className={styles.pagination}
        current={query.page + 1 || 0}
        pageSize={query.size || 0}
        total={recordsTotal || 0}
        onChange={onPaginationChange}
      />
    </Page>
  )
}

const getMinimalPrice = (product: Product) =>
  Math.min(...(product.variants || []).map((variant) => variant.price))

export default ProductsPage
