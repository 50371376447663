import React from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'

import styles from './mobile-menu.module.scss'

import { MenuItemType } from 'src/interfaces'

interface MobileMenuItemType {
  className?: string
  Icon?: React.FunctionComponent<React.SVGAttributes<SVGElement>>
  path?: string
  title?: string
  onClick: () => void
}

const MenuItem: React.FC<MobileMenuItemType> = (props) => {
  const { className, Icon, path, title, onClick } = props

  const component = (
    <div className={cx(styles.menuItem, className || null)} onClick={onClick}>
      {Icon ? <Icon /> : null}
      <div className={styles.menuItemTitle}>{title}</div>
    </div>
  )

  return path ? <Link to={path}>{component}</Link> : component
}

const MenuBlock = (props: { children: React.ReactNode }) => {
  const { children } = props

  return <div className={styles.menuBlock}>{children}</div>
}

interface MobileMenuType {
  menuItems: MenuItemType[][]
  show: boolean
  close: () => void
}

const MobileMenu: React.FC<MobileMenuType> = (props) => {
  const { menuItems, show, close } = props

  return (
    <div className={cx(styles.wrapper, show ? styles.show : null)}>
      {menuItems.map((block, i) => (
        <MenuBlock key={i}>
          {block.map((item, j) => (
            <MenuItem key={`${i}-${j}`} {...item} onClick={close} />
          ))}
        </MenuBlock>
      ))}
    </div>
  )
}

export default MobileMenu
