import React, { useState } from 'react'
import { FormikValues } from 'formik'
import cx from 'classnames'

import styles from './filters.module.scss'

import { Input, Select, DatePicker, Button, Icons } from 'src/components'

import { ORDER_STATUSES } from 'src/config'

import { OrderFilterValues } from '../interfaces'
import { IOption } from 'src/interfaces'

const STATUS_OPTIONS = Object.entries(ORDER_STATUSES).map((entry) => ({
  label: entry[1],
  value: entry[0],
}))

interface FilterType {
  key:
    | 'filter_states'
    | 'filter_phone'
    | 'filter_tracking_number'
    | 'city'
    | 'filter_promocode'
    | 'product_id'
    | 'article'
    | 'barcode'
  label: string
  placeholder?: string
  type?: 'multiselect' | 'datepicker-range'
  options?: IOption[]
}

const FILTERS: FilterType[] = [
  {
    key: 'filter_states',
    label: 'Статус заказа',
    type: 'multiselect',
    options: STATUS_OPTIONS,
  },
  {
    key: 'filter_phone',
    label: 'Телефон',
    placeholder: 'Введите телефон',
  },
  {
    key: 'filter_tracking_number',
    label: 'Трек-номер',
    placeholder: 'Введите трек-номер',
  },
  {
    key: 'city',
    label: 'Населенный пункт',
    placeholder: 'Введите город, регион',
  },
  {
    key: 'filter_promocode',
    label: 'Промокод',
    placeholder: 'Введите промокод',
  },
  {
    key: 'product_id',
    label: 'ID товара',
    placeholder: 'Введите ID',
  },
  {
    key: 'article',
    label: 'Артикул товара',
    placeholder: 'Введите артикул',
  },
  {
    key: 'barcode',
    label: 'Штрихкод товара',
    placeholder: 'Введите штрихкод',
  },
]

type FilterInputProps = FilterType & {
  id: string
  value: string
  setFilterFieldValue: (field: string, value: any) => void
}

const FilterInput = (props: React.PropsWithChildren<FilterInputProps>) => {
  const { id, value, label, placeholder, type, options, setFilterFieldValue } =
    props

  switch (type) {
    case 'multiselect':
      return (
        <Select
          className={styles.filterInputWrapper}
          inputClassName={styles.filterInput}
          isMultiple
          size="small"
          label={label}
          placeholder={placeholder}
          options={options || []}
          value={value?.split(',')}
          onSelect={(value) =>
            setFilterFieldValue(String(id), Array(value).join(',') || undefined)
          }
        />
      )
    default:
      return (
        <Input
          className={styles.filterInputWrapper}
          inputClassName={styles.filterInput}
          template="small"
          value={value}
          label={label}
          placeholder={placeholder}
          onChange={(e) =>
            setFilterFieldValue(String(id), e.currentTarget.value)
          }
        />
      )
  }
}

interface FiltersProps {
  values: OrderFilterValues
  setFieldValue: (key: string, value?: number | string | null) => void
  setFilterFieldValue: (key: string, value?: string | null) => void
  resetForm: (initial?: any) => void
}

const Filters = (props: FiltersProps) => {
  const { values, setFieldValue, setFilterFieldValue, resetForm } = props

  const [showFilters, setShowFilters] = useState(false)

  const handleClickFilterButton = () => {
    const currentValues = { ...values }
    resetForm()
    setFilterFieldValue('filter_multi', currentValues.filter_multi)
    setFieldValue('size', currentValues.size)
    setShowFilters(!showFilters)
  }

  return (
    <>
      <div className={styles.topPanel}>
        <Input
          value={values.filter_multi || ''}
          inputClassName={styles.searchInput}
          placeholder="Поиск по названию или артикулу"
          template="small"
          withoutError
          IconBefore={Icons.Search}
          onChange={(e) =>
            setFilterFieldValue('filter_multi', e.currentTarget.value)
          }
        />
        <Button
          className={cx(styles.filterButton, showFilters && styles.rotate)}
          icon={<Icons.Arrow />}
          onClick={handleClickFilterButton}
        >
          {`${showFilters ? 'Скрыть' : 'Показать'} фильтр`}
        </Button>
        <Button className={styles.reserveRefresh} icon={<Icons.Refresh />}>
          Обновить резервы
        </Button>
      </div>

      {showFilters ? (
        <div className={styles.filterPanel}>
          <div
            className={cx(styles.dateRangeWrapper, styles.filterInputWrapper)}
          >
            <label>Дата</label>
            <div className={styles.dateRange}>
              <DatePicker
                value={values.date_from || ''}
                max={values.date_to || ''}
                onSelect={(value) => setFilterFieldValue('date_from', value)}
              />
              <span className={styles.separator}>–</span>
              <DatePicker
                value={values.date_to || ''}
                min={values.date_from || ''}
                onSelect={(value) => setFilterFieldValue('date_to', value)}
              />
            </div>
          </div>
          {FILTERS.map((filter: FilterType) => {
            const { key, label, placeholder, type, options } = filter
            return (
              <FilterInput
                key={key}
                id={key}
                value={values[key] || ''}
                label={label}
                placeholder={placeholder}
                type={type}
                options={options}
                setFilterFieldValue={setFilterFieldValue}
              />
            )
          })}
        </div>
      ) : null}
    </>
  )
}

export default Filters
