import React from 'react'
import { useParams } from 'react-router-dom'

import styles from './single-order.module.scss'

import PageWrapper from './PageWrapper'
import OrderProducts from './OrderProducts'
import OrderReceiverBlock from './OrderReceiverBlock'
import OrderDeliveryBlock from './OrderDeliveryBlock'
import OrderPaymentBlock from './OrderPaymentBlock'
import OrderCommentsBlock from './OrderCommentsBlock'
import OrderPackingBlock from './OrderPackingBlock'
import OrderHistory from './OrderHistory'

import { useOrders } from 'src/hooks'

import { OrderPacking } from 'src/interfaces'

const PACKINGS_MOCK: OrderPacking[] = [
  {
    barcode: '1111111',
    measurements: {
      weight: 200,
      dimensions: {
        height: 100,
        width: 100,
        depth: 100,
      },
    },
    items: [
      {
        productId: '123',
        variantId: '123-1',
        num: -1,
        name: 'Маска морда',
        article: '234-morda',
        quantity: 10,
        quantityAvailable: 200,
        vatRate: 'VAT_20',
        vatSkuProduct: 'VAT_20',
        price: 1000,
        skuType: 'SKU',
        itemDimensions: {
          width: 50,
          height: 50,
          depth: 50,
        },
        itemWeight: 100,
        img: 'https://storage.yandexcloud.net/images-k2c/e1a2246d-5438-44a1-99e1-87ca2c51bef2.jpg',
        itemAmountState: {
          amountORDERED: 10,
          amountRESERVED: 10,
          amountPACKED: 10,
          amountSHIPPED: 10,
          amountDELIVERED: 5,
          amountLOST: 2,
          amountRETURNED: 3,
          amountRETURNED_DEFECTIVE: 0,
        },
      },
      {
        productId: '321',
        variantId: '321-1',
        num: 0,
        name: 'Греция костюм',
        article: 'greece-222',
        quantity: 10,
        quantityAvailable: 100,
        vatRate: 'VAT_20',
        vatSkuProduct: 'VAT_20',
        price: 2000,
        skuType: 'SKU',
        itemDimensions: {
          width: 100,
          height: 150,
          depth: 150,
        },
        itemWeight: 200,
        img: 'https://storage.yandexcloud.net/images-k2c/40f6de79-160f-4dad-ab82-331c3bcb7202.jpg',
        itemAmountState: {
          amountORDERED: 10,
          amountRESERVED: 10,
          amountPACKED: 10,
          amountSHIPPED: 10,
          amountDELIVERED: 5,
          amountLOST: 2,
          amountRETURNED: 3,
          amountRETURNED_DEFECTIVE: 0,
        },
      },
    ],
  },
  {
    barcode: '1111222',
    measurements: {
      weight: 220,
      dimensions: {
        height: 120,
        width: 120,
        depth: 120,
      },
    },
    items: [
      {
        productId: '123',
        variantId: '123-1',
        num: -1,
        name: 'Маска морда',
        article: '234-morda',
        quantity: 10,
        quantityAvailable: 200,
        vatRate: 'VAT_20',
        vatSkuProduct: 'VAT_20',
        price: 1000,
        skuType: 'SKU',
        itemDimensions: {
          width: 50,
          height: 50,
          depth: 50,
        },
        itemWeight: 100,
        img: 'https://storage.yandexcloud.net/images-k2c/e1a2246d-5438-44a1-99e1-87ca2c51bef2.jpg',
        itemAmountState: {
          amountORDERED: 10,
          amountRESERVED: 10,
          amountPACKED: 10,
          amountSHIPPED: 10,
          amountDELIVERED: 5,
          amountLOST: 2,
          amountRETURNED: 3,
          amountRETURNED_DEFECTIVE: 0,
        },
      },
      {
        productId: '321',
        variantId: '321-1',
        num: 0,
        name: 'Греция костюм',
        article: 'greece-222',
        quantity: 10,
        quantityAvailable: 100,
        vatRate: 'VAT_20',
        vatSkuProduct: 'VAT_20',
        price: 2000,
        skuType: 'SKU',
        itemDimensions: {
          width: 100,
          height: 150,
          depth: 150,
        },
        itemWeight: 200,
        img: 'https://storage.yandexcloud.net/images-k2c/40f6de79-160f-4dad-ab82-331c3bcb7202.jpg',
        itemAmountState: {
          amountORDERED: 10,
          amountRESERVED: 10,
          amountPACKED: 10,
          amountSHIPPED: 10,
          amountDELIVERED: 5,
          amountLOST: 2,
          amountRETURNED: 3,
          amountRETURNED_DEFECTIVE: 0,
        },
      },
    ],
  },
]

const SingleOrder = () => {
  const { orderId } = useParams<{ orderId: string }>()

  const {
    data: {
      orders: { 0: currentOrder },
    },
  } = useOrders({ id: orderId, full_info: true })

  if (!currentOrder) return null

  const sum =
    currentOrder.items?.reduce((a, c) => {
      a += c.price * c.quantity
      return a
    }, 0) ?? 0

  const deliveryProduct = currentOrder.items.find(
    (p) => p.variantId === 'delivery',
  )

  return (
    <PageWrapper currentOrder={currentOrder}>
      <div className={styles.wrapper}>
        <div className={styles.leftColumn}>
          <OrderProducts currentOrder={currentOrder} sum={sum} />

          {/* {currentOrder.packings?.length ?
            <OrderPackingBlock packings={currentOrder.packings} />
          : null} */}

          <OrderPackingBlock packings={PACKINGS_MOCK} />

          <OrderHistory orderId={orderId} />
        </div>

        <div className={styles.rightColumn}>
          <OrderReceiverBlock receiver={currentOrder.delivery.receiver} />

          <OrderDeliveryBlock
            delivery={currentOrder.delivery}
            price={deliveryProduct?.price}
          />

          <OrderPaymentBlock currentOrder={currentOrder} sum={sum} />

          <OrderCommentsBlock currentOrder={currentOrder} />
        </div>
      </div>
    </PageWrapper>
  )
}

export default SingleOrder
