import React, { useState } from 'react'
import cx from 'classnames'

import styles from './variants-block.module.scss'

import { Input } from 'src/components'

interface EditableTdType {
  disabled?: boolean
  type?: string
  className?: string
  defaultValue?: string | number
  postfix?: string
  onChange: (value: string | number) => void
}

const EditableTd: React.FC<EditableTdType> = (props) => {
  const {
    disabled = false,
    type = 'text',
    className = '',
    postfix = '',
    defaultValue = '',
    onChange,
  } = props

  const [isEdit, setIsEdit] = useState(false)
  const [value, setValue] = useState(defaultValue)

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      setIsEdit(false)
    }
  }

  const handleShowInput = () => {
    setIsEdit(true)
  }

  const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.currentTarget.value)
    onChange(event.currentTarget.value)
  }

  if (isEdit) {
    return (
      <td className={styles.addCell}>
        <Input
          template="small"
          type={type}
          value={value}
          focusOnMount
          withoutError
          onChange={handleChangeValue}
          onKeyPress={handleKeyPress}
        />
      </td>
    )
  }

  if (!disabled && !value) {
    return (
      <td
        className={cx(styles.addCell, styles.pointer)}
        onClick={handleShowInput}
      >
        Добавить
      </td>
    )
  }

  return (
    <td className={cx(className, styles.pointer)} onClick={handleShowInput}>
      {value ? `${value}${postfix ? ' ' + postfix : ''}` : ''}
    </td>
  )
}

export default EditableTd
