import React from 'react'

import { TableTabs, Icons } from 'src/components'

import { useModerationStatuses } from 'src/ADMIN/hooks'

import { TableTab } from 'src/interfaces'

interface ModerationTab {
  id: string
  title: string
  icon: React.SVGProps<SVGElement>
  status?: string
}

export const MODERATION_PAGE_TABS: ModerationTab[] = [
  {
    id: 'all',
    title: 'Все заявки',
    icon: <Icons.Box2d />,
  },
  {
    id: 'creating',
    title: 'Создание товара',
    icon: <Icons.DocumentBlank />,
    status: 'NEW',
  },
  {
    id: 'editing',
    title: 'Редактирование товара',
    icon: <Icons.Document />,
    status: 'UPDATE',
  },
  {
    id: 'archive',
    title: 'Архив',
    icon: <Icons.Archive />,
    status: 'ARCHIVE',
  },
]

interface ModerationTabsProps {
  currentTab?: string | null
  onTabChange: (id: string) => void
}

const ModerationTabs = (props: ModerationTabsProps) => {
  const { currentTab, onTabChange } = props

  const { data: statusesLength } = useModerationStatuses()

  const tabsData = MODERATION_PAGE_TABS.map((t: ModerationTab) => {
    const current: TableTab = {
      key: t.id,
      title: t.title,
      icon: t.icon,
    }

    if (t.status) {
      current.count = statusesLength?.[t.status] || undefined
    }

    return current
  })

  return (
    <TableTabs
      currentTab={currentTab}
      tabsData={tabsData}
      onTabChange={onTabChange}
    />
  )
}

export default ModerationTabs
