import React from 'react'
import { useHistory } from 'react-router-dom'

import styles from './select-panel.module.scss'

import { Button, Icons } from 'src/components'

import { useCopyProduct, useArchiveProduct } from 'src/hooks/products'
import { useNotifications } from 'src/notification'

interface SelectPanelProps<RowData> {
  selectedItems: string[]
  itemsData: { [key: string]: RowData | null }
  onUnselect: () => void
}

const SelectPanel = <RowData,>(props: SelectPanelProps<RowData>) => {
  const { selectedItems, itemsData, onUnselect } = props

  const copyMutation = useCopyProduct()
  const archiveMutation = useArchiveProduct()

  // const { showNotification } = useNotifications()
  const context = useNotifications()
  const history = useHistory()

  const handleCopyProducts = async () => {
    let anyError = false
    let anySuccess = false
    for (let i = 0; i < selectedItems.length; i++) {
      try {
        const result = await copyMutation.mutateAsync(selectedItems[i])
        anySuccess = true
      } catch (err) {
        anyError = true
      }
    }

    if (anySuccess) {
      // showNotification(
      //   'success',
      //   !anyError
      //     ? 'Продукты успешно скопированы'
      //     : 'Некоторые продукты успешно скопированы',
      // )
    }

    history.go(0)
  }

  const handleArchiveProducts = async () => {
    let anyError = false
    let anySuccess = false
    for (let i = 0; i < selectedItems.length; i++) {
      if (itemsData[selectedItems[i]]) {
        try {
          const result = await archiveMutation.mutateAsync({
            data: itemsData[selectedItems[i]],
          })
          anySuccess = true
        } catch (err) {
          anyError = true
        }
      }
    }

    if (anySuccess) {
      // showNotification(
      //   'success',
      //   !anyError
      //     ? 'Продукты успешно перенесы в архив'
      //     : 'Некоторые продукты успешно перенесы в архив',
      // )
    }

    history.go(0)
  }

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.count}
      >{`Выбрано товаров: ${selectedItems.length}`}</div>
      <div className={styles.divider} />
      <Button template="simple" onClick={onUnselect}>
        Отменить
      </Button>

      <Button
        template="simple"
        loading={copyMutation.isLoading}
        className={styles.goRight}
        onClick={handleCopyProducts}
      >
        Создать копию
      </Button>
      <div className={styles.divider} />
      <Button
        template="simple"
        loading={archiveMutation.isLoading}
        onClick={handleArchiveProducts}
      >
        В архив
      </Button>
    </div>
  )
}

export default SelectPanel
