export const getPhoneWithMask = (str: string) => {
  const value = `+7 ${str.substr(1, str.length)}`.replace(/[a-zа-я]/gi, '')
  return value
    .split('')
    .reduce((a: string[], c, i) => {
      if (i == 9 || i == 11) a.push('-')
      if (i == 6) a.push(') ')
      a.push(c)
      if (i == 2) a.push('(')
      return a
    }, [])
    .join('')
}
