import React, { useState } from 'react'

import styles from './order-packing-block.module.scss'

import Block from '../Block'

import { Icons } from 'src/components'

import { OrderPacking } from 'src/interfaces'

const Packing = (props: OrderPacking) => {
  const {
    barcode,
    items,
    measurements: {
      dimensions: { width, height, depth },
      weight,
    },
  } = props

  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className={styles.block}>
      <div className={styles.blockTitle}>{`# ${barcode}`}</div>

      <div className={styles.flex}>
        <div>
          <span>{`ШхВхГ: ${width} x ${height} x ${depth} cм`}</span>
          <span className={styles.weight}>{`${weight} г`}</span>
        </div>
        <div className={styles.button} onClick={() => setIsOpen(!isOpen)}>
          {!isOpen ? <Icons.Eye /> : <Icons.EyeClosed />} Позиции
        </div>
      </div>

      {isOpen ? (
        <div className={styles.items}>
          {items.map((item) => (
            <div key={`${item.productId}-${item.variantId}`}>
              {item.name}
              <div className={styles.itemQuantity}>{`${item.quantity} шт`}</div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  )
}

interface OrderCommentsBlockProps {
  packings: OrderPacking[]
}

const OrderPackingBlock = (props: OrderCommentsBlockProps) => {
  const { packings } = props

  return (
    <Block title="Комплектация" subTitle={`${packings.length} места`}>
      <div className={styles.wrapper}>
        {packings.map((packing) => (
          <Packing key={packing.barcode} {...packing} />
        ))}
      </div>
    </Block>
  )
}

export default OrderPackingBlock
