import React, { useEffect } from 'react'

import styles from './delivery-variants-block.module.scss'

import { Table } from 'src/components'

import { useDeliveryVariantsCalculation } from 'src/ADMIN/hooks'

import { DELIVERY_TYPES } from 'src/config'

import { AddressFiases, Tariff } from 'src/ADMIN/interfaces'

interface DeliveryVariantsBlockProps {
  fiases?: AddressFiases
  onTariffChange: (tariff: Tariff) => void
}

const DeliveryVariantsBlock = (props: DeliveryVariantsBlockProps) => {
  const { fiases, onTariffChange } = props

  const { data: tariffs, refetch: queryDeliveryVariants } =
    useDeliveryVariantsCalculation({
      ...fiases,
      weight: 1000, //TODO real data
      insuranceSum: 1000,
      codSum: 1000,
      postalCode: null,
      dimensions: {
        height: 1000,
        width: 1000,
        depth: 1000,
      },
      fromTempoline: false,
      sourceRegionFias: null,
      sourceAreaFias: null,
      sourceCityFias: null,
      sourceSettlementFias: null,
      sourcePostalCode: null,
    })

  useEffect(() => {
    queryDeliveryVariants()
  }, [fiases])

  const handleSelectTariff = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentTariff = tariffs?.find(
      (t: Tariff, i: number) => String(i) === event.currentTarget.value,
    )

    if (currentTariff) {
      onTariffChange(currentTariff)
    }
  }

  return (
    <div className={styles.wrapper}>
      {/* TODO rendering type name */}
      <Table>
        {(tariffs || []).map((t: Tariff, i: number) => (
          <Table.Row key={i}>
            <Table.Column>
              <input
                type="radio"
                name="tariff-checkbox"
                onChange={handleSelectTariff}
                value={i}
              />
            </Table.Column>

            <Table.Column title="Название">{t.courier}</Table.Column>

            <Table.Column title="Тип">{t.type}</Table.Column>

            <Table.Column title="Тариф">{t.deliveryPrice}</Table.Column>

            <Table.Column title="Цена">{t.price}</Table.Column>
          </Table.Row>
        ))}
      </Table>
    </div>
  )
}

export default DeliveryVariantsBlock
