import { ComponentClass, FunctionComponent } from 'react'

import {
  LoginPage,
  RecoveryPage,
  ProductsPage,
  SingleProductPage,
  NewProductPage,
} from 'src/pages'

//import OldProductsPage from 'src/pages/OldProducts'

import {
  ModerationPage,
  SingleModerationPage,
  OrdersPage,
  SingleOrderPage,
} from 'src/ADMIN/pages'

import { PRIVATE_PATHS, PUBLIC_PATHS } from 'src/config'

export type Routes = {
  path?: string
  component: FunctionComponent | ComponentClass
  default?: boolean
}[]

export const privateRoutes: Routes =
  process.env.REACT_APP_ADMIN_PANEL !== 'true'
    ? [
        //TODO remove admin
        {
          path: PRIVATE_PATHS.PRODUCTS,
          component: ProductsPage,
          default: true,
        },
        // {
        //   path: PRIVATE_PATHS.OLD_PRODUCTS,
        //   component: OldProductsPage,
        //   default: true,
        // },
        { path: PRIVATE_PATHS.NEW_PRODUCT, component: NewProductPage },
        { path: PRIVATE_PATHS.SINGLE_PRODUCT, component: SingleProductPage },
      ]
    : [
        {
          path: PRIVATE_PATHS.MODERATION,
          component: ModerationPage,
        },
        {
          path: PRIVATE_PATHS.SINGLE_MODERATION,
          component: SingleModerationPage,
        },
        {
          path: PRIVATE_PATHS.ORDERS,
          component: OrdersPage,
          default: true,
        },
        { path: PRIVATE_PATHS.SINGLE_ORDER, component: SingleOrderPage },
      ]

export const publicRoutes: Routes = [
  { path: PUBLIC_PATHS.LOGIN, component: LoginPage, default: true },
  { path: PUBLIC_PATHS.RECOVERY, component: RecoveryPage },
]
