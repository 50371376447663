import React from 'react'
import cx from 'classnames'

import { Tooltip } from 'src/components'

import styles from './textarea.module.scss'

type TextareaType = React.InputHTMLAttributes<HTMLTextAreaElement> & {
  label?: string
  error?: string
  tooltip?: string
  tooltipSide?: 'top' | 'right'
}

const Textarea: React.FC<TextareaType> = (props) => {
  const {
    required,
    id,
    label,
    className,
    error,
    tooltip,
    tooltipSide,
    children,
    ...otherProps
  } = props

  return (
    <div className={cx(className, styles.wrapper)}>
      {label ? (
        <label htmlFor={id}>
          {label}
          {required ? <span className="required">*</span> : null}
        </label>
      ) : null}
      <div className={styles.textareaWrapper}>
        <textarea
          id={id}
          className={cx(
            styles.textarea,
            error ? styles.textareaError : null,
          )}
          {...otherProps}
        />
        {tooltip ? (
          <Tooltip
            className={styles.tooltip}
            content={tooltip}
            side={tooltipSide}
          />
        ) : null}
        {children ? children : null}
      </div>
      <div className={styles.errorText}>{error}</div>
    </div>
  )
}

export default Textarea
