import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'

import appLocalStorage from 'src/libs/localStorage'

import MobileMenu from './MobileMenu'

import { Icons } from 'src/components'

import styles from './header.module.scss'

import userIcon from './assets/user.svg'

import { MenuItemType } from 'src/interfaces'
import { AppContext } from 'src/App'

interface HeaderType {
  menuItems: MenuItemType[][]
}

const Header: React.FC<HeaderType> = (props) => {
  const { menuItems } = props

  const { setAuthorized } = useContext(AppContext)

  const [mobileMenuOpened, setMobileMenuOpened] = useState(false)

  const toggleMobileMenu = () => {
    setMobileMenuOpened(!mobileMenuOpened)
  }

  const handleLogout = () => {
    appLocalStorage.clear()
    setAuthorized(false)
  }

  return (
    <div className={styles.wrapper}>
      <div onClick={toggleMobileMenu}>
        <div className={styles.mobileMenuButton}>
          {!mobileMenuOpened ? <Icons.Burger /> : <Icons.Close />}
        </div>
      </div>

      <Link className={styles.logo} to="/">
        <img alt="" src="/logo.svg" />
      </Link>

      <div className={styles.notifications}>
        <Icons.Bell />
      </div>

      <div className={styles.user}>
        <span className={styles.username}>Username</span>
        <img alt="" src={userIcon} />
        <ul className={styles.menu}>
          <li>Настройки</li>
          <li onClick={handleLogout}>Выйти</li>
        </ul>
      </div>

      <MobileMenu
        menuItems={menuItems}
        show={mobileMenuOpened}
        close={() => setMobileMenuOpened(false)}
      />
    </div>
  )
}

export default Header
