import React from 'react'

import Ordered from './Ordered'
import ProductStatus from './ProductStatus'

import { Table, Price, Nomenclature } from 'src/components'

import { Order, OrderProduct } from 'src/interfaces'

import { getItemsStatuses } from './helpers'

export const PRODUCTS_MOCK: OrderProduct[] = [
  {
    productId: '123',
    variantId: '123-1',
    num: -1,
    name: 'Маска морда',
    article: '234-morda',
    quantity: 10,
    quantityAvailable: 200,
    vatRate: 'VAT_20',
    vatSkuProduct: 'VAT_20',
    price: 1000,
    skuType: 'SKU',
    itemDimensions: {
      width: 50,
      height: 50,
      depth: 50,
    },
    itemWeight: 100,
    img: 'https://storage.yandexcloud.net/images-k2c/e1a2246d-5438-44a1-99e1-87ca2c51bef2.jpg',
    itemAmountState: {
      amountORDERED: 10,
      amountRESERVED: 10,
      amountPACKED: 10,
      amountSHIPPED: 10,
      amountDELIVERED: 5,
      amountLOST: 2,
      amountRETURNED: 3,
      amountRETURNED_DEFECTIVE: 0,
    },
  },
  {
    productId: '321',
    variantId: '321-1',
    num: 0,
    name: 'Греция костюм',
    article: 'greece-222',
    quantity: 10,
    quantityAvailable: 100,
    vatRate: 'VAT_20',
    vatSkuProduct: 'VAT_20',
    price: 2000,
    skuType: 'SKU',
    itemDimensions: {
      width: 100,
      height: 150,
      depth: 150,
    },
    itemWeight: 200,
    img: 'https://storage.yandexcloud.net/images-k2c/40f6de79-160f-4dad-ab82-331c3bcb7202.jpg',
    itemAmountState: {
      amountORDERED: 10,
      amountRESERVED: 10,
      amountPACKED: 10,
      amountSHIPPED: 10,
      amountDELIVERED: 5,
      amountLOST: 2,
      amountRETURNED: 3,
      amountRETURNED_DEFECTIVE: 0,
    },
  },
]

interface OrderProductsTableProps {
  currentOrder: Order
}

const OrderProductsTable = (props: OrderProductsTableProps) => {
  const {
    currentOrder: { items, status },
  } = props

  const itemsStatuses = getItemsStatuses([...PRODUCTS_MOCK, ...items], status) //TODO remove mocked products

  return (
    <Table>
      {[...PRODUCTS_MOCK, ...items].map(
        (
          product, //TODO remove mocked products
        ) => (
          <Table.Row key={`${product.productId}-${product.variantId}`}>
            <Table.Column title="Номенклатура">
              <Nomenclature product={product} />
            </Table.Column>

            <Table.Column title="Заказано">
              <Ordered product={product} />
            </Table.Column>

            <Table.Column title="Статус">
              <ProductStatus
                variantId={product.variantId}
                statuses={itemsStatuses}
              />
            </Table.Column>

            <Table.Column title="Цена">
              <Price value={product.price} />
            </Table.Column>

            <Table.Column title="Сумма">
              <Price value={product.price * product.quantity} />
            </Table.Column>
          </Table.Row>
        ),
      )}
    </Table>
  )
}

export default OrderProductsTable
