import { ProductSaleStatus } from 'src/interfaces'

export const PUBLIC_PATHS = {
  LOGIN: '/login',
  RECOVERY: '/recovery',
}

export const PRIVATE_PATHS =
  process.env.REACT_APP_ADMIN_PANEL !== 'true' //TODO remove admin
    ? {
        //OLD_PRODUCTS: '/old-products',
        PRODUCTS: '/products',
        NEW_PRODUCT: '/products/new',
        SINGLE_PRODUCT: '/products/:productId',
      }
    : {
        MODERATION: '/moderation',
        SINGLE_MODERATION: '/moderation/:moderationId',
        ORDERS: '/orders',
        SINGLE_ORDER: '/orders/:orderId',
      }

export const PATHS = {
  ...PUBLIC_PATHS,
  ...PRIVATE_PATHS,
}

export const LOCAL_STORAGE_NAME =
  process.env.REACT_APP_ADMIN_PANEL !== 'true'
    ? '____GREENLEAF_SELLER____'
    : '____GREENLEAF_ADMIN____'

export const API_BASE_URL = `http://core.demo.greenleaf.team/${
  process.env.REACT_APP_ADMIN_PANEL !== 'true' ? 'seller' : 'admin'
}/`
export const API_DLV_URL = 'http://core.demo.greenleaf.team/api/dlv/'
export const API_PUBLIC_KAK2C_URL = 'https://app.kak2c.ru/api/'

export const NOTIFICATIONS_TIMEOUT = 3000

export interface OrderStatusKeys {
  [key: string]: string
}

export const ORDER_STATUSES: OrderStatusKeys = {
  ORDER_INFO_DRAFT: 'Черновик',
  ORDER_INFO_NEW: 'Новый',
  ORDER_INFO_NEED_CONFIRM: 'Не подтвержден',
  ORDER_INFO_INSUFFICIENT_RESERVE: 'Не хватает товара',
  ORDER_INFO_REQUIRE_PAYMENT: 'Ожидает оплаты',
  ORDER_INFO_READY_TO_PACK: 'Готов к комплектации',
  ORDER_INFO_CONFIRM_HOLD: 'Приостановлен',
  ORDER_INFO_PACKED: 'Упакован',
  ORDER_INFO_IN_PACKING: 'На комплектации',
  ORDER_INFO_SHIPPED: 'Отгружен',
  ORDER_INFO_COURIER: 'На доставке',
  ORDER_INFO_DELIVERED: 'Доставлен',
  ORDER_INFO_PARTIALLY_DELIVERED_NOT_RETURNED: 'Доставлен частично *',
  ORDER_INFO_PARTIALLY_DELIVERED_RETURNED: 'Доставлен частично',
  ORDER_INFO_RETURNED: 'Возвращён',
  ORDER_INFO_LOST: 'Утерян',
  ORDER_INFO_RETURNING: 'Возвращается',
  ORDER_INFO_AT_TAKEOUT_POINT: 'В пункте выдачи',
  ORDER_INFO_DONE: 'Доставлен',
  ORDER_INFO_CANCELED: 'Отменен',
  ORDER_INFO_WAIT_UNPACK: 'Ожидает распаковки',
  ORDER_INFO_SORT_CENTER_ARRIVED: 'Принят на СЦ',
  ORDER_INFO_SORT_CENTER_SHIPPED: 'Отгружен с СЦ',
  ORDER_INFO_SORT_CENTER_RETURNED: `Возврат на СЦ`,
  ORDER_INFO_SORT_CENTER_RETURNED_AND_SHIPPED: `Возврат отгружен с СЦ`,
  ORDER_INFO_SORT_CENTER_PARTIALLY_RETURNED: `Част. возврат на СЦ`,
  ORDER_INFO_SORT_CENTER_PARTIALLY_RETURNED_AND_SHIPPED: `Част. возврат отгружен с СЦ`,
  ORDER_INFO_SORT_CENTER_PARTIALLY_RETURNED_AND_DELIVERED: `Ожидает приёмки возврата`,
  ORDER_INFO_RETURNED_TO_CLIENT: `Возвращен клиенту напрямую`,
}

export const INFO_STATUS_BAR_COLORS: OrderStatusKeys = {
  ORDER_INFO_DRAFT: '00000',
  ORDER_INFO_NEW: '10000',
  ORDER_INFO_NEED_CONFIRM: '10000',
  ORDER_INFO_INSUFFICIENT_RESERVE: '21000',
  ORDER_INFO_REQUIRE_PAYMENT: '21000',
  ORDER_INFO_READY_TO_PACK: '22100',
  ORDER_INFO_CONFIRM_HOLD: '22000',
  ORDER_INFO_PACKED: '22200',
  ORDER_INFO_IN_PACKING: '22100',
  ORDER_INFO_SHIPPED: '22210',
  ORDER_INFO_COURIER: '22210',
  ORDER_INFO_DELIVERED: '33333',
  ORDER_INFO_PARTIALLY_DELIVERED_NOT_RETURNED: '33332',
  ORDER_INFO_PARTIALLY_DELIVERED_RETURNED: '33333',
  ORDER_INFO_RETURNED: 'ccccc',
  ORDER_INFO_LOST: 'ccce0',
  ORDER_INFO_RETURNING: '00000',
  ORDER_INFO_AT_TAKEOUT_POINT: '22220',
  ORDER_INFO_DONE: '33333',
  ORDER_INFO_RETURNED_TO_CLIENT: '33333',
  ORDER_INFO_CANCELED: 'ccccc',
  ORDER_INFO_WAIT_UNPACK: '22100',
  ORDER_INFO_SORT_CENTER_ARRIVED: '22210',
  ORDER_INFO_SORT_CENTER_SHIPPED: '22210',
}

export const DELIVERY_TYPES = {
  COURIER: 'Курьер',
  PVZ: 'Пункт выдачи заказов',
  RUSSIAN_POST: 'Отделение',
  SELF_PICKUP: 'Самовывоз из магазина',
}

export const PRODUCT_SALE_STATUSES: { [key in ProductSaleStatus]: string } = {
  NEW: 'Черновик',
  READY_FOR_SALE: 'Готов к продаже',
  ON_SALE: 'В продаже',
  WITHDRAWN: 'Снят с продажи',
  DECLINED: 'Отклонен',
  ARCHIVE: 'В архиве',
}
