import React, { useState } from 'react'
import { useFormik } from 'formik'

import styles from './order-delivery-modal.module.scss'

import CityBlock from './CityBlock'
import AddressBlock from './AddressBlock'
import PvzBlock from './PvzBlock'
import DeliveryVariantsBlock from './DeliveryVariantsBlock'

import { Modal } from 'src/components'

import { AddressFiases, Tariff } from 'src/ADMIN/interfaces'
import { OrderDelivery } from 'src/interfaces'

interface OrderDeliveryModalProps {
  defaultData?: OrderDelivery
  onClose: () => void
}

const OrderDeliveryModal = (props: OrderDeliveryModalProps) => {
  const { defaultData, onClose } = props

  const [fiases, setFiases] = useState<AddressFiases | undefined>()

  const [tariff, setTariff] = useState<Tariff | undefined>()

  const { values, setFieldValue, handleChange, handleSubmit, setValues } =
    useFormik({
      initialValues: {
        street: '',
        house: '',
        block: '',
        building: '',
        flat: '',
        zip: '',
        desiredDeliveryDate: null,
        pickupPointId: null,
      },
      onSubmit: () => {},
    })

  return (
    <Modal id="orderDelivery" title="Доставка" onClose={onClose}>
      <form className={styles.wrapper} onSubmit={handleSubmit}>
        <CityBlock defaultData={defaultData} onCityChange={setFiases} />

        {tariff ? (
          <>
            {tariff.type === 'COURIER' || tariff.type === 'RUSSIAN_POST' ? (
              <AddressBlock
                values={values}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
              />
            ) : null}

            {tariff.type === 'PVZ' ? (
              <PvzBlock
                fiases={fiases}
                tariff={tariff}
                pointFieldValue={values.pickupPointId}
                setPointFieldValue={(value) =>
                  setFieldValue('pickupPointId', value)
                }
              />
            ) : null}
          </>
        ) : null}

        {fiases ? (
          <DeliveryVariantsBlock fiases={fiases} onTariffChange={setTariff} />
        ) : null}
      </form>
    </Modal>
  )
}

export default OrderDeliveryModal
