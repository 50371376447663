import React, { useRef, useEffect } from 'react'
import cx from 'classnames'

import { Tooltip } from 'src/components'

import styles from './input.module.scss'

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  inputClassName?: string
  template?: string
  label?: string
  error?: string
  withoutError?: boolean
  IconBefore?: React.FC
  IconAfter?: React.FC
  focusOnMount?: boolean
  onIconAfterClick?: () => void
  tooltip?: string
  tooltipSide?: 'top' | 'right'
}

const Input = (props: InputProps) => {
  const {
    inputClassName,
    template = 'basic',
    required,
    id,
    type = 'text',
    label,
    IconBefore,
    IconAfter,
    focusOnMount,
    onIconAfterClick,
    className,
    error,
    withoutError = false,
    tooltip,
    tooltipSide,
    children,
    ...otherProps
  } = props

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (focusOnMount) {
      inputRef?.current?.focus()
    }
  }, [])

  return (
    <div className={cx(className, styles.wrapper, styles[template])}>
      {label ? (
        <label htmlFor={id}>
          {label}
          {required ? <span className="required">*</span> : null}
        </label>
      ) : null}
      <div className={styles.inputWrapper}>
        {IconBefore ? (
          <div className={cx(styles.iconBefore, template && styles[template])}>
            <IconBefore />
          </div>
        ) : null}
        <input
          id={id}
          className={cx(
            inputClassName,
            styles.input,
            IconBefore && styles.withIconBefore,
            IconAfter && styles.withIconAfter,
            error && styles.inputError,
            template && styles[template],
          )}
          {...otherProps}
          type={type}
          ref={inputRef}
          onWheel={(e) => e.currentTarget.blur()}
        />
        {IconAfter ? (
          <div
            className={cx(
              styles.iconAfter,
              tooltip && styles.haveTooltip,
              onIconAfterClick && styles.clickable,
              template && styles[template],
            )}
            onClick={() =>
              onIconAfterClick ? onIconAfterClick() : () => false
            }
          >
            <IconAfter />
          </div>
        ) : null}
        {tooltip ? (
          <Tooltip
            className={styles.tooltip}
            content={tooltip}
            side={tooltipSide}
          />
        ) : null}
        {children ? children : null}
      </div>
      {!withoutError ? <div className={styles.errorText}>{error}</div> : null}
    </div>
  )
}

export default Input
