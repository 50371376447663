import React, { useContext } from 'react'
import numeral from 'numeral'
import cx from 'classnames'

import { AppContext } from 'src/App'

import styles from './price.module.scss'

interface PriceProps {
  className?: string
  value: number
}

const Price = (props: PriceProps) => {
  const { className, value } = props

  const { currencySymbol } = useContext(AppContext)

  return (
    <div className={cx(styles.wrapper, className)}>
      {`${numeral(value).format('0.[00]')} ${currencySymbol}`}
    </div>
  )
}

export default Price
