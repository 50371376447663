import { useQuery, useMutation } from 'react-query'
import { ModerationTask, ModerationStatusesRes } from 'src/ADMIN/interfaces'

import api from 'src/api'

interface UseModerationTasksParamsType {
  page?: number | null
  size?: number | null
  id?: string
  productId?: string
}

const getModerationTasks = async (params?: UseModerationTasksParamsType) => {
  const result = await api.get(`v1/moderation`, { params })
  if (!result.data?.success) throw new Error(result.data.message)
  return result.data
}

interface ModerationTasks {
  tasks: ModerationTask[]
  recordsTotal: number
}

export const useModerationTasks = (params?: UseModerationTasksParamsType) => {
  return useQuery<ModerationTasks>(
    [`moderationTasks`, params],
    () => getModerationTasks(params),
    {
      enabled: !!params,
      keepPreviousData: true,
      initialData: { tasks: [], recordsTotal: 0 },
    },
  ) as { data: ModerationTasks }
}

interface AcceptProductModeration {
  productId: string
  productUuid: string
  status: 'ACCEPTED' | 'DECLINED'
  comment: string | null
}

const acceptProductModeration = async (data: AcceptProductModeration) => {
  const result = await api.post(`v1/moderation/accept`, data)
  if (!result.data?.success) throw new Error(result.data.message)
  return result.data
}

export const useAcceptProductModeration = () => {
  return useMutation(async (data: any) => {
    return await acceptProductModeration(data)
  })
}

const getModerationStatuses = async () => {
  const result = await api.get(`v1/moderation/statuses`)
  if (!result.data?.success) throw new Error(result.data.message)
  return result.data.statuses
}

export const useModerationStatuses = () => {
  return useQuery<ModerationStatusesRes>(
    [`moderationStatuses`],
    getModerationStatuses,
    {
      initialData: {},
    },
  )
}
