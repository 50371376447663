import React, { useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import cx from 'classnames'

import styles from './date-picker.module.scss'

import Day from './Day'

import { Input, Icons } from 'src/components'

interface CalendarDayType {
  day: number
  dayOfCurrentMonth: boolean
  date: string
}

const WEEK_DAYS = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс']

const getDaysForPage = (activeMonth: Dayjs) => {
  const result = []

  const month = dayjs(activeMonth)
  const daysInMonth = activeMonth.daysInMonth()

  const firstDayAsDayOfWeek = month.set('date', 1).day() || 7
  const daysBefore = Array.from(Array(firstDayAsDayOfWeek - 1).keys())
  const daysInMonthBefore = daysBefore.length
    ? dayjs(activeMonth).add(-1, 'month').daysInMonth()
    : 0

  const daysAfter = Array.from(
    Array(42 - daysBefore.length - daysInMonth).keys(),
  )

  return [
    ...daysBefore
      .map((d, i) => ({
        day: daysInMonthBefore - i,
        date: dayjs(activeMonth)
          .add(-1, 'month')
          .set('date', daysInMonthBefore - i)
          .format(),
      }))
      .reverse(),
    ...Array.from(Array(daysInMonth).keys()).map((d) => ({
      day: d + 1,
      dayOfCurrentMonth: true,
      date: dayjs(activeMonth)
        .set('date', d + 1)
        .format(),
    })),
    ...daysAfter.map((d) => ({
      day: d + 1,
      date: dayjs(activeMonth)
        .add(1, 'month')
        .set('date', d + 1)
        .format(),
    })),
  ] as CalendarDayType[]
}

interface DatePickerProps {
  label?: string
  value?: string
  min?: string
  max?: string
  onSelect: (value?: string) => void
}

const DatePicker = (props: DatePickerProps) => {
  const { label, value, min, max, onSelect } = props

  const [isOpen, setIsOpen] = useState(false)
  const [activeMonth, setActiveMonth] = useState(dayjs().startOf('day'))

  const handlePrevActiveMonth = () => {
    const newValue = dayjs(activeMonth).add(-1, 'month')
    setActiveMonth(newValue)
  }

  const handleNextActiveMonth = () => {
    const newValue = dayjs(activeMonth).add(1, 'month')
    setActiveMonth(newValue)
  }

  const handleSelectDate = (day: CalendarDayType) => {
    onSelect(day.date.split('+')[0])
    setIsOpen(false)
  }

  const handleClearDate = () => {
    onSelect(undefined)
  }

  const days = getDaysForPage(activeMonth)

  return (
    <div className={styles.wrapper}>
      <Input
        label={label}
        template="small"
        className={styles.inputWrapper}
        inputClassName={styles.input}
        value={value ? dayjs(value).format('DD.MM.YYYY') : ''}
        readOnly
        IconAfter={!value ? Icons.Calendar : Icons.CircleClose}
        onClick={() => setIsOpen(!isOpen)}
        onIconAfterClick={() =>
          value ? handleClearDate() : setIsOpen(!isOpen)
        }
      />

      {isOpen ? (
        <div className={styles.calendar}>
          <div className={styles.topPanel}>
            <Icons.Arrow
              className={styles.leftArrow}
              onClick={handlePrevActiveMonth}
            />
            <div className={styles.activeMonth}>
              {activeMonth.format('MMM YYYY')}
            </div>
            <Icons.Arrow
              className={styles.rightArrow}
              onClick={handleNextActiveMonth}
            />
          </div>
          <div className={styles.weekDays}>
            {WEEK_DAYS.map((d) => (
              <div key={d}>{d}</div>
            ))}
          </div>
          <div className={styles.monthDays}>
            {days.map((d: CalendarDayType) => (
              <Day
                key={d.date}
                label={d.day}
                isDayOfCurrentMonth={d.dayOfCurrentMonth}
                isActive={Boolean(value && dayjs(value).isSame(d.date, 'day'))}
                isDisabled={Boolean(
                  (min &&
                    dayjs(d.date).isSameOrBefore(
                      dayjs(min).add(-1, 'day'),
                      'day',
                    )) ||
                    (max &&
                      dayjs(d.date).isSameOrAfter(
                        dayjs(max).add(1, 'day'),
                        'day',
                      )),
                )}
                onClick={() => handleSelectDate(d)}
              />
            ))}
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default DatePicker
