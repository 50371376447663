import { useQuery, useMutation } from 'react-query'

import api from 'src/api'

import { AddressFiases, DeliveryPoint, Tariff } from 'src/ADMIN/interfaces'

interface UseLocationPrompterParams {
  query: string
  include_foreign?: boolean
  country?: string
}

const getLocationSuggestionsForPrompter = async (
  params: UseLocationPrompterParams,
) => {
  //const result = await api.get(`prompter/location`, { params })
  const result = await api.get('prompter/location', { params, source: 'kak2c' })
  if (!result.data?.success) throw new Error(result.data.message)
  return result.data
}

interface LocationPromter {
  suggestions: any //TODO type
  success: boolean
}

export const useLocationPrompter = (params: UseLocationPrompterParams) => {
  return useQuery<LocationPromter | undefined>(
    [`locationPrompter`, params],
    () => getLocationSuggestionsForPrompter(params),
    {
      enabled: params.query.length > 2,
      //keepPreviousData: true,
      //initialData: { suggestions: [], success: false },
    },
  )
}

interface DeliveryVariantsCalculation {
  fromTempoline: boolean
  sourceRegionFias: string | null
  sourceAreaFias: string | null
  sourceCityFias: string | null
  sourceSettlementFias: string | null
  sourcePostalCode: string | null
  regionFias?: string | null
  areaFias?: string | null
  cityFias?: string | null
  settlementFias?: string | null
  postalCode: string | null
  weight: number
  insuranceSum: number
  codSum: number
  dimensions: {
    height: number
    width: number
    depth: number
  }
}

const calculateDeliveryVariants = async (data: DeliveryVariantsCalculation) => {
  const result = await api.post(`tariffs`, data, { source: 'dlv' })
  if (!result.data?.success) throw new Error(result.data.message)
  return result.data.tariffs
}

export const useDeliveryVariantsCalculation = (
  data: DeliveryVariantsCalculation,
) =>
  useQuery<Tariff[]>([`tariffs`, data], () => calculateDeliveryVariants(data))

type DeliveryPointsGettingParams = AddressFiases & {
  courierCode?: string
}

const getDeliveryPoints = async (address: DeliveryPointsGettingParams) => {
  const { data } = await api.post(`delivery_points`, address, { source: 'dlv' })
  return data.list
}

export const useDeliveryPoints = (address: DeliveryPointsGettingParams) =>
  useQuery<DeliveryPoint[]>(
    [`deliveryPoints`, address],
    async () => await getDeliveryPoints(address),
  )

interface DeliveryPointGettingParams {
  courierCode: string
  pvzCode: string
}

const getDeliveryPoint = async (params?: DeliveryPointGettingParams) => {
  const { data } = await api.post(`delivery_point`, params, { source: 'dlv' })

  if (!data.success) {
    throw 'Ошибка получения ПВЗ'
  }

  return data.list[0]
}

export const useDeliveryPoint = (
  data: DeliveryPointGettingParams,
  { onError }: { onError: () => void },
) =>
  useQuery<DeliveryPoint>(
    [`deliveryPoint`, data],
    () => getDeliveryPoint(data),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError,
    },
  )
