import React, { useState, useCallback } from 'react'
import { useFormik } from 'formik'
import dayjs from 'dayjs'

import styles from './address-block.module.scss'

import { Input, DatePicker } from 'src/components'

import { DeliveryFormValues } from 'src/ADMIN/interfaces'

interface AddressBlockProps {
  values: DeliveryFormValues
  handleChange: (e: React.FormEvent<HTMLInputElement>) => void
  setFieldValue: (field: string, value: any) => void
}

const AddressBlock = (props: AddressBlockProps) => {
  const { values, handleChange, setFieldValue } = props

  return (
    <div className={styles.wrapper}>
      <h3>Адрес доставки</h3>

      <div className={styles.row}>
        <Input
          id="street"
          label="Улица"
          value={values.street}
          onChange={handleChange}
        />

        <Input
          id="zip"
          label="Индекс"
          value={values.zip}
          onChange={handleChange}
        />
      </div>

      <div className={styles.row}>
        <Input
          id="house"
          label="Дом"
          value={values.house}
          onChange={handleChange}
        />

        <Input
          id="block"
          label="Корпус"
          value={values.block}
          onChange={handleChange}
        />

        <Input
          id="building"
          label="Строение"
          value={values.building}
          onChange={handleChange}
        />

        <Input
          id="flat"
          label="Квартира/Офис"
          value={values.flat}
          onChange={handleChange}
        />
      </div>

      <h3>Желаемое время доставки</h3>

      <DatePicker
        label="Дата доставки"
        value={values.desiredDeliveryDate || ''}
        min={dayjs().toISOString()}
        onSelect={(value) => setFieldValue('desiredDeliveryDate', value)}
      />
    </div>
  )
}

export default AddressBlock
