import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import {
  useQueryParams,
  DecodedValueMap,
  QueryParamConfigMap,
} from 'use-query-params'

import styles from './orders.module.scss'

import Filters from './Filters'
import StatusBar from './StatusBar'
import DeliveryBlock from './DeliveryBlock'
import SelectPanel from './SelectPanel'
import OrdersTabs, { ORDERS_PAGE_TABS } from './OrdersTabs'

import {
  Page,
  Table,
  Pagination,
  SelectOnPage,
  PaymentBadge,
} from 'src/components'

import { useOrders } from 'src/hooks'

import {
  getPhoneWithMask,
  getFullAddress,
  filterObject,
  updateQuery,
  setQueryFieldValue,
  handleChangePagination,
} from 'src/helpers'

import { OrderFilterParams } from './interfaces'

interface FilterPanelProps {
  page: number
  size: number
  total: number
  onChange: (size: number) => void
}

const FilterPanel = (props: FilterPanelProps) => {
  const { page, size, total, onChange } = props

  return (
    <div className={styles.filterPanel}>
      <Pagination.StateString {...props} />
      <SelectOnPage value={size} onChange={onChange} />
    </div>
  )
}

const getOrdersStateInitialData = (tab?: string | null) => ({
  page: 0,
  size: 10,
  tab,
})

const getOrdersState = (query: DecodedValueMap<QueryParamConfigMap>) => {
  const newState = { ...query }

  if (query.tab) {
    const currentTabData = ORDERS_PAGE_TABS.find((t) => t.id === query.tab)
    if (currentTabData) {
      if (currentTabData?.states?.length) {
        if (query.tab === 'errors') {
          newState.problemOrders = true
        } else {
          newState.filter_states = (query.filter_states?.split(',') ?? [])
            .concat(currentTabData.states)
            .join(',')
        }
      }
    }

    delete newState.tab
  }

  return newState
}

const Orders = () => {
  const [query, setQuery] = useQueryParams(OrderFilterParams)

  const {
    data: { orders, recordsTotal },
  } = useOrders(getOrdersState(query))

  const handleSelectDeliveryType = (deliveryType: string) => {
    console.log('SELECT DELIVERY TYPE')
    console.log(deliveryType)
  }

  const setFieldValue = (key: string, value?: string | number | null) =>
    setQueryFieldValue(query, setQuery, key, value)

  const setFilterFieldValue = (key: string, value?: string | null) => {
    updateQuery(query, setQuery, {
      page: 0,
      [key]: value,
    })
  }

  const handleTabChange = (tab: string) => {
    updateQuery(query, setQuery, { tab, page: 0 })
  }

  const resetForm = () => {
    setQuery(getOrdersStateInitialData(query.tab), 'replace')
  }

  const onPaginationChange = (page: number, size: number) =>
    handleChangePagination(query, setQuery, page, size)

  return (
    <Page title="Заказы">
      <Filters
        values={query}
        setFieldValue={setFieldValue}
        setFilterFieldValue={setFilterFieldValue}
        resetForm={resetForm}
      />

      <OrdersTabs
        currentTab={query.tab || 'all'}
        onTabChange={handleTabChange}
      />

      <Table
        FilterPanel={() => (
          <FilterPanel
            page={query.page}
            size={query.size}
            total={recordsTotal}
            onChange={(size) =>
              handleChangePagination(query, setQuery, query.page + 1, size)
            }
          />
        )}
        SelectPanel={SelectPanel}
      >
        {orders.map(
          (
            row: any, //TODO order type
          ) => (
            <Table.Row key={row.id} id={row.id}>
              <Table.Column title="Заказ">
                <Link
                  className={styles.orderLink}
                  to={`/orders/${row.id}`}
                  target="_blank"
                >
                  {row.id}
                </Link>
                <span className={styles.subtext}>
                  {dayjs(row.date).format('DD MMM, HH:mm')}
                </span>
              </Table.Column>

              <Table.Column title="Получатель">
                {row.delivery?.receiver?.fullName}
                <span className={styles.subtext}>
                  {getPhoneWithMask(row.delivery?.receiver?.phone)}
                </span>
              </Table.Column>

              <Table.Column title="Статус">
                <StatusBar status={row.status} />
                {!row.summaryItemAmountState?.amountSHIPPED &&
                  row.summaryItemAmountState?.amountRESERVED <
                    row.summaryItemAmountState?.amountORDERED && (
                    <>
                      <span>Резерв товаров: </span>
                      <span>
                        {row.summaryItemAmountState.amountRESERVED} из{' '}
                        {row.summaryItemAmountState.amountORDERED}
                      </span>
                    </>
                  )}
              </Table.Column>

              <Table.Column title="Служба доставки">
                <DeliveryBlock
                  {...row.delivery}
                  onSelect={handleSelectDeliveryType}
                />
              </Table.Column>

              <Table.Column title="Адрес доставки">
                {row.delivery?.address?.city}
                <span className={styles.subtext}>
                  {getFullAddress(row.delivery?.address)}
                </span>
              </Table.Column>

              <Table.Column title="Статус оплаты">
                <PaymentBadge data={row} />
              </Table.Column>

              <Table.Column title="Сумма">{row.totalOrderSum} ₽</Table.Column>
            </Table.Row>
          ),
        )}
      </Table>

      <Pagination
        className={styles.pagination}
        current={query.page + 1}
        pageSize={query.size}
        total={recordsTotal || 0}
        onChange={onPaginationChange}
      />
    </Page>
  )
}

export default Orders
