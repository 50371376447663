import React, { Children, ComponentProps, ReactElement } from 'react'

import styles from './tabs.module.scss'
import tabPane from './tabPane'
import Tab from './Tab'

interface Props {
  children:
    | ReactElement<ComponentProps<typeof tabPane>>
    | ReactElement<ComponentProps<typeof tabPane>>[]
  activeKey?: string | null
}

const Tabs = (props: Props) => {
  const { children, activeKey } = props

  const tabs = Children.map(children, ({ props, key }) => ({
    ...props,
    key,
    id: key !== null ? String(key) : undefined,
  }))
  return (
    <div className={styles.scrollWrapper}>
      <div className={styles.scrollElement}>
        <div className={styles.tabsWrapper}>
          {tabs.map((tab) => (
            <Tab
              key={tab.key}
              id={tab.id}
              active={tab.key === activeKey}
              icon={tab.icon}
              elementsCount={tab.elementsCount}
              onClick={tab.onClick}
            >
              {tab.children}
            </Tab>
          ))}
        </div>
      </div>
    </div>
  )
}

Tabs.TabPane = tabPane
export default Tabs
