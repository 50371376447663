import React from 'react'

import styles from './actionsPanel.module.scss'

const ActionsPanel = (props: React.PropsWithChildren<{}>) => {
  const { children } = props

  return <div className={styles.wrapper}>{children}</div>
}

export default ActionsPanel
