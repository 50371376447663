import React, { useState } from 'react'

import styles from './gallery.module.scss'

import { FileItem } from 'src/components'

import { ProductCreatingImage } from 'src/interfaces'

interface GalleryProps {
  images: ProductCreatingImage[]
}

const Gallery = (props: GalleryProps) => {
  const { images } = props

  const [activeIndex, setActiveIndex] = useState(0)

  return (
    <div className={styles.wrapper}>
      <div className={styles.bigImage}>
        {images[activeIndex] ? <img src={images[activeIndex].url} /> : null}
      </div>
      <div className={styles.thumbnails}>
        {images.map((image, index) => (
          <FileItem
            key={image.id}
            active={index === activeIndex}
            size="small"
            template="gallery"
            url={image.url}
            onClick={() => setActiveIndex(index)}
          />
        ))}
      </div>
    </div>
  )
}

export default Gallery
