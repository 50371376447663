import React, { useMemo, useState } from 'react'

import { YMaps, Map as YMap, Clusterer, ZoomControl } from 'react-yandex-maps'

import styles from './pvz-map.module.scss'

import PvzMarker from './PvzMarker'

import { Button } from 'src/components'

import { DeliveryPoint, Tariff } from 'src/ADMIN/interfaces'

const getMapCenter = (point?: DeliveryPoint) => {
  return point
    ? [parseFloat(point.latitude), parseFloat(point.longitude)]
    : [0, 0]
}

interface PvzMapProps {
  currentPoint?: DeliveryPoint
  points?: DeliveryPoint[]
  tariff?: Tariff
  setCurrentPoint: (point: DeliveryPoint) => void
}

const PvzMap = (props: PvzMapProps) => {
  const { currentPoint, points, tariff, setCurrentPoint } = props

  const content = useMemo(
    () => (
      <Clusterer gridSize={128}>
        {points?.map((point) => (
          <PvzMarker
            key={point.code}
            point={point}
            price={tariff?.price}
            setCurrentPoint={setCurrentPoint}
          />
        ))}
      </Clusterer>
    ),
    [points],
  )

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <YMaps>
          <YMap
            defaultState={{ center: [55.75, 37.57], zoom: 10 }}
            state={{
              center: getMapCenter(currentPoint || points?.[0]),
              zoom: currentPoint ? 18 : 10,
            }}
            height="100%"
            modules={[`layout.ImageWithContent`]}
            width="100%"
          >
            {content}
            <ZoomControl
              options={{
                position: {
                  top: 24,
                  right: 16,
                  bottom: `auto`,
                  left: `auto`,
                },
              }}
            />
          </YMap>
        </YMaps>
      </div>
    </div>
  )
}

export default PvzMap
