import React, { useState, useEffect, useRef, useContext } from 'react'
import { useParams, Link } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import cx from 'classnames'

import styles from './single-product.module.scss'

import { STEPS, getProductValidationSchema } from '../Steps'

import HeaderButtons from './HeaderButtons'

import { Page, PageNav, ActionsPanel, Button, Icons } from 'src/components'

import { useNotifications } from 'src/notification'
import { useProductById, useUpdateProduct } from 'src/hooks/products'

import {
  reformatProductDataBeforeSaving,
  getProductFormikInitialValues,
  isMultiVariant,
} from 'src/helpers'

import { PATHS } from 'src/config'

import { AppContext } from 'src/App'

import { Variant } from 'src/interfaces'

const SingleProductPage = () => {
  const { productId } = useParams<{ productId: string }>()

  const { data: product } = useProductById({
    id: productId,
  })

  const notifications = useNotifications()
  const updateProductMutation = useUpdateProduct()

  const [currentPartName, setCurrentPartName] = useState(STEPS[0].name)

  const [isLoaded, setIsLoaded] = useState(false)
  const [showActionsPanel, setShowActionsPanel] = useState(false)

  const scrolledBlockRef = useRef<HTMLDivElement>(null)

  const {
    values,
    errors,
    submitCount,
    setValues,
    handleChange,
    setFieldValue,
    validateForm,
    submitForm,
  } = useFormik({
    initialValues: getProductFormikInitialValues(),
    onSubmit: (values) => {
      updateProductMutation.mutate(
        reformatProductDataBeforeSaving(values, productId),
        {
          onSuccess: () => {
            notifications?.show('success', 'Изменения успешно сохранены')
            setShowActionsPanel(false)
          },
        },
      )
    },
    validationSchema: getProductValidationSchema(false),
  })

  useEffect(() => {
    if (isLoaded) {
      setShowActionsPanel(true)
    }
  }, [values])

  useEffect(() => {
    if (product) {
      ;(async function () {
        await setValues(getProductFormikInitialValues(product))
        setIsLoaded(true)
      })()
    }
  }, [product])

  return (
    <>
      <Page
        title="Редактирование товара"
        backUrl={PATHS.PRODUCTS}
        rightBlock={
          <HeaderButtons
            productId={productId}
            productStatus={product?.saleStatus}
            values={values}
            handleSave={() => submitForm()}
          />
        }
      >
        <div
          className={cx(styles.wrapper, showActionsPanel && styles.bottomSpace)}
        >
          <div className={styles.navWrapper}>
            <PageNav
              parts={STEPS.filter((s) => s.name !== 'preview').map((s) => ({
                name: s.name,
                title: s.title,
                icon: s.icon,
              }))}
              currentPartName={currentPartName}
              scrolledElement={
                scrolledBlockRef ? scrolledBlockRef.current : null
              }
              onSelect={setCurrentPartName}
            />
          </div>

          <div className={styles.rightBlock} ref={scrolledBlockRef} id="test">
            {STEPS.filter((part) => part.name !== 'preview').map((part) => (
              <>
                {part.name === 'variants' && !isMultiVariant(values) ? null : (
                  <div key={part.name} id={`part-${part.name}`}>
                    <h2>{part.title}</h2>
                    <part.component
                      editMode
                      productId={productId}
                      values={values}
                      errors={errors}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      </Page>

      {showActionsPanel ? (
        <ActionsPanel>
          <div className={styles.actionsPanel}>
            <div>Сохранить изменения</div>
            <Button
              className={styles.saveButton}
              template="secondary"
              loading={updateProductMutation.isLoading}
              icon={<Icons.Diskette />}
              onClick={submitForm}
            >
              Сохранить
            </Button>

            {!updateProductMutation.isLoading ? (
              <Button
                template="secondary"
                icon={<Icons.CircleCloseLight />}
                onClick={() => document.location.reload()}
              >
                Отменить
              </Button>
            ) : null}
          </div>
        </ActionsPanel>
      ) : null}
    </>
  )
}

export default SingleProductPage
