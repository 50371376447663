import React from 'react'
import styles from './select-panel.module.scss'

import { Icons } from 'src/components'

interface SelectPanelProps {
  selectedItems: string[]
  onUnselect: () => void
}

const SelectPanel = (props: SelectPanelProps) => {
  const { selectedItems, onUnselect } = props

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.count}
      >{`Выбрано заказов: ${selectedItems.length}`}</div>
      <div className={styles.divider} />
      <button onClick={onUnselect}>Отменить</button>

      <button className={styles.goRight}>
        <Icons.Refresh />
        Обновить резервы заказов
      </button>
      <div className={styles.divider} />
      <button>
        <Icons.CircleCloseLight />
        Отменить заказ
      </button>
    </div>
  )
}

export default SelectPanel
