import { useQuery, useMutation } from 'react-query'
import { Product } from 'src/interfaces'

import api from 'src/api'

import {
  ProductCreatingImage,
  ProductCreatingVariantValues,
} from 'src/interfaces'
import { Category } from 'src/interfaces/category'

interface UseProductsParamsType {
  page?: number
  size?: number
  id?: string
  order?: string
  query?: string | null
}

// type UseProductByIdParamsType = {
//   id: string | null
// }

const getProducts = async (params?: UseProductsParamsType) => {
  const result = await api.get(`v1/products`, { params })
  if (!result.data?.success) throw new Error(result.data.message)
  return params?.id ? result.data.products[0] : result.data
}

interface Products {
  products: Product[]
  recordsTotal: number
}

export const useProducts = (params?: UseProductsParamsType) => {
  return useQuery<Products>([`products`, params], () => getProducts(params), {
    enabled: !!params,
    keepPreviousData: true,
    initialData: { products: [], recordsTotal: 0 },
  }) as { data: Products }
}

export const useProductById = (params?: UseProductsParamsType) => {
  return useQuery<Product>(
    [`products`, params],
    () => {
      if (params && params.id === null) {
        return Promise.resolve()
      }
      return getProducts(params)
    },
    {
      enabled: !!params,
      keepPreviousData: true,
    },
  ) as { data: Product }
}

const postProducts = async (
  data: any,
  errorCallback?: (message: string) => string,
) => {
  //TODO type
  const result = await api.post(`v1/products`, data, { errorCallback })
  if (!result.data?.success) throw new Error(result.data.message)
  return result.data
}

const postProductImage = async (data: any) => {
  //TODO type
  const result = await api.post(`v1/products/image`, data)
  if (!result.data?.success) throw new Error(result.data.message)
  return result.data
}

const deleteProductVariant = async (productId: string, variantId: string) =>
  api.delete(
    `v1/products/variants?productId=${productId}&variantId=${variantId}`,
  )

export const useCreateProduct = () => {
  return useMutation(async ({ data, images = [] }: any) => {
    //TODO type
    return await postProducts({
      shortName: data.shortName,
      categoryId: data.categoryId,
    })
      .then(async (result) => {
        for (let i = 0; i < images.length; i++) {
          const formData = new FormData()
          formData.append('id', result.product.id)
          formData.append('upfile', images[i].file)
          const imageUploadingResult = await postProductImage(formData)
          images[i].url = imageUploadingResult.url
        }
        return result
      })
      .then(
        async (result) =>
          await postProducts({
            id: result.product.id,
            ...data,
            variantsToUpdate: data.variantsToUpdate.map(
              (v: any, i: number) => ({
                ...v,
                productId: result.product.id,
                variantId: !i ? result.product.variants[0].id : undefined,
                imageId: undefined,
                defaultImage:
                  (v.imageId &&
                    (images.find(
                      (image: ProductCreatingImage) => image.id === v.imageId,
                    )?.url ??
                      null)) ||
                  null,
              }),
            ), //TODO type ?
          }),
      )
  })
}

export const useUpdateProduct = () => {
  return useMutation(
    async ({ data, images = [], productId, variantId }: any) => {
      return new Promise(async (resolve) => {
        for (let i = 0; i < data.variantsForRemoving.length; i++) {
          await deleteProductVariant(productId, data.variantsForRemoving[i].id)
        }
        resolve(true)
      })
        .then(async () => {
          for (let i = 0; i < images.length; i++) {
            if (!images[i].url) {
              const formData = new FormData()
              formData.append('id', productId)
              formData.append('upfile', images[i].file)
              const imageUploadingResult = await postProductImage(formData)
              images[i].url = imageUploadingResult.url
            }
          }

          return true
        })
        .then(async () => {
          const alreadyHaveBasicVariant = data.variantsToUpdate.find(
            (v: ProductCreatingVariantValues) => v.id === variantId,
          )

          return await postProducts({
            id: productId,
            ...data,
            variantsToUpdate: data.variantsToUpdate.map(
              (v: ProductCreatingVariantValues, i: number) => ({
                ...v,
                productId,
                variantId:
                  variantId && !alreadyHaveBasicVariant && !i
                    ? variantId
                    : v.id,
                defaultImage:
                  (v.imageId &&
                    (images.find(
                      (image: ProductCreatingImage) => image.id === v.imageId,
                    )?.url ??
                      null)) ||
                  v.imageUrl ||
                  v.defaultImage,
              }),
            ),
          }).then((res) => {
            return { ...res, images }
          })
        })
    },
  )
}

export const useArchiveProduct = () => {
  return useMutation(async ({ data }: any) => {
    //TODO type ?
    return await postProducts(
      {
        ...data,
        deleted: true,
        variantsToUpdate: [],
      },
      (errorMessage) =>
        `Архивация продукта ${data.id} прошла неуспешно: ${errorMessage}`,
    )
  })
}

export const useVariantDeleting = () => {
  //TODO remove mutation ?
  return useMutation(
    async ({
      productId,
      variantId,
    }: {
      productId: string
      variantId: string
    }) => {
      return api.delete(
        `v1/products/variants?productId=${productId}&variantId=${variantId}`,
      )
    },
  )
}

export const useCategories = () => {
  return useQuery<Category>(
    'categories',
    async () => (await api.get(`v1/products/categories`)).data,
  )
}

export const useAttributes = (params?: { categoryId: string }) => {
  return useQuery(
    [`attributes`, params],
    async () => api.get(`v1/products/attributes`, { params }),
    {
      enabled: params?.categoryId !== '',
      select: (result) => result && result.data?.success && result.data,
    },
  )
}

export const useFeatures = (params?: { categoryId: string }) => {
  return useQuery(
    [`features`, params],
    async () => api.get(`v1/products/features`, { params }),
    {
      select: (result) => result && result.data?.success && result.data,
    },
  )
}

const sendProductToModeration = async (productId: string) => {
  const result = await api.post(`v1/moderation?productId=${productId}`, {})
  if (!result.data?.success) throw new Error(result.data.message)
  return result.data
}

export const useSendProductToModeration = () => {
  //TODO type
  return useMutation(async ({ productId }: any) => {
    return await sendProductToModeration(productId)
  })
}

const copyProduct = async (
  productId: string,
  errorCallback?: (message: string) => string,
) => {
  const result = await api.post(
    `v1/products/copy`,
    { id: productId },
    { errorCallback },
  )
  if (!result.data?.success) throw new Error(result.data.message)
  return result.data
}

export const useCopyProduct = () => {
  return useMutation(async (productId: string) => {
    return await copyProduct(
      productId,
      (errorMessage) =>
        `Копирование продукта ${productId} прошло неуспешно: ${errorMessage}`,
    )
  })
}
