import React from 'react'
import cx from 'classnames'

import { ReactComponent as TooltipIcon} from './assets/tooltip.svg'

import styles from './tooltip.module.scss'

//TODO .getBoundingClientRect() для обработки вылезания тултипа на пределы экрана

interface TooltipType {
    className?: string
    side?: 'right' | 'top'
    content: string
}

const Tooltip:React.FC<TooltipType> = (props) => {
    const {
        className,
        side = 'right',
        content,
    } = props

    return (
        <span className={cx(styles.wrapper,className)}>
            <TooltipIcon />
            <div className={cx(styles.content,styles[side])}>{content}</div>
        </span>
    )
}

export default Tooltip