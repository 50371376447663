import React from 'react'
import cx from 'classnames'

import styles from './moderation-status.module.scss'

export type ModerationStatusType = 'NEW' | 'EDITING' | 'REJECTED'

const getModerationStatusTitle = (status: ModerationStatusType) => {
  switch (status) {
    case 'NEW':
      return 'Новый товар'
    case 'EDITING':
      return 'Редактирование товара'
    case 'REJECTED':
      return 'Отклонено'
  }
}

interface ModerationStatusProps {
  status: ModerationStatusType
}

const ModerationStatus = (props: ModerationStatusProps) => {
  const { status } = props
  return (
    <div className={styles.wrapper}>
      <span className={cx(styles.circle, styles[status.toLowerCase()])} />
      {getModerationStatusTitle(status)}
    </div>
  )
}

export default ModerationStatus
