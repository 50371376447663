import React from 'react'
import cx from 'classnames'

import styles from './badge.module.scss'

interface BadgeProps {
  className?: string
  style?: React.CSSProperties
  title?: string
}

const Badge = (props: BadgeProps) => {
  const { className, title, style } = props

  return title ? (
    <span className={cx(styles.wrapper, className)} style={style}>
      {title}
    </span>
  ) : null
}

export default Badge
