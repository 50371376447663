import React from 'react'
import styles from './delivery-block.module.scss'

interface deliveryTypeKeysType {
  [key: string]: string
}

const DELIVERY_TYPE_TRANSLATE: deliveryTypeKeysType = {
  COURIER: 'Курьер',
  PVZ: 'Пункт выдачи заказов',
  RUSSIAN_POST: 'Отделение',
  SELF_PICKUP: 'Самовывоз из магазина',
}

interface DeliveryBlockProps {
  type: string
  title: string
  logoUrl: string
  onSelect: (deliveryType: string) => void
}

const DeliveryBlock = (props: DeliveryBlockProps) => {
  const { type, title, logoUrl, onSelect } = props

  let delivery_title

  if (DELIVERY_TYPE_TRANSLATE.hasOwnProperty(type)) {
    if (!title || type === 'SELF_PICKUP') {
      delivery_title = DELIVERY_TYPE_TRANSLATE[type]
    } else {
      delivery_title = title + ' – ' + DELIVERY_TYPE_TRANSLATE[type]
    }
  } else {
    delivery_title = ''
  }

  return (
    <div>
      <div style={{ backgroundImage: `url(${logoUrl})` }} />
      <div className={styles.deliveryTitle} onClick={() => onSelect(type)}>
        {delivery_title}
      </div>
    </div>
  )
}

export default DeliveryBlock
