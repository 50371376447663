import React, { useState } from 'react'
import cx from 'classnames'

import styles from './variants-block.module.scss'

import { Modal, Button, FileItem } from 'src/components'

import { ProductCreatingImage } from 'src/interfaces'

interface ImagesModalProps {
  images: ProductCreatingImage[]
  onSubmit: (image: ProductCreatingImage) => void
  onClose: () => void
}

const ImagesModal = (props: ImagesModalProps) => {
  const { images, onSubmit, onClose } = props

  const [active, setActive] = useState<ProductCreatingImage>()

  const handleSubmit = () => {
    onSubmit(active!)
    onClose()
  }

  return (
    <Modal
      id={`imagesModal`}
      title="Выберите изображение для варианта"
      HeaderButton={() => (
        <Button size="large" disabled={!Boolean(active)} onClick={handleSubmit}>
          Подтвердить
        </Button>
      )}
      onClose={onClose}
    >
      <div className={styles.imagesModal}>
        {images.map((image) => (
          <div
            key={image.id}
            className={cx(
              styles.imageItem,
              active && active!.id === image.id && styles.active,
            )}
          >
            <FileItem
              file={image.file}
              url={image.url}
              onClick={() => setActive(image)}
            />
          </div>
        ))}
      </div>
    </Modal>
  )
}

export default ImagesModal
