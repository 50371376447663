import React, { useState, useEffect } from 'react'

import styles from './pvz-block.module.scss'

import PvzMap from './PvzMap'
import PvzManual from './PvzManual'
import SelectedPvzData from './SelectedPvzData'

import { Link } from 'src/components'

import { useDeliveryPoints } from 'src/ADMIN/hooks'

import { AddressFiases, DeliveryPoint, Tariff } from 'src/ADMIN/interfaces'

interface PvzBlockProps {
  fiases?: AddressFiases
  tariff?: Tariff
  pointFieldValue: string | null
  setPointFieldValue: (pointId?: string) => void
}

const PvzBlock = (props: PvzBlockProps) => {
  const { fiases, tariff, pointFieldValue, setPointFieldValue } = props

  const [mode, setMode] = useState('map')
  const [currentPoint, setCurrentPoint] = useState<DeliveryPoint>()

  useEffect(() => {
    setPointFieldValue(currentPoint?.code)
  }, [currentPoint])

  const { data: points } = useDeliveryPoints({
    regionFias: fiases?.regionFias ?? null,
    areaFias: fiases?.areaFias ?? null,
    cityFias: fiases?.cityFias ?? null,
    settlementFias: fiases?.settlementFias ?? null,
    courierCode: tariff?.courierCode,
  })

  useEffect(() => {
    if (pointFieldValue) {
      const currentPoint = points?.find((p) => p.code === pointFieldValue)
      if (currentPoint) {
        setCurrentPoint(currentPoint)
      }
    }
  }, [])

  return (
    <div className={styles.wrapper}>
      <div className={styles.topPanel}>
        <h3>Пункт выдачи заказов</h3>
        <div className={styles.modeSelecting}>
          <Link active={mode === 'map'} onClick={() => setMode('map')}>
            Выбрать на карте
          </Link>
          <Link active={mode === 'manual'} onClick={() => setMode('manual')}>
            Ввести вручную
          </Link>
        </div>
      </div>

      {mode === 'map' ? (
        <PvzMap
          currentPoint={currentPoint}
          points={points}
          tariff={tariff}
          setCurrentPoint={setCurrentPoint}
        />
      ) : (
        <PvzManual
          pointFieldValue={pointFieldValue}
          tariff={tariff}
          setCurrentPoint={setCurrentPoint}
        />
      )}

      {currentPoint ? (
        <SelectedPvzData currentPoint={currentPoint} tariff={tariff} />
      ) : null}
    </div>
  )
}

export default PvzBlock
