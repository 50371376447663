import {
  Order,
  OrderProduct,
  OrderProductAmountState,
  OrderProductAmountStateKey,
  ProductStatusObj,
} from 'src/interfaces'

const checkOnlyOrderedStatus = (state: OrderProductAmountState) =>
  !Object.keys(state).some(
    (key: string) =>
      key !== 'amountORDERED' &&
      state[key as keyof OrderProductAmountState] !== 0,
  )
const checkOnlyReservedStatus = (state: OrderProductAmountState) =>
  !Object.keys(state).some(
    (key) =>
      key !== 'amountORDERED' &&
      key !== 'amountRESERVED' &&
      state[key as keyof OrderProductAmountState] !== 0,
  )
const checkOnlyPackedStatus = (state: OrderProductAmountState) =>
  !Object.keys(state).some(
    (key) =>
      key !== 'amountORDERED' &&
      key !== 'amountRESERVED' &&
      key !== 'amountPACKED' &&
      state[key as keyof OrderProductAmountState] !== 0,
  )
const checkOnlyShippedStatus = (state: OrderProductAmountState) =>
  !Object.keys(state).some(
    (key) =>
      key !== 'amountORDERED' &&
      key !== 'amountRESERVED' &&
      key !== 'amountPACKED' &&
      key !== 'amountSHIPPED' &&
      state[key as keyof OrderProductAmountState] !== 0,
  )

const getItemStatus = (item: OrderProduct, isAnyDelivered: boolean) => {
  const getData = () => {
    const state = item.itemAmountState
    if (!state) return { data: item }

    const {
      amountDELIVERED,
      amountORDERED,
      amountPACKED,
      amountRESERVED,
      amountRETURNED,
      amountSHIPPED,
      amountRETURNED_DEFECTIVE,
    } = state

    if (
      amountDELIVERED > amountORDERED ||
      amountPACKED > amountORDERED ||
      amountSHIPPED > amountORDERED ||
      amountRETURNED > amountORDERED ||
      amountRESERVED > amountORDERED
    ) {
      return { title: 'ошибка', style: 'red', data: item }
    }

    if (isAnyDelivered) {
      if (amountDELIVERED === amountORDERED) {
        return { title: 'доставлено', style: 'green', data: item }
      }
      if (amountDELIVERED < amountORDERED) {
        const cancelCount = amountORDERED - amountDELIVERED - amountRETURNED
        if (cancelCount === amountORDERED) {
          return {
            title: 'отказ',
            style: 'red',
            sum: cancelCount * item.price,
            minusSum: true,
            data: item,
          }
        }
        const resultArr = [
          {
            title: 'доставлено',
            style: 'green',
            sum: amountDELIVERED * item.price,
            text: `Продано: ${amountDELIVERED} шт`,
            data: item,
          },
          {
            title: 'отказ',
            style: 'red',
            sum: cancelCount * item.price,
            text: `Отказ: ${cancelCount} шт`,
            minusSum: true,
            data: item,
          },
        ]
        if (amountRETURNED) {
          resultArr.push({
            title: 'возвращено',
            style: 'gray',
            sum: amountRETURNED * item.price,
            text: `Возврат: ${amountRETURNED} шт`,
            minusSum: true,
            data: item,
          })
        }
        return resultArr
      }
    }

    if (amountORDERED > 0 && checkOnlyOrderedStatus(item.itemAmountState)) {
      return { title: 'нет резерва', style: 'orange', data: item }
    }

    if (amountRESERVED > 0 && checkOnlyReservedStatus(item.itemAmountState)) {
      if (amountRESERVED === amountORDERED) {
        return { title: 'в резерве', style: 'green', data: item }
      } else {
        return [
          {
            title: 'в резерве',
            style: 'green',
            text: `Резерв: ${amountRESERVED} шт`,
            data: item,
          },
          {
            title: 'нет резерва',
            style: 'orange',
            text: `Без резерва: ${amountORDERED - amountRESERVED} шт`,
            data: item,
          },
        ]
      }
    }

    if (amountPACKED > 0 && checkOnlyPackedStatus(state)) {
      if (amountPACKED === amountORDERED) {
        return { title: 'упаковано', style: 'green', data: item }
      } else {
        return [
          {
            title: 'упаковано',
            style: 'green',
            text: `Упаковано: ${amountPACKED} шт`,
            data: item,
          },
          {
            title: 'не упаковано',
            style: 'orange',
            text: `Не упаковано: ${amountORDERED - amountPACKED} шт`,
            data: item,
          },
        ]
      }
    }

    if (amountSHIPPED > 0 && checkOnlyShippedStatus(state)) {
      if (amountSHIPPED === amountORDERED) {
        return { title: 'отгружено', style: 'green', data: item }
      } else {
        return [
          {
            title: 'отгружено',
            style: 'green',
            text: `Отгружено: ${amountSHIPPED} шт`,
            data: item,
          },
          {
            title: 'не отгружено',
            style: 'orange',
            text: `Не отгружено: ${amountORDERED - amountSHIPPED} шт`,
            data: item,
          },
        ]
      }
    }

    if (amountRETURNED_DEFECTIVE) {
      return {
        title: 'возвращён брак',
        style: 'red',
        data: item,
        minusSum: true,
      }
    }

    if (amountRETURNED) {
      return {
        title: 'возвращён',
        style: 'red',
        sum: amountRETURNED * item.price,
        text: `Возврат: ${amountRETURNED} шт`,
        minusSum: true,
        data: item,
      }
    }

    return { data: item }
  }
  return getData()
}

export const getItemsStatuses = (
  products: OrderProduct[],
  status: string | number,
) => {
  const filteredData = products.filter((item) => item.variantId !== 'delivery')
  const isAnyDelivered = filteredData.some(
    (item) => item.itemAmountState.amountDELIVERED > 0,
  )

  if (
    isAnyDelivered &&
    ![
      'ORDER_INFO_DELIVERED',
      'ORDER_INFO_PARTIALLY_DELIVERED_NOT_RETURNED',
      'ORDER_INFO_PARTIALLY_DELIVERED_RETURNED',
      'ORDER_INFO_DONE',
    ].includes(status as string)
  ) {
    return filteredData.map((data) => ({ title: 'ошибка', style: 'red', data }))
  }

  if (status === 'ORDER_INFO_CANCELED') {
    return filteredData.map((data) => ({ data }))
  }

  let resultArr: (ProductStatusObj | ProductStatusObj[])[] = []
  filteredData.forEach((product) => {
    const currentItemStatus:
      | ProductStatusObj
      | ProductStatusObj[] = getItemStatus(product, isAnyDelivered)
    resultArr.push(currentItemStatus)
  })

  return resultArr
}
