import React, { useState } from 'react'

import styles from './select-count.module.scss'

import { Input, Icons } from 'src/components'

interface SelectCountProps {
  value?: number
  onChange: (value: number) => void
}

const SelectCount = (props: SelectCountProps) => {
  const { value, onChange } = props

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(Number(event.currentTarget.value))
  }

  return (
    <div className={styles.wrapper}>
      {typeof value === 'undefined' ? (
        <div className={styles.plus} onClick={() => onChange(0)}>
          <Icons.Plus />
        </div>
      ) : (
        <div className={styles.select}>
          <Icons.Minus onClick={() => onChange(value > 0 ? value - 1 : 0)} />
          <Input
            inputClassName={styles.input}
            withoutError
            template="small"
            value={value}
            onChange={handleInputChange}
          />
          <Icons.Plus onClick={() => onChange(value + 1)} />
        </div>
      )}
    </div>
  )
}

export default SelectCount
