import React, { InputHTMLAttributes } from 'react'
import cx from 'classnames'
import { ReactComponent as Check } from './assets/check.svg'
import styles from './checkbox.module.scss'

type Props = InputHTMLAttributes<HTMLInputElement> & {
  indeterminate?: boolean
  label?: string
}

const Checkbox = (props: Props) => {
  const { indeterminate, className, label, ...rest } = props

  return (
    <label className={cx(styles.wrapper, className)}>
      <input {...rest} className={styles.checkbox} type="checkbox" />
      <span
        className={cx(
          styles.icon,
          indeterminate && styles.indeterminate,
          props.disabled && styles.disabled,
        )}
      >
        <Check className={styles.check} />
      </span>

      {label ? <span className={styles.label}>{label}</span> : null}
    </label>
  )
}

export default Checkbox
