import React from 'react'
import { Link } from 'react-router-dom'

import { PATHS } from 'src/config'

import styles from './recovery.module.scss'

const RecoveryPage = () => {
  return (
    <div className={styles.wrapper}>
      <img alt="" className={styles.logo} src="/logo.svg" />

      <Link to={PATHS.LOGIN}>Вернуться назад</Link>
    </div>
  )
}

export default RecoveryPage
