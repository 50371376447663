import axios, { AxiosResponse } from 'axios'
import { API_BASE_URL, API_DLV_URL, API_PUBLIC_KAK2C_URL } from 'src/config'
import appLocalStorage from 'src/libs/localStorage'

declare module 'axios' {
  export interface AxiosRequestConfig {
    source?: string
    errorCallback?: (error: string) => string
  }
}

declare global {
  interface Window {
    showNotification: any
  }
}

interface ResError {
  code: number
  message: string
}

const token = appLocalStorage.token

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Authorization: token && `Bearer ${token}`,
  },
})

api.interceptors.request.use((config) => {
  switch (config.source) {
    case 'kak2c':
      config.baseURL = API_PUBLIC_KAK2C_URL
      delete config.headers.Authorization
      config.headers.Domain = 'shop3732349' //TODO Remove
      break

    case 'dlv':
      config.baseURL = API_DLV_URL
      config.headers.Domain = 'core'
      break
  }

  return config
})

api.interceptors.response.use(
  (res: AxiosResponse) => {
    if (res.data.success === false && res.data.errors) {
      res.data.errors.forEach((e: ResError) => {
        window.showNotification(
          'error',
          res.config.errorCallback
            ? res.config.errorCallback(e.message)
            : e.message,
        )
      })
    }
    return res
  },
  async (error) => {
    if (
      error.response?.status === 401 &&
      error.response.config.url !== `v1/auth` &&
      error.response.config.url !== `v1/auth/refresh`
    ) {
      try {
        const { data } = await api.post(`v1/auth/refresh`, null, {
          headers: { Authorization: `Bearer ${token}` },
        })
        appLocalStorage.token = data.refresh_token
        api.defaults.headers.Authorization = `Bearer ${data.access_token}`
        error.config.headers.Authorization = `Bearer ${data.access_token}`
        return await api(error.config)
      } catch (error) {
        console.error(error)
        appLocalStorage.clear()
        window.location.reload()
      }
    }
  },
)

export default api
