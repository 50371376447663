import React, { useState, useCallback } from 'react'
import cx from 'classnames'

import styles from './add.module.scss'

interface AddType {
  onChange: (value: string) => void
}

const Add: React.FC<AddType> = (props) => {
  const { onChange } = props

  const inputRef = useCallback((node) => {
    if (node !== null) {
      node.focus()
    }
  }, [])

  const [isActive, setIsActive] = useState(false)
  const [value, setValue] = useState('')

  const handleButtonClick = () => {
    if (!isActive) {
      setIsActive(true)
    } else {
      if (value) {
        onChange(value)
        setIsActive(false)
        setValue('')
      }
    }
  }

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleButtonClick()
    }
  }

  return (
    <div className={cx(styles.wrapper, isActive ? styles.active : null)}>
      {isActive ? (
        <input
          ref={inputRef}
          value={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setValue(e.target.value)
          }
          onKeyPress={handleKeyPress}
        />
      ) : null}
      <div className={styles.button} onClick={handleButtonClick}>
        +
      </div>
    </div>
  )
}

export default Add
