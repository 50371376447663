import React from 'react'

import Sidebar from './Sidebar'
import Header from './Header'
import { Icons } from 'src/components'

import { PRIVATE_PATHS } from 'src/config'

import styles from './private-layout.module.scss'

const Layout: React.FC = (props) => {
  const { children } = props

  const MENU_ITEMS = [
    [
      {
        Icon: Icons.CloudCheck,
        path: PRIVATE_PATHS.MODERATION,
        title: 'Модерация',
      },
      {
        Icon: Icons.Box,
        path: PRIVATE_PATHS.ORDERS,
        title: 'Заказы',
      },
    ],
  ]

  return (
    <div className={styles.wrapper}>
      <Sidebar menuItems={MENU_ITEMS} />
      <div className={styles.inner}>
        <Header menuItems={MENU_ITEMS} />
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  )
}

export default Layout
