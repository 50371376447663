import {
  DecodedValueMap,
  QueryParamConfigMap,
  SetQuery,
} from 'use-query-params'

export const updateQuery = (
  query: DecodedValueMap<QueryParamConfigMap>,
  setQuery: SetQuery<QueryParamConfigMap>,
  newData: Object,
) => {
  setQuery(
    {
      ...query,
      ...newData,
    },
    'replace',
  )
}

export const setQueryFieldValue = (
  query: DecodedValueMap<QueryParamConfigMap>,
  setQuery: SetQuery<QueryParamConfigMap>,
  key: string,
  value?: string | number | null,
) => {
  updateQuery(query, setQuery, {
    [key]: value,
  })
}

export const handleChangePagination = (
  query: DecodedValueMap<QueryParamConfigMap>,
  setQuery: SetQuery<QueryParamConfigMap>,
  page: number,
  size: number,
) => {
  updateQuery(query, setQuery, {
    page: page - 1,
    size,
  })
}
