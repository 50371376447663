import React, { useState } from 'react'
import dayjs from 'dayjs'
import cx from 'classnames'

import styles from './order-history.module.scss'

import { useOrderHistory } from 'src/hooks'

import { OrderHistoryEvent } from 'src/interfaces'

const MOCK_HISTORY_EVENTS: OrderHistoryEvent[] = [
  {
    id: '2ebbb234-156e-2362-2dad-79556ea3ee14',
    subType: 'Изменения заказа',
    orderDocStage: 'Новый',
    eventDate: '2020-01-15 11:57:05.159',
    description:
      'Заказ 1285-1000. Покупатель: Ываыва; тел.: +7 (900) 111-11-14\n\nДобавлена строка: #2. [1002] Кактус Рипсалис, 3 шт., Цена с НДС: 22 RUB, НДС: 20%, Сумма с НДС: 66 RUB. \n\nСпособ оплаты: Без наложенного платежа; Статус оплаты: Оплачен\nАдрес доставки: Москва, ул Озёрная, д 31 Канал доставки: CUSTOM\n',
    changeSource: 'USER',
    userLogin: 'test@test.ru',
  },
  {
    id: 'bf6e88a8-98bc-2f28-9973-bc870e8f1b67',
    subType: 'Заказ ожидает подтверждения',
    orderDocStage: 'Не подтвержден',
    eventDate: '2020-01-15 11:57:05.298',
    description: 'Заказ 1285-1000. ',
    changeSource: 'USER',
    userLogin: 'test@test.ru',
  },
  {
    id: '3ebbb234-156e-2362-2dad-79556ea3ee14',
    subType: 'Изменения заказа',
    orderDocStage: 'Новый',
    eventDate: '2020-01-15 11:57:05.159',
    description:
      'Заказ 1285-1000. Покупатель: Ываыва; тел.: +7 (900) 111-11-14\n\nДобавлена строка: #2. [1002] Кактус Рипсалис, 3 шт., Цена с НДС: 22 RUB, НДС: 20%, Сумма с НДС: 66 RUB. \n\nСпособ оплаты: Без наложенного платежа; Статус оплаты: Оплачен\nАдрес доставки: Москва, ул Озёрная, д 31 Канал доставки: CUSTOM\n',
    changeSource: 'USER',
    userLogin: 'test@test.ru',
  },
  {
    id: '4f6e88a8-98bc-2f28-9973-bc870e8f1b67',
    subType: 'Заказ ожидает подтверждения',
    orderDocStage: 'Не подтвержден',
    eventDate: '2020-01-15 11:57:05.298',
    description: 'Заказ 1285-1000. ',
    changeSource: 'USER',
    userLogin: 'test@test.ru',
  },
  {
    id: '2ebbb234-156e-2362-2dad-79556ea3ee14',
    subType: 'Изменения заказа',
    orderDocStage: 'Новый',
    eventDate: '2020-01-15 11:57:05.159',
    description:
      'Заказ 1285-1000. Покупатель: Ываыва; тел.: +7 (900) 111-11-14\n\nДобавлена строка: #2. [1002] Кактус Рипсалис, 3 шт., Цена с НДС: 22 RUB, НДС: 20%, Сумма с НДС: 66 RUB. \n\nСпособ оплаты: Без наложенного платежа; Статус оплаты: Оплачен\nАдрес доставки: Москва, ул Озёрная, д 31 Канал доставки: CUSTOM\n',
    changeSource: 'USER',
    userLogin: 'test@test.ru',
  },
  {
    id: 'vf6e88a8-98bc-2f28-9973-bc870e8f1b67',
    subType: 'Заказ ожидает подтверждения',
    orderDocStage: 'Не подтвержден',
    eventDate: '2020-01-15 11:57:05.298',
    description: 'Заказ 1285-1000. ',
    changeSource: 'USER',
    userLogin: 'test@test.ru',
  },
]

type HistoryEventProps = OrderHistoryEvent & {
  fullMode: boolean
}

const HistoryEvent = (props: HistoryEventProps) => {
  const {
    fullMode,
    eventDate,
    orderDocStage,
    subType,
    changeSource,
    userLogin,
    description,
  } = props

  return (
    <div className={cx(fullMode && styles.fullMode)}>
      <div className={styles.orderEventDate}>
        {dayjs(eventDate).format('DD MMM YYYY HH:mm:ss')}
      </div>

      <div className={styles.orderEventStatus}>{orderDocStage}</div>

      <div className={styles.orderEventText}>
        <div className={styles.orderEventSubtype}>{subType}</div>

        {changeSource === `Пользователь` ? (
          <div>{`Изменено: ${userLogin}`}</div>
        ) : null}

        {description ? (
          <div className={styles.orderEventDescription}>{description}</div>
        ) : null}
      </div>
    </div>
  )
}

interface OrderHistoryProps {
  orderId: string
}

const OrderHistory = (props: OrderHistoryProps) => {
  const { orderId } = props

  const {
    data: { events: historyEvents },
  } = useOrderHistory({ id: orderId })

  const [fullMode, setFullState] = useState(false)

  // if (!historyEvents.length) return null

  const filteredData = fullMode
    ? MOCK_HISTORY_EVENTS.slice().reverse()
    : MOCK_HISTORY_EVENTS.slice().reverse().slice(0, 5)

  return (
    <div className={styles.wrapper}>
      <h2>История заказа</h2>

      <div className={styles.eventsWrapper}>
        {filteredData.map((historyEvent) => (
          <HistoryEvent
            key={historyEvent.id}
            fullMode={fullMode}
            {...historyEvent}
          />
        ))}
      </div>

      {!fullMode ? (
        <div className={styles.moreWrapper}>
          <div onClick={() => setFullState(true)}>Полная история</div>
        </div>
      ) : null}
    </div>
  )
}

export default OrderHistory
