import React, { useState } from 'react'
import cx from 'classnames'

import styles from './category-select.module.scss'

import ValueItem from '../ValueItem'
import Modal from './Modal'

import { Icons, Tooltip } from 'src/components'

import { useCategories } from 'src/hooks/products'

import { findCurrentCategory } from 'src/helpers'

import { TreeOptionType } from 'src/interfaces'

interface CategorySelectType {
  required?: boolean
  disabled?: boolean
  id: string
  className?: string
  placeholder?: string
  value?: string
  label?: string
  error?: string
  tooltip?: string
  tooltipSide?: 'top' | 'right'
  onChange: (option?: string) => void
}

const CategorySelect: React.FC<CategorySelectType> = (props) => {
  const {
    required,
    disabled,
    id,
    label,
    className,
    value,
    placeholder,
    tooltip,
    tooltipSide,
    error,
    onChange,
  } = props

  const { data: { categories } = { categories: [] } } = useCategories()

  const [showModal, setShowModal] = useState(false)

  const handleInputClick = () => {
    if (!disabled) {
      setShowModal(true)
    }
  }

  const handleSelectCategory = (option: TreeOptionType) => {
    onChange(option.guid)
  }

  const handleRemoveCategory = (event: React.MouseEvent) => {
    event.stopPropagation()
    onChange(undefined)
  }

  const currentCategory = findCurrentCategory({
    items: categories,
    guid: value,
  })
  return (
    <>
      <div className={cx(className, styles.wrapper)}>
        {label ? (
          <label htmlFor={id}>
            {label}
            {required ? <span className="required">*</span> : null}
          </label>
        ) : null}

        <div className={styles.inputWrapper}>
          <div
            id={id}
            className={cx(
              styles.input,
              error && styles.inputError,
              disabled && styles.disabled,
            )}
            onClick={handleInputClick}
          >
            {placeholder && !currentCategory ? (
              <span className={styles.placeholder}>{placeholder}</span>
            ) : null}
            {currentCategory ? (
              <>
                <ValueItem
                  {...currentCategory}
                  disabled={disabled}
                  onRemove={handleRemoveCategory}
                />
                {!disabled ? (
                  <div className={styles.close} onClick={handleRemoveCategory}>
                    <Icons.CircleClose />
                  </div>
                ) : null}
              </>
            ) : null}
          </div>
          {tooltip ? (
            <Tooltip
              className={styles.tooltip}
              content={tooltip}
              side={tooltipSide}
            />
          ) : null}
        </div>
        <div className={styles.errorText}>{error}</div>
      </div>
      {showModal ? (
        <Modal
          id={id}
          categories={categories}
          onSubmit={handleSelectCategory}
          onClose={() => setShowModal(false)}
        />
      ) : null}
    </>
  )
}

export default CategorySelect
