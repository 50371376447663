import React from 'react'
import { useFormik } from 'formik'
import styles from './order-comments-modal.module.scss'

import { Textarea, Button, Modal } from 'src/components'

interface OrderCommentsModalProps {
  // onSubmit: (image: ProductCreatingImage) => void
  onClose: () => void
}

const OrderReceiverModal = (props: OrderCommentsModalProps) => {
  const { onClose } = props

  const { values, errors, submitCount, handleChange, handleSubmit } = useFormik(
    {
      initialValues: {
        comment: '',
        operatorComment: '',
      },
      onSubmit: () => {
        onClose()
      },
    },
  )

  return (
    <Modal id="commentsModal" title="Комментарии" onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <Textarea
          id="comment"
          label="Комментарий"
          value={values.comment}
          onChange={handleChange}
        />

        <Textarea
          id="operatorComment"
          label="Комментарий оператору склада"
          value={values.operatorComment}
          onChange={handleChange}
        />

        <Button type="submit" className={styles.button} size="large">
          Сохранить
        </Button>
      </form>
    </Modal>
  )
}

export default OrderReceiverModal
