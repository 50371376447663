import { ORDER_STATUSES } from 'src/config'

export interface OrderReceiver {
  name: string
  surname: string
  patronymic?: string
  fullName: string
  phone?: string
}

export interface OrderDelivery {
  code: string
  type: 'COURIER' | 'PVZ' | 'RUSSIAN_POST'
  title: string
  logoUrl: string
  deliveryComment?: string
  actualDeliveryDate?: string
  address: {
    city?: string
    cityFias?: string
    settlementFias?: string
    country: string
    fullAddress: string
    zip?: string
  }
  receiver: OrderReceiver
}

export interface OrderProductAmountState {
  amountORDERED: number
  amountRESERVED: number
  amountPACKED: number
  amountSHIPPED: number
  amountDELIVERED: number
  amountLOST: number
  amountRETURNED: number
  amountRETURNED_DEFECTIVE: number
}

export type OrderProductAmountStateKey =
  | 'amountORDERED'
  | 'amountRESERVED'
  | 'amountPACKED'
  | 'amountSHIPPED'
  | 'amountDELIVERED'
  | 'amountLOST'
  | 'amountRETURNED'
  | 'amountRETURNED_DEFECTIVE'

export interface OrderProduct {
  num: number
  productId?: string
  variantId: string
  variantExtId?: string
  name: string
  article: string
  quantity: number
  quantityAvailable: number
  vatRate: 'NO_VAT' | 'VAT_0' | 'VAT_10' | 'VAT_18' | 'VAT_20'
  vatSkuProduct?: 'NO_VAT' | 'VAT_0' | 'VAT_10' | 'VAT_18' | 'VAT_20'
  price: number
  img: string
  itemAmountState: OrderProductAmountState
  skuType: 'SKU' | 'SKUSET' | 'SERVICE'
  itemDimensions: {
    height: number
    width: number
    depth: number
  }
  itemWeight: number
}

export interface OrderPacking {
  barcode: string
  measurements: {
    weight: number
    dimensions: {
      height: number
      width: number
      depth: number
    }
  }
  items: OrderProduct[]
}

export interface Order {
  id: string
  date: string
  status: keyof typeof ORDER_STATUSES
  totalOrderSum: number
  insuranceSum: number
  insuranceSumAutoCalculation: boolean
  paymentStatus: 'NOT_PAID' | 'PAID'
  paymentMethodType:
    | 'COD_CASH'
    | 'COD_CARD'
    | 'ONLINE'
    | 'NO_COD'
    | 'NO_COD_NO_CONTROL'
  paymentMethodName: string
  delivery: OrderDelivery
  items: OrderProduct[]
  codPayments: {
    sum: number
    date: string
    paymentDocumentId: string
  }[]
  comment?: string
  packings?: OrderPacking[]
}

export interface ProductStatusObj {
  title?: string
  style?: string
  sum?: number
  text?: string
  minusSum?: boolean
  data: OrderProduct
}

export interface OrderHistoryEvent {
  id: string
  eventDate: string
  orderDocStage: string
  subType: string
  changeSource: string
  userLogin: string
  description?: string
}

export enum OrdersStatuses {
  ORDER_INFO_CONFIRM_HOLD = 'ORDER_INFO_CONFIRM_HOLD',
  ORDER_INFO_DRAFT = 'ORDER_INFO_DRAFT',
  ORDER_INFO_INSUFFICIENT_RESERVE = 'ORDER_INFO_INSUFFICIENT_RESERVE',
  ORDER_INFO_IN_PACKING = 'ORDER_INFO_IN_PACKING',
  ORDER_INFO_NEED_CONFIRM = 'ORDER_INFO_NEED_CONFIRM',
  ORDER_INFO_PACKED = 'ORDER_INFO_PACKED',
  ORDER_INFO_READY_TO_PACK = 'ORDER_INFO_READY_TO_PACK',
  ORDER_INFO_REQUIRE_PAYMENT = 'ORDER_INFO_REQUIRE_PAYMENT',
  ORDER_INFO_SHIPPED = 'ORDER_INFO_SHIPPED',
  ORDER_INFO_COURIER = 'ORDER_INFO_COURIER',
  ORDER_INFO_PARTIALLY_DELIVERED_NOT_RETURNED = 'ORDER_INFO_PARTIALLY_DELIVERED_NOT_RETURNED',
  ORDER_INFO_AT_TAKEOUT_POINT = 'ORDER_INFO_AT_TAKEOUT_POINT',
  ORDER_INFO_SORT_CENTER_SHIPPED = 'ORDER_INFO_SORT_CENTER_SHIPPED',
  ORDER_INFO_SORT_CENTER_ARRIVED = 'ORDER_INFO_SORT_CENTER_ARRIVED',
  ORDER_INFO_RETURNING = 'ORDER_INFO_RETURNING',
  ORDER_INFO_SORT_CENTER_RETURNED = 'ORDER_INFO_SORT_CENTER_RETURNED',
  ORDER_INFO_SORT_CENTER_RETURNED_AND_SHIPPED = 'ORDER_INFO_SORT_CENTER_RETURNED_AND_SHIPPED',
  ORDER_INFO_SORT_CENTER_PARTIALLY_RETURNED = 'ORDER_INFO_SORT_CENTER_PARTIALLY_RETURNED',
  ORDER_INFO_SORT_CENTER_PARTIALLY_RETURNED_AND_SHIPPED = 'ORDER_INFO_SORT_CENTER_PARTIALLY_RETURNED_AND_SHIPPED',
  ORDER_INFO_SORT_CENTER_PARTIALLY_RETURNED_AND_DELIVERED = 'ORDER_INFO_SORT_CENTER_PARTIALLY_RETURNED_AND_DELIVERED',
  ORDER_INFO_RETURNED_TO_CLIENT = 'ORDER_INFO_RETURNED_TO_CLIENT',
  ORDER_ERROR = 'ORDER_ERROR',
}

export interface OrdersStatusesRes {
  [key: string]: number
}
