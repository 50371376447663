import React from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'

import { Icons } from 'src/components'

import styles from './sidebar.module.scss'

import { MenuItemType } from 'src/interfaces'

interface SidebarMenuItemType {
  children?: React.ReactNode
  className?: string
  Icon?: React.FunctionComponent<React.SVGAttributes<SVGElement>>
  path?: string
  title?: string
}

const MenuItem: React.FC<SidebarMenuItemType> = (props) => {
  const { children, className, Icon, path, title } = props

  const component = (
    <>
      {children ? children : Icon ? <Icon /> : null}
      {title ? <div className={styles.menuItemTitle}>{title}</div> : null}
    </>
  )

  return path ? (
    <Link to={path} className={cx(styles.menuItem, className || null)}>
      {component}
    </Link>
  ) : (
    <div className={cx(styles.menuItem, className || null)}>{component}</div>
  )
}

const MenuBlock = (props: { children: React.ReactNode }) => {
  const { children } = props

  return (
    <div className={styles.menuBlock}>
      <div className={styles.separator} />
      {children}
    </div>
  )
}

const Notification = () => {
  return (
    <MenuItem className={styles.notification}>
      <span className={styles.notificationCircle} />
      <Icons.Bell />
    </MenuItem>
  )
}

interface SidebarType {
  menuItems: MenuItemType[][]
}

const Sidebar: React.FC<SidebarType> = (props) => {
  const { menuItems } = props

  return (
    <div className={styles.wrapper}>
      <Notification />
      {menuItems.length ? (
        <>
          {menuItems.map((block, i) => (
            <MenuBlock key={i}>
              {block.map((item, j) => (
                <MenuItem key={`${i}-${j}`} {...item} />
              ))}
            </MenuBlock>
          ))}
        </>
      ) : null}
    </div>
  )
}

export default Sidebar
