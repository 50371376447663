import React from 'react'

import styles from './selected-pvz-data.module.scss'

import { Price } from 'src/components'

import { DeliveryPoint, Tariff } from 'src/ADMIN/interfaces'

interface SelectedPvzDataProps {
  currentPoint: DeliveryPoint
  tariff?: Tariff
}

const SelectedPvzData = (props: SelectedPvzDataProps) => {
  const { currentPoint, tariff } = props

  return (
    <div className={styles.wrapper}>
      <div style={{ display: 'flex' }}>
        <div className={styles.title}>
          {currentPoint.courier} ({currentPoint.code})
        </div>
        {tariff ? (
          <Price className={styles.price} value={tariff.price} />
        ) : null}
      </div>
      <div className={styles.address}>{currentPoint.address.fullAddress}</div>
      <div className={styles.worktime}>{currentPoint.workTime}</div>

      {currentPoint.description ? (
        <>
          <div className={styles.infoTitle}>Дополнительная информация</div>
          <div className={styles.description}>{currentPoint.description}</div>
        </>
      ) : null}
    </div>
  )
}

export default SelectedPvzData
