import React, { useState, useEffect } from 'react'
import { Link, generatePath } from 'react-router-dom'
import {
  useQueryParams,
  NumberParam,
  StringParam,
  withDefault,
  DecodedValueMap,
  QueryParamConfigMap,
} from 'use-query-params'

import styles from './moderation.module.scss'

import ModerationTabs, { MODERATION_PAGE_TABS } from './ModerationTabs'
import ModerationStatus, { ModerationStatusType } from './ModerationStatus'

import { Page, Table, SelectOnPage, Pagination } from 'src/components'

import { useCategories } from 'src/hooks/products'
import { useModerationTasks } from 'src/ADMIN/hooks'

import {
  getCategoryNameString,
  updateQuery,
  handleChangePagination,
} from 'src/helpers'

import { PRIVATE_PATHS } from 'src/config'

import { Category } from 'src/interfaces/category'

interface FilterPanelProps {
  page: number
  total: number
  size: number
  onChange: (size: number) => void
}

const FilterPanel = (props: FilterPanelProps) => {
  const { page, total, size, onChange } = props

  return (
    <div className={styles.filterPanel}>
      <Pagination.StateString {...props} />
      <SelectOnPage value={size} onChange={onChange} />
    </div>
  )
}

const ACTIONS = [] as any[] //TODO type

const ModerationParams = {
  page: withDefault(NumberParam, 0),
  size: withDefault(NumberParam, 10),
  tab: StringParam,
}

const getModerationStateInitialData = (tab?: string | null) => ({
  page: 0,
  size: 10,
  tab,
})

const getModerationState = (query: DecodedValueMap<QueryParamConfigMap>) => {
  const newState = { ...query }

  if (query.tab) {
    const currentTabData = MODERATION_PAGE_TABS.find((t) => t.id === query.tab)
    if (currentTabData) {
      if (currentTabData?.status) {
        newState.status = currentTabData.status
      }
    }
  }

  return newState
}

const Moderation = () => {
  const [query, setQuery] = useQueryParams(ModerationParams)

  const { data: { categories } = { categories: [] } as Category } =
    useCategories()

  const {
    data: { tasks, recordsTotal },
  } = useModerationTasks(getModerationState(query))

  const handleTabChange = (tab: string) => {
    updateQuery(query, setQuery, { tab, page: 0 })
  }

  const onPaginationChange = (page: number, size: number) =>
    handleChangePagination(query, setQuery, page, size)

  return (
    <Page title="Модерация">
      <ModerationTabs
        currentTab={query.tab || 'all'}
        onTabChange={handleTabChange}
      />

      <Table
        FilterPanel={() => (
          <FilterPanel
            page={query.page}
            total={recordsTotal}
            size={query.size || 0}
            onChange={(size) =>
              handleChangePagination(query, setQuery, query.page + 1, size)
            }
          />
        )}
        actions={ACTIONS}
      >
        {tasks.map((row) => (
          <Table.Row key={row.id}>
            <Table.Column title="ID заявки">
              {PRIVATE_PATHS.SINGLE_MODERATION ? ( //TODO remove admin
                <Link
                  className={styles.linkToModeration}
                  to={generatePath(PRIVATE_PATHS.SINGLE_MODERATION, {
                    moderationId: row.id,
                  })}
                >
                  {row.id}
                </Link>
              ) : null}
            </Table.Column>
            <Table.Column title="ID продукта">{row.productId}</Table.Column>
            <Table.Column title="ID селлера">{row.sellerId}</Table.Column>
            <Table.Column title="Статус">
              <ModerationStatus status={row.status as ModerationStatusType} />
            </Table.Column>
            <Table.Column title="Категория">
              {getCategoryNameString({
                items: categories,
                guid: row.categoryId,
              })}
            </Table.Column>
          </Table.Row>
        ))}
      </Table>

      <Pagination
        className={styles.pagination}
        current={query.page + 1 || 0}
        pageSize={query.size || 0}
        total={recordsTotal || 0}
        onChange={onPaginationChange}
      />
    </Page>
  )
}

export default Moderation
