import React, { ReactNode } from 'react'

import styles from './pagination-button.module.scss'

import { ReactComponent as ArrowLeft } from '../assets/arrow-left.svg'
import { ReactComponent as Ellipsis } from '../assets/ellipsis.svg'

interface Props {
  page?: number
  ellipsis?: boolean
  active?: boolean
  onChange?: (page: number) => void
  children?: ReactNode
  type?: 'switch'
  direction?: 'ascending' | 'descending'
}

const PaginationButton = (props: Props) => {
  const { ellipsis, active, onChange, page, children, type, direction } = props

  if (type === 'switch' && direction) {
    return (
      <button
        className={styles.button}
        onClick={() => onChange && page && onChange(page)}
      >
        {/* onClick={() => onChange && page && onChange(page)} */}
        <ArrowLeft
          className={
            direction === `descending` ? styles.leftArrow : styles.rightArrow
          }
        />
      </button>
    )
  }

  if (ellipsis) {
    return (
      <button disabled className={styles.button}>
        <Ellipsis />
      </button>
    )
  }

  return (
    <button
      key={page}
      className={active ? styles.activeButton : styles.button}
      onClick={() => onChange && page && onChange(page)}
    >
      {page || children}
    </button>
  )
}

export default PaginationButton
