import React, { useState } from 'react'
import cx from 'classnames'

import styles from './plus-select.module.scss'

import { IOption } from 'src/interfaces'

interface PlusSelectType {
  label: string
  className?: string
  options: IOption[]
  onSelect: (option: IOption) => void
}

const PlusSelect: React.FC<PlusSelectType> = (props) => {
  const { label, className = '', options, onSelect } = props

  const [isOpen, setIsOpen] = useState(false)

  const handleOptionSelect = (option: IOption) => {
    if (!option.disabled) {
      onSelect(option)
      setIsOpen(false)
    }
  }

  return (
    <div className={cx(styles.wrapper, className)}>
      <div className={styles.label} onClick={() => setIsOpen(!isOpen)}>
        {`${label} +`}
      </div>
      {isOpen ? (
        <div className={styles.options}>
          {options.map((o) => (
            <div
              key={o.value}
              className={cx(styles.option, o.disabled && styles.disabled)}
              onClick={() => handleOptionSelect(o)}
            >
              {o.label}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  )
}

export default PlusSelect
