import React, { useEffect, useState } from 'react'
import cx from 'classnames'

import styles from './file-item.module.scss'

import { Icons } from 'src/components'

interface Props {
  className?: string
  active?: boolean
  url?: string
  file?: File
  size?: 'normal' | 'small'
  template?: 'standart' | 'gallery'
  onClick?: () => void
  handleRemove?: () => void
}

const FileItem = (props: Props) => {
  const {
    className,
    active,
    url,
    file,
    size = 'normal',
    template = 'standart',
    onClick = () => false,
    handleRemove,
  } = props

  const [src, setSrc] = useState<string>()

  useEffect(() => {
    if (url) {
      setSrc(url)
    } else {
      if (file) {
        const reader = new FileReader()
        reader.addEventListener(`load`, () => setSrc(reader.result as string))
        reader.readAsDataURL(file)
      }
    }
  }, [file, url])

  return (
    <div
      className={cx(
        styles.wrapper,
        styles[size],
        styles[template],
        active && styles.active,
        className,
      )}
      onClick={onClick}
    >
      <img alt="" className={styles.image} src={src} />
      <div className={styles.shadow}>
        {handleRemove ? <Icons.Trash onClick={handleRemove} /> : null}
      </div>
    </div>
  )
}

export default FileItem
