import { StringParam, NumberParam, withDefault } from 'use-query-params'

export interface OrderFilterValues {
  filter_multi?: string | null
  date_from?: string | null
  date_to?: string | null
  filter_states?: string | null
  filter_phone?: string | null
  filter_tracking_number?: string | null
  city?: string | null
  filter_promocode?: string | null
  product_id?: string | null
  article?: string | null
  barcode?: string | null
  page?: number | null
  size?: number | null
}

export const OrderFilterParams = {
  filter_multi: StringParam,
  date_from: StringParam,
  date_to: StringParam,
  filter_states: StringParam,
  filter_phone: StringParam,
  filter_tracking_number: StringParam,
  city: StringParam,
  filter_promocode: StringParam,
  product_id: StringParam,
  article: StringParam,
  barcode: StringParam,
  page: withDefault(NumberParam, 0),
  size: withDefault(NumberParam, 10),
  tab: StringParam,
}
