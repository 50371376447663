import React from 'react'

import styles from './nomenclature.module.scss'

import { ImageFromStore } from 'src/components'

import { NomenclatureProduct } from 'src/interfaces'

interface NomenclatureProps {
  product: NomenclatureProduct
}

const Nomenclature = (props: NomenclatureProps) => {
  const { product } = props

  return (
    <div className={styles.wrapper}>
      <div className={styles.image}>
        {product.variantId !== 'delivery' ? (
          <ImageFromStore url={product.img} size="small" />
        ) : null}
      </div>
      <div className={styles.data}>
        <div>{product.name}</div>

        {product.article ? (
          <div className={styles.subname}>{`Арт: ${product.article}`}</div>
        ) : null}

        <div className={styles.subname}>
          {`${product.variantId || product.productId || ''} ${
            product.variantExtId ? '(' + product.variantExtId + ')' : ''
          }`}
        </div>
      </div>
    </div>
  )
}

export default Nomenclature
