import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useNotifications } from 'src/notification'

import styles from './single-product.module.scss'

import { Button, Icons } from 'src/components'

import {
  useArchiveProduct,
  useSendProductToModeration,
} from 'src/hooks/products'

import { reformatProductDataBeforeSaving } from 'src/helpers'

import { PATHS } from 'src/config'

import { ProductCreatingValues, ProductSaleStatus } from 'src/interfaces'

interface HeaderButtonsProps {
  values: ProductCreatingValues
  productId: string
  productStatus?: ProductSaleStatus
  handleSave: () => Promise<any>
}

const HeaderButtons = (props: HeaderButtonsProps) => {
  const { values, productId, productStatus, handleSave } = props

  const history = useHistory()
  const notifications = useNotifications()

  const archiveMutation = useArchiveProduct()
  const moderationMutation = useSendProductToModeration()

  const handleArchive = async () => {
    archiveMutation.mutate(reformatProductDataBeforeSaving(values, productId), {
      onSuccess: () => {
        if (PATHS.PRODUCTS) {
          //TODO remove admin
          notifications?.show('success', 'Товар успешно отправлен в архив')
          history.push(PATHS.PRODUCTS)
        }
      },
    })
  }

  const handleModerate = async () => {
    moderationMutation.mutate(
      { productId },
      {
        onSuccess: () => {
          notifications?.show('success', 'Товар успешно отправлен на модерацию')
        },
      },
    )
  }

  if (!productStatus) return null

  return (
    <>
      <Button
        className={styles.moderationButton}
        template="secondary"
        loading={moderationMutation.isLoading}
        icon={<Icons.CloudCheck />}
        onClick={handleModerate}
      >
        Отправить на модерацию
      </Button>

      <Button
        template="secondary"
        loading={archiveMutation.isLoading}
        disabled={Boolean(
          productStatus === 'DECLINED' || productStatus === 'ARCHIVE',
        )}
        icon={<Icons.Archive />}
        onClick={handleArchive}
      >
        Отправить в архив
      </Button>
    </>
  )
}

export default HeaderButtons
