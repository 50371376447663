import React, { useState, useEffect } from 'react'

import { Input } from 'src/components'

import { InputProps } from 'src/components/Form/Input'

import { getPhoneWithMask } from 'src/helpers'

const PhoneInput = (props: InputProps) => {
  const { id, value, onChange } = props

  const [currentValue, setCurrentValue] = useState('')

  useEffect(() => {
    if (!(!value && !currentValue)) {
      setCurrentValue(getPhoneWithMask(String(value)))
    }
  }, [value])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = ''

    if (!currentValue && event.currentTarget!.value === '+') {
      newValue = '+'
    } else {
      newValue = `${
        event.currentTarget!.value.replace(/\+|\(|\)|\-|\s/gi, '') || ''
      }`
    }

    if (onChange) {
      onChange({
        currentTarget: { id, value: newValue } as EventTarget &
          HTMLInputElement,
      } as React.ChangeEvent<HTMLInputElement>)
    }
  }

  return <Input {...props} value={currentValue} onChange={handleChange} />
}

export default PhoneInput
