import React from 'react'
import dayjs from 'dayjs'

import { Badge } from 'src/components'

import { ORDER_STATUSES } from 'src/config'

import { Order } from 'src/interfaces'

interface badgesStyleType {
  [key: string]: React.CSSProperties
}

export const badgesStyle: badgesStyleType = {
  ORDER_INFO_DRAFT: {
    background:
      'linear-gradient(90deg, rgba(252,253,253,1) 0%, rgba(248,249,249,1) 100%)',
    borderColor: '#fbfcfd',
    color: '#7d7f80',
  },
  ORDER_INFO_NEW: {
    background:
      'linear-gradient(90deg, rgba(236,236,236,1) 0%, rgba(227,227,229,1) 100%)',
    borderColor: '#d8d8da',
    color: '#3b3c40',
  },
  ORDER_INFO_NEED_CONFIRM: {
    background:
      'linear-gradient(90deg, rgba(236,236,236,1) 0%, rgba(227,227,229,1) 100%)',
    borderColor: '#d8d8da',
    color: '#3b3c40',
  },
  ORDER_INFO_INSUFFICIENT_RESERVE: {
    background:
      'linear-gradient(90deg, rgba(255,235,238,1) 0%, rgba(252,221,226,1) 100%)',
    borderColor: '#fbcfd6',
    color: '#7d2c38',
  },
  ORDER_INFO_REQUIRE_PAYMENT: {
    background:
      'linear-gradient(90deg, rgba(255,247,230,1) 0%, rgba(255,236,201,1) 100%)',
    borderColor: '#c1ecf4',
    color: '#296775',
  },
  ORDER_INFO_READY_TO_PACK: {
    background:
      'linear-gradient(90deg, rgba(255,247,230,1) 0%, rgba(255,236,201,1) 100%)',
    borderColor: '#fdebcd',
    color: '#806027',
  },
  ORDER_INFO_CONFIRM_HOLD: {
    background:
      'linear-gradient(90deg, rgba(236,236,236,1) 0%, rgba(194,237,246,1) 100%)',
    borderColor: '#c1ecf4',
    color: '#3b3c40',
  },
  ORDER_INFO_PACKED: {
    background:
      'linear-gradient(90deg, rgba(255,247,230,1) 0%, rgba(255,236,201,1) 100%)',
    borderColor: '#fdebcd',
    color: '#806027',
  },
  ORDER_INFO_IN_PACKING: {
    background:
      'linear-gradient(90deg, rgba(255,247,230,1) 0%, rgba(255,236,201,1) 100%)',
    borderColor: '#fdebcd',
    color: '#806027',
  },
  ORDER_INFO_SHIPPED: {
    background:
      'linear-gradient(90deg, rgba(243,243,243,1) 0%, rgba(232,225,244,1) 100%)',
    borderColor: '#dbd0ee',
    color: '#422d65',
  },
  ORDER_INFO_COURIER: {
    background:
      'linear-gradient(90deg, rgba(237,232,246,1) 0%, rgba(230,221,247,1) 100%)',
    borderColor: '#dbd0ee',
    color: '#422d65',
  },
  ORDER_INFO_DELIVERED: {
    background:
      'linear-gradient(90deg, rgba(216,244,238,1) 0%, rgba(168,241,225,1) 100%)',
    borderColor: '#b7e2d9',
    color: '#5e827a',
  },
  ORDER_INFO_PARTIALLY_DELIVERED_NOT_RETURNED: {
    background:
      'linear-gradient(90deg, rgba(216,244,238,1) 0%, rgba(168,241,225,1) 100%)',
    borderColor: '#b7e2d9',
    color: '#5e827a',
  },
  ORDER_INFO_PARTIALLY_DELIVERED_RETURNED: {
    background:
      'linear-gradient(90deg, rgba(216,244,238,1) 0%, rgba(168,241,225,1) 100%)',
    borderColor: '#b7e2d9',
    color: '#5e827a',
  },
  ORDER_INFO_RETURNED: {
    background:
      'linear-gradient(90deg, rgba(255,235,238,1) 0%, rgba(252,221,226,1) 100%)',
    borderColor: '#fbcfd6',
    color: '#7d2c38',
  },
  ORDER_INFO_LOST: {
    background:
      'linear-gradient(90deg, rgba(255,235,238,1) 0%, rgba(252,221,226,1) 100%)',
    borderColor: '#fbcfd6',
    color: '#7d2c38',
  },
  ORDER_INFO_RETURNED_TO_CLIENT: {
    background:
      'linear-gradient(90deg, rgba(216,244,238,1) 0%, rgba(168,241,225,1) 100%)',
    borderColor: '#b7e2d9',
    color: '#5e827a',
  },
  ORDER_INFO_RETURNING: {
    background:
      'linear-gradient(90deg, rgba(237,232,246,1) 0%, rgba(230,221,247,1) 100%)',
    borderColor: '#dbd0ee',
    color: '#422d65',
  },
  ORDER_INFO_AT_TAKEOUT_POINT: {
    background:
      'linear-gradient(90deg, rgba(237,232,246,1) 0%, rgba(230,221,247,1) 100%)',
    borderColor: '#dbd0ee',
    color: '#422d65',
  },
  ORDER_INFO_DONE: {
    background:
      'linear-gradient(90deg, rgba(216,244,238,1) 0%, rgba(168,241,225,1) 100%)',
    borderColor: '#b7e2d9',
    color: '#5e827a',
  },
  ORDER_INFO_CANCELED: {
    background:
      'linear-gradient(90deg, rgba(252,253,253,1) 0%, rgba(248,249,249,1) 100%)',
    borderColor: '#fbfcfd',
    color: '#7d7f80',
  },
  ORDER_INFO_WAIT_UNPACK: {
    background:
      'linear-gradient(90deg, rgba(252,253,253,1) 0%, rgba(252,221,226,1) 100%)',
    borderColor: '#fcdde2',
    color: '#7d7f80',
  },
}

interface PaymentBadgeProps {
  className?: string
  data: Order
}

const StatusBadge = (props: PaymentBadgeProps) => {
  const { className, data } = props

  const title = `${ORDER_STATUSES[data.status]} ${
    (data.delivery?.actualDeliveryDate &&
      dayjs(data.delivery?.actualDeliveryDate).format(`DD.MM.YYYY`)) ||
    ''
  }`

  const status: keyof typeof badgesStyle = data.status

  return (
    <Badge className={className} style={badgesStyle[status]} title={title} />
  )
}

export default StatusBadge
