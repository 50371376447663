import React from 'react'
import cx from 'classnames'
import numeral from 'numeral'

import styles from './product-status.module.scss'

import { ProductStatusObj } from 'src/interfaces'

interface ProductStatusProps {
  variantId: string
  statuses: (ProductStatusObj | ProductStatusObj[])[]
}

const ProductStatus = (props: ProductStatusProps) => {
  const { variantId, statuses } = props

  if (variantId === 'delivery') return null

  const statusData = statuses.find(
    (s) =>
      s &&
      (!Array.isArray(s)
        ? s.data.variantId === variantId
        : s[0].data.variantId === variantId),
  )

  if (!statusData) return null

  if (!Array.isArray(statusData)) {
    return (
      <div
        className={cx(
          styles.productStatus,
          statusData.style && styles[statusData.style],
        )}
      >
        {statusData.title}
      </div>
    )
  } else {
    return (
      <div className={styles.multipleProductStatus}>
        {statusData.map((s, i) => (
          <div
            key={`status-data-${i}`}
            className={cx(styles.productStatus, s.style && styles[s.style])}
          >
            {s.title}
            {s.sum ? (
              <>
                <br />
                {`${numeral(s.sum).format('0.[00]')} р.`}
              </>
            ) : null}
            <span>{s.text}</span>
          </div>
        ))}
      </div>
    )
  }
}

export default ProductStatus
