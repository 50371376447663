import React from 'react'

import styles from './pagination-panel.module.scss'

import { Pagination, SelectOnPage } from 'src/components'

interface PaginationPanelProps {
  page: number
  total: number
  size: number
  onChange: (size: number) => void
}

const PaginationPanel = (props: PaginationPanelProps) => {
  const { page, total, size, onChange } = props

  return (
    <div className={styles.wrapper}>
      <Pagination.StateString {...props} />
      <SelectOnPage value={size} onChange={onChange} />
    </div>
  )
}

export default PaginationPanel
