import React from 'react'

import { TableTabs, Icons } from 'src/components'

import { useOrdersStatuses } from 'src/hooks'

import { OrdersStatuses, OrdersStatusesRes, TableTab } from 'src/interfaces'

const getTabOrdersLength = (
  tab: OrdersTab,
  statusesLength?: OrdersStatusesRes,
): number | undefined => {
  return statusesLength
    ? tab?.states?.reduce((a: number, c: OrdersStatuses): number => {
        if (statusesLength[OrdersStatuses[c]]) {
          a += statusesLength[c]
        }
        return a
      }, 0)
    : undefined
}

interface OrdersTab {
  id: string
  title: string
  icon: React.SVGProps<SVGElement>
  states?: OrdersStatuses[]
}

export const ORDERS_PAGE_TABS: OrdersTab[] = [
  {
    id: 'all',
    title: 'Все заказы',
    icon: <Icons.List />,
  },
  {
    id: 'agreement',
    title: 'Согласование',
    icon: <Icons.Phone />,
    states: [
      OrdersStatuses.ORDER_INFO_DRAFT,
      OrdersStatuses.ORDER_INFO_NEED_CONFIRM,
      OrdersStatuses.ORDER_INFO_INSUFFICIENT_RESERVE,
      OrdersStatuses.ORDER_INFO_REQUIRE_PAYMENT,
      OrdersStatuses.ORDER_INFO_CONFIRM_HOLD,
    ],
  },
  {
    id: 'equipment',
    title: 'Комплектация',
    icon: <Icons.Box />,
    states: [
      OrdersStatuses.ORDER_INFO_READY_TO_PACK,
      OrdersStatuses.ORDER_INFO_IN_PACKING,
      OrdersStatuses.ORDER_INFO_PACKED,
    ],
  },
  {
    id: 'delivery',
    title: 'Доставка',
    icon: <Icons.Delivery />,
    states: [
      OrdersStatuses.ORDER_INFO_SHIPPED,
      OrdersStatuses.ORDER_INFO_COURIER,
      OrdersStatuses.ORDER_INFO_PARTIALLY_DELIVERED_NOT_RETURNED,
      OrdersStatuses.ORDER_INFO_AT_TAKEOUT_POINT,
      OrdersStatuses.ORDER_INFO_SORT_CENTER_SHIPPED,
      OrdersStatuses.ORDER_INFO_SORT_CENTER_ARRIVED,
    ],
  },
  {
    id: 'return',
    title: 'Возвраты',
    icon: <Icons.Return />,
    states: [
      OrdersStatuses.ORDER_INFO_RETURNING,
      OrdersStatuses.ORDER_INFO_PARTIALLY_DELIVERED_NOT_RETURNED,
      OrdersStatuses.ORDER_INFO_SORT_CENTER_RETURNED,
      OrdersStatuses.ORDER_INFO_SORT_CENTER_RETURNED_AND_SHIPPED,
      OrdersStatuses.ORDER_INFO_SORT_CENTER_PARTIALLY_RETURNED,
      OrdersStatuses.ORDER_INFO_SORT_CENTER_PARTIALLY_RETURNED_AND_SHIPPED,
      OrdersStatuses.ORDER_INFO_SORT_CENTER_PARTIALLY_RETURNED_AND_DELIVERED,
      OrdersStatuses.ORDER_INFO_RETURNED_TO_CLIENT,
    ],
  },
  {
    id: 'errors',
    title: 'Ошибки',
    icon: <Icons.Danger />,
    states: [OrdersStatuses.ORDER_ERROR],
  },
]

interface OrdersTabsProps {
  currentTab?: string | null
  onTabChange: (id: string) => void
}

const OrdersTabs = (props: OrdersTabsProps) => {
  const { currentTab, onTabChange } = props

  const { data: statusesLength } = useOrdersStatuses()

  const tabsData = ORDERS_PAGE_TABS.map((t: OrdersTab) => {
    const current: TableTab = {
      key: t.id,
      title: t.title,
      icon: t.icon,
    }

    if (t.states) {
      current.count = getTabOrdersLength(t, statusesLength)
    }

    return current
  })

  return (
    <TableTabs
      currentTab={currentTab}
      tabsData={tabsData}
      onTabChange={onTabChange}
    />
  )
}

export default OrdersTabs
