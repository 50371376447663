import React, { useState } from 'react'

import styles from './select-on-page.module.scss'

import Select from '../'

const ON_PAGE_OPTIONS = [10, 20, 50, 100].map((o) => ({
  label: String(o),
  value: String(o),
}))

interface SelectOnPageProps {
  value: number
  onChange: (value: number) => void
}

const SelectOnPage = (props: SelectOnPageProps) => {
  // const [onPage, setOnPage] = useState<string | string[]>(
  //   ON_PAGE_OPTIONS[0].value,
  // )

  const { value, onChange } = props

  return (
    <div className={styles.wrapper}>
      <span>На странице:</span>
      <Select
        value={String(value)}
        options={ON_PAGE_OPTIONS}
        size="small"
        withoutError
        onSelect={(value) => onChange(Number(value))}
      />
    </div>
  )
}

export default SelectOnPage
